<template>
    <b-modal id="modal-image-preview" scrollable title="Pesan Gambar" hide-footer>
        <div class="mobile-only">
            <form class="form-horizontal form-material">
                <img class="images-preview-modal img-responsive rounded" :src="imageUrl"  @error="$helpers.handleImageError($event, 'message')"/>
                <div class="img-caption" v-html="caption"></div>
            </form>
        </div>
        <div class="desktop-only">
            <div v-if="from == 'comment'">
                <div class="detail-post">
                    <img class="images-preview-modal img-responsive rounded" :src="imageUrl"
                        @error="$helpers.handleImageError($event, 'message')" />
                </div>
                <div style="padding-left: 15px" class="detail-post">
                    <div>
                        {{ caption }}
                    </div>
                </div>
            </div>
            <form class="form-horizontal form-material" v-else>
                <img class="images-preview-modal img-responsive rounded" :src="imageUrl" @error="$helpers.handleImageError($event, 'message')" />
                <div class="img-caption" v-html="caption"></div>
            </form>
        </div>
    </b-modal>
</template>

<script>
    export default {
        props: ['imageUrl', 'caption', 'from']
    }
</script>
