<template>
    <div class="container box-shadow-login">
        <div class="row">
            <div class="col-md-4 login-sec logo-login">
                <img src="images/logo-login.png" />
            </div>
            <div class="col-md-8 login-form">
                <h3 class="title text-center title-login">Login</h3>
                <div v-if="isEdit" class="mb-5">
                    <div class="mb-3">
                        Daftar Kode Perusahaan 
                        <v-icon color="primary" @click="isEdit = false">
                            mdi-close
                        </v-icon>
                    </div>
                    <div v-for="item in companies" :key="item" class="listStore">
                        <div class="row">

                            <div class="col-md-10 col" @click="companyId = item, isEdit = false">
                                <v-icon color="indigo" class="mr-2">
                                    mdi-home
                                </v-icon> {{ item }}
                            </div>
                            <div class="col-md-2 col text-right" @click="deleteCompany(item)">
                                <v-icon color="primary">mdi-delete</v-icon>
                            </div>
                        </div>


                    </div>
                </div>
               
                <v-form class="form-material m-t-20 login-form" ref="form" v-model="formValid" lazy-validation>
                    <v-row>
                        <v-col cols="12">
                            <div>
                                <!-- <small class="ml-4">Kode Perusahaan</small> -->
                                <v-text-field class="" v-if="!isCompany" type="text" label="Kode Perusahaan" v-model="companyId"
                                    prepend-inner-icon="mdi-home" :rules="[...rules.required]" >
                                </v-text-field>
                                <v-text-field class="" v-else-if="isEdit" type="text" v-model="companyId" :readonly="!isEdit"
                                    prepend-inner-icon="mdi-home" :label="isEdit ? 'Kode Perusahaan' : ''"
                                    :append-icon="isEdit ? '' : 'mdi-pencil'" :rules="[...rules.required]"
                                    @click:append="isEdit = !isEdit"></v-text-field>
                                <div class="" style="margin-bottom:30px; cursor: pointer;" v-else>
                                    <v-icon class="mr-1">
                                        mdi-home
                                    </v-icon> {{ companyId }}
                                    <a class="ml-2" @click="isEdit = !isEdit">
                                        <v-icon color="primary">mdi-pencil</v-icon>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <v-text-field class="" type="text" label="Email atau IDDDD" v-model="userId" prepend-inner-icon="mdi-account" 
                                    :rules="[...rules.required]" @keyup.enter="login()">
                                </v-text-field>
                            </div>
                            <div>
                                <v-text-field class="" type="password" label="Kata Sandi" v-model="password" prepend-inner-icon="mdi-key" 
                                    :rules="[...rules.required]" @keyup.enter="login()">
                                </v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-right">
                            <v-btn class="btn btn-primary" :disabled="!formValid || isLoginInProgress"
                                :loading="isLoginInProgress" @click="login()">
                                LOGIN
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
                <ErrorMessage v-bind:error="error" />
            </div>
        </div>
        <NotifPermission ref="notifPermission" />
    </div>
</template>
<script>
    var firebase = require("firebase");
    var cookie = require('cookie');
    var axios = require("axios");
    import LoginLayout from "../layouts/LoginLayout"
    import {
        mapMutations
    } from 'vuex'
    import ErrorMessage from '../components/ErrorMessage'
    import NotifPermission from '../components/NotifPermission'


    export default {
        data: function () {
            return {
                userId: String(),
                password: String(),
                companyId: String(),
                newCompanyId: String(),
                error: String(),

                companies: Array(),

                isLoginInProgress: false,
                formValid: false,
                isEdit: false,
                isAdd: false,
                isCompany: false,

                rules: {
                    required: [v => !!v || 'Kolom ini wajib diisi!'],
                    email: [v => /.+@.+\..+/.test(v) || 'Format email salah!']
                }
            }
        },
        methods: {
            ...mapMutations({
                setSessionInfo: 'session/setSessionInfo',
                setCommerce: 'session/setCommerce'
            }),
            addCompany: function (companyId) {
                if (!this.companies.includes(companyId)) {
                    this.companies = [...this.companies, companyId]
                    localStorage.setItem('companies', this.companies.join(','))
                }

                this.newCompanyId = String()
                this.isAdd = false
            },
            deleteCompany: function (item) {
                const companies = this.companies.filter(e => e != item)

                if (item == this.companyId) this.companyId = this.companies[0]
                this.companies = companies
                localStorage.setItem('companies', companies.join(','))
            },
            login: async function () {
                var loginData = {
                    userId: this.userId,
                    password: this.password,
                    companyId: this.companyId
                };

                this.isLoginInProgress = true;
                this.isEdit = false

                try {
                    if (this.$socket.connected) this.$socket.disconnect()
                    let resp = await axios.post(process.env.VUE_APP_API_URL + '/auth/login', loginData);
                    this.$socket.connect()

                    var jsonData = resp.data;
                    this.setSessionInfo({
                        isLoggedIn: true,
                        userId: jsonData.id,
                        userFullName: jsonData.name,
                        tenantId: jsonData.tenantId,
                        defaultCountryCode: jsonData.defaultContryCode,
                        commerceIntegration: jsonData.commerceIntegration,
                        fbToken: jsonData.token,
                        activeChannels: jsonData.activeChannels,
                        ui: jsonData.ui
                    })

                    const apiUrl = jsonData.tenantId == 'gitapelangi2' ? process.env.VUE_APP_COMMERCE_GP_API_URL :
                        process.env.VUE_APP_COMMERCE_API_URL
                    const webUrl = jsonData.tenantId == 'gitapelangi2' ? process.env.VUE_APP_COMMERCE_GP_WEB_URL :
                        process.env.VUE_APP_COMMERCE_WEB_URL

                    this.setCommerce({
                        apiUrl: apiUrl,
                        webUrl: webUrl
                    })

                    document.cookie = cookie.serialize('fbtoken', jsonData.token);

                    if (firebase.apps.length === 0) {
                        var config = {
                            apiKey: process.env.VUE_APP_FB_API_KEY,
                            authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
                            databaseURL: process.env.VUE_APP_FB_DATABASE_URL,
                            storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET
                        };

                        firebase.initializeApp(config);
                    }

                    try {
                        await firebase.auth().signInWithCustomToken(jsonData.token)
                    } catch (error) {
                        console.error(error)
                    }
                    this.$router.push('/unassigned/');

                    localStorage.setItem('companyCode', this.companyId)
                    this.addCompany(this.companyId)
                } catch (error) {
                    this.error = this.$helpers.createError(error)
                }
                this.isLoginInProgress = false;
            }
        },
        created() {
            console.log(this.$socket)
            this.$emit('update:layout', LoginLayout);
        },
        mounted: function () {
            this.isCompany = localStorage.getItem('companyCode') ? true : false
            this.companyId = localStorage.getItem('companyCode')
            this.companies = localStorage.getItem('companies') ? localStorage.getItem('companies').split(',') : []

            if (Notification.permission !== "granted" && Notification.permission !== "denied") {
                this.$bvModal.show('notif-permission-modal');
            }
        },
        components: {
            ErrorMessage,
            NotifPermission
        }
    }
</script>