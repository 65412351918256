<template>
    <div class="row">
        <div class="col-12">
            <div class="card card m-b-0">
                <!-- .chat-row -->
                <div class="chat-main-box">
                    <!-- .chat-left-panel -->
                    <div class="chat-left-aside">
                        <div class="chat-left-inner form-horizontal" style="height: 3px;">
                            <div class="form-material">
                                <div class="input-group searchbox">
                                    <input type="text" class="form-control" id="uname" placeholder="Pencarian..."
                                        v-model="commChannelUIFlag.searchTerm" @input="isTyping = true">
                                    <div class="input-group-addon">
                                        <i class="ti-search"></i>
                                    </div>
                                </div>
                            </div>
                            <div id="left-inner-scrollbar" data-scrollbar="true" tabindex="1"
                                style="overflow: hidden; outline: none;">
                                <div class="scroll-content">
                                    <ul class="chatonline style-none">
                                        <li v-for="item in commChannelList" :key="item._id">
                                            <router-link v-bind:class="{ active: item.selected }"
                                                :to="{ name: 'commchannel', params: {id: encodeURIComponent(item._id)}}">
                                                <div class="wrapp-img">
                                                     <div class="initials">
                                                        <i class="mdi mdi-settings"></i>
                                                     </div>
                                                </div>
                                                <span class="chat-info"> <span class="user-name">{{ item.name }}</span>
                                                    <small class="text-default">
                                                        <div class="chat-content-thumb">{{ item.status }}
                                                        </div>
                                                    </small>
                                                </span>
                                                <span v-if="commChannelDetailFlag.isLoading && item.selected == true"><i
                                                        class="fa fa-circle-o-notch fa-spin pull-right m-t-10"></i> </span>
                                                <span class="account-status"><i v-if="item.accountStatus == 'ACTIVE'"
                                                        title="Pengguna Aktif" class="mdi mdi-check-circle"></i>
                                                    <i v-if="item.accountStatus == 'VOID'" title="Pengguna Nonaktif"
                                                        class="mdi mdi-minus-circle"></i></span>
                                            </router-link>
                                        </li>
                                        <li id="user-loader-indicator" v-if="commChannelUIFlag.isLoading">
                                            <span class="chat-info"> <span class="user-info">Load More...</span>
                                                <small class="text-default">
                                                    <div class="chat-content-thumb"></div>
                                                </small>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                    <div class="scrollbar-thumb scrollbar-thumb-x"
                                        style="width: 329px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                    <div class="scrollbar-thumb scrollbar-thumb-y"
                                        style="height: 20px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- .chat-left-panel -->
                    <!-- .chat-right-panel -->
                    <div class="chat-right-aside layer-opacity">
                        <div class="chat-main-header">
                            <div class="headchat">
                                <div class="ml-2 open-panel-mobile pull-left pl-0">
                                    <div class="open-panel">
                                        <i class="ti-angle-left"></i>
                                    </div>
                                </div>
                                <button id="btn-new" type="button" class="pull-right btn btn-default waves-effect waves-light"
                                    @click="$router.push('/commchannel/')">
                                    Buat Kanal Baru
                                </button>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="chat-rbox">
                            <div id="detailContent" class="wrapp-chat-content" data-scrollbar="true" tabindex="1" style="overflow: hidden; outline: none;">
                                <div class="scroll-content">
                                    <div class="contact-add">
                                        <span v-if="commChannel.channelType == 'OPENWA'"  class="badge badge-pill badge-primary pull-right m-b-20">
                                            {{ OPEN_WA_STATE_MAP[openWaState.state] }}
                                        </span>
                                        <div class="clearfix"></div>
                                        <form class="form-horizontal form-material">
                                            <button title="Hapus" id="btn-delete" type="button" v-if="commChannel.mode == 'edit'"
                                                v-bind:disabled="commChannel.mode != 'edit'"
                                                class="btn btn-default waves-effect waves-light btn-new-contact" 
                                                @click="deleteChannel()"><v-icon class="text-info">mdi-delete</v-icon></button>

                                            <button title="Sinkronisasi Pesan" id="btn-delete" type="button" 
                                                v-if="commChannel.mode == 'edit' && commChannel.channelType == 'WABAILEYS'"
                                                class="btn btn-default waves-effect waves-light btn-new-contact pull-right m-r-5"
                                                @click="openDialog('session')">Connect</button>
                                                
                                            <button title="Sinkronisasi Pesan" id="btn-delete" type="button" 
                                                v-if="commChannel.mode == 'edit' && commChannel.channelType == 'OPENWA'"
                                                class="btn btn-default waves-effect waves-light btn-new-contact pull-right m-r-5"
                                                @click="openDialog('sync')"><v-icon class="text-info">mdi-sync</v-icon></button>
                
                                            <button title="Masuk" id="btn-login" type="button" v-if="allowStartOpenWA"
                                                v-bind:disabled="commChannel.mode != 'edit'"
                                                class="btn btn-default waves-effect waves-light btn-new-contact pull-right m-r-5"
                                                @click="loginOpenWa()"><v-icon class="text-info">mdi-login</v-icon></button>
                                            
                                            <button title="Keluar" type="button" v-if="allowStopOpenWA"
                                                v-bind:disabled="commChannel.mode != 'edit'"
                                                class="btn btn-default waves-effect waves-light btn-new-contact pull-right m-r-5"
                                                @click="logoutOpenWa()"><v-icon class="text-info">mdi-logout</v-icon></button>
                                            <button title="Matikan" type="button" v-if="allowShutdownOpenWA"
                                                v-bind:disabled="commChannel.mode != 'edit'"
                                                class="btn btn-default waves-effect waves-light btn-new-contact pull-right m-r-5"
                                                @click="shutdownOpenWa()"><v-icon class="text-info">mdi-power-standby</v-icon></button>

                                            <div class="wrapp-input-contact wrapp-group">
                                                <div class="alert alert-primary story-sync" v-if="commChannel.successSync">
                                                    <div>Waktu Kanal Terakhir Sinkron: <b>{{ commChannel.lastChannelTimeSync }}</b></div>
                                                    <div>Waktu Pesan Terakhir Sinkron: <b>{{ commChannel.lastMessageTimeSync }}</b></div>
                                                    <div>Status Sinkron: <b>{{ commChannel.successSync == true ? 'Sukses' : 'Gagal' }}</b></div>
                                                </div>
                                                <div class="clearfix"></div>
    
                                                <div v-if="commChannel.mode == 'edit' && (commChannel.channelType == 'WABAILEYS' || commChannel.channelType == 'WACLOUDAPI')" 
                                                    class="mb-2 pull-right">
                                                    <div class="col">
                                                        <div class="badge p-2 ml-2" :class="{ 
                                                            'badge-success': commChannel.botState == 'RUNNING', 
                                                            'badge-danger': commChannel.botState == 'STOP', 
                                                            'badge-warning': commChannel.botState == 'GRACEFULLY_STOP'  
                                                        }">
                                                            <div>Bot - {{ commChannel.botState == 'STOP' ? 'Non Aktif' : commChannel.botState == 'RUNNING' ? 'Aktif' : 'Segera Berhenti' }}</div>
                                                        </div>
                                                        <div v-if="commChannel.botState != 'STOP'" class="badge p-2 ml-2" 
                                                            :class="{ 'badge-success': commChannel.isAutoReplyActive, 'badge-danger': !commChannel.isAutoReplyActive }">
                                                            <div>Balas Otomatis - {{ commChannel.isAutoReplyActive ? 'Aktif' : 'Non Aktif' }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="clearfix" v-if="commChannel.mode == 'edit' && (commChannel.channelType == 'WABAILEYS' || commChannel.channelType == 'WACLOUDAPI')"></div>
                                               
                                                <div class="wrapp-input-contact">
                                                    <div class="row">
                                                        <label for="f-user-full-name"
                                                            class="  col-sm-3 text-right control-label col-form-label">Nama
                                                            Kanal</label>
                                                        <div class="  col-sm-6 pt-0">
                                                            <input v-model="commChannel.name" type="text"
                                                                class="form-control" id="f-user-full-name">
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="f-password"
                                                            class="  col-sm-3 text-right control-label col-form-label">Jenis
                                                            Kanal</label>
                                                        <div class="  col-sm-5"
                                                            style="padding-top:6px; padding-bottom: 6px;">
                                                            <template v-for="(item, index) in availableChannels">
                                                                <input type="radio" v-bind:value="item.id" :key="index"
                                                                    v-bind:id="item.id" class="filled-in chk-col-indigo"
                                                                    v-model="commChannel.channelType">
                                                                <label :key="index + '-label'" v-bind:for="item.id" class="mr-2">
                                                                    {{ item.name}}
                                                                </label>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="f-user-id"
                                                            class="  col-sm-3 text-right control-label col-form-label">Webhook Url</label>
                                                        <div class="  col-sm-8 pt-0">
                                                            <input v-model="commChannel.webhook" type="text" disabled
                                                                class="form-control" id="f-user-id">
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="commChannel.channelType == 'OPENWA'">
                                                        <label for="f-api-url"
                                                            class="  col-sm-3 text-right control-label col-form-label">Mode
                                                            API</label>
                                                        <div class="  col-sm-8 pt-0 mt-0">
                                                            <v-select style="font-size:14px;" :items="openWAApiModes" v-model="commChannel.apiMode" class="pt-0">
                                                            </v-select>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-0 pt-0"
                                                        v-if="['OPENWA', 'WACLOUDAPI', 'WABAILEYS', 'FBMSGR', 'INSTAGRAM'].indexOf(commChannel.channelType) >= 0 ">
                                                        <label for="f-api-url"
                                                            class="  col-sm-3 text-right control-label col-form-label">API
                                                            URL</label>
                                                        <div class="  col-sm-8 pt-0">
                                                            <input v-model="commChannel.apiUrl" type="text"
                                                                class="form-control" id="f-api-url" placeholder="API URL">
                                                        </div>
                                                    </div>
                                                    <div class="row"
                                                        v-if="['WACLOUDAPI', 'OPENWA', 'WABOXAPP', 'WABAILEYS'].indexOf(commChannel.channelType) >= 0">
                                                        <label for="f-user-email"
                                                            class="  col-sm-3 text-right control-label col-form-label">Token</label>
                                                        <div class="  col-sm-8 pt-0">
                                                            <input v-model="commChannel.waboxToken" type="text"
                                                                class="form-control" id="f-user-email"
                                                                placeholder="WABOXAPP Token">
                                                        </div>
                                                    </div>
                                                    <div class="row"
                                                        v-if="commChannel.channelType == 'WACLOUDAPI'">
                                                        <label for="f-waboxPhoneNumber"
                                                            class="  col-sm-3 text-right control-label col-form-label">ID No
                                                            Telpon</label>
                                                        <div class="  col-sm-8 pt-0">
                                                            <input v-model="commChannel.waPhoneNumberId" type="text"
                                                                placeholder="ID Nomor telepon" class="form-control"
                                                                id="f-waboxPhoneNumberId">
                                                        </div>
                                                    </div>
                                                    <div class="row"
                                                        v-if="['WACLOUDAPI', 'OPENWA', 'WABOXAPP', 'WABAILEYS'].indexOf(commChannel.channelType) >= 0">
                                                        <label for="f-waboxPhoneNumber"
                                                            class="  col-sm-3 text-right control-label col-form-label">No
                                                            Telpon</label>
                                                        <div class="  col-sm-8 pt-0">
                                                            <input v-model="commChannel.waboxPhoneNumber" type="text"
                                                                placeholder="Nomor telepon" class="form-control"
                                                                id="f-waboxPhoneNumber">
                                                        </div>
                                                    </div>
                                                    <div class="row"
                                                        v-if="['WACLOUDAPI', 'OPENWA', 'WABOXAPP', 'WABAILEYS'].indexOf(commChannel.channelType) >= 0">
                                                        <label for="f-password"
                                                            class="  col-sm-3 text-right control-label col-form-label">Negara</label>
                                                        <div class="  col-sm-8 pt-0">
                                                            <select v-model="commChannel.waboxDefaultCountryCode"
                                                                class="form-control">
                                                                <!-- inline object literal -->
                                                                <option v-for="item in countryCodes" :key="item.Code"
                                                                    v-bind:value="item.Code">{{item.Name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="commChannel.channelType == 'FBMSGR' || commChannel.channelType == 'WACLOUDAPI' || commChannel.channelType == 'INSTAGRAM'">
                                                        <label for="f-fbmsgrVerifyToken"
                                                            class="  col-sm-3 text-right control-label col-form-label">Verify
                                                            Token</label>
                                                        <div class="col-sm-8 pt-0">
                                                            <input v-model="commChannel.fbmsgrVerifyToken" type="text"
                                                                readonly class="form-control" id="f-fbmsgrVerifyToken">
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="commChannel.channelType == 'FBMSGR' || commChannel.channelType == 'INSTAGRAM'">
                                                        <label for="f-fbmsgrPageId"
                                                            class="col-sm-3 text-right control-label col-form-label">Page
                                                            ID</label>
                                                        <div class="col-sm-8 pt-0">
                                                            <input v-model="commChannel.fbmsgrPageId" type="text"
                                                                class="form-control" id="f-fbmsgrPageId">
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="commChannel.channelType == 'FBMSGR' || commChannel.channelType == 'INSTAGRAM'">
                                                        <label for="f-fbmsgrPageAccessToken"
                                                            class="  col-sm-3 text-right control-label col-form-label">Page
                                                            Access Token</label>
                                                        <div class="pt-0 col-sm-8">
                                                            <input v-model="commChannel.fbmsgrPageAccessToken" type="text"
                                                                class="form-control" id="f-fbmsgrPageAccessToken">
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="commChannel.channelType == 'FBMSGR' || commChannel.channelType == 'INSTAGRAM'">
                                                        <label for="f-fbmsgrAppSecret"
                                                            class="  col-sm-3 text-right control-label col-form-label">Application
                                                            Secret</label>
                                                        <div class="pt-0 col-sm-8">
                                                            <input v-model="commChannel.fbmsgrAppSecret" type="text"
                                                                class="form-control" id="f-fbmsgrAppSecret">
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="commChannel.channelType == 'INSTAGRAM'">
                                                        <label for="f-fbmsgrAppSecret" class="col-sm-3 text-right control-label col-form-label">
                                                            Business Account ID
                                                        </label>
                                                        <div class="pt-0 col-sm-8">
                                                            <input v-model="commChannel.igBusinessAccId" type="text"
                                                                class="form-control" id="f-fbmsgrAppSecret">
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="commChannel.channelType == 'FBMSGR' || commChannel.channelType == 'INSTAGRAM'">
                                                        <label for="f-fbmsgrAppId"
                                                            class="  col-sm-3 text-right control-label col-form-label">Application
                                                            ID</label>
                                                        <div class="pt-0 col-sm-8">
                                                            <input v-model="commChannel.fbmsgrAppId" type="text"
                                                                class="form-control" id="f-fbmsgrAppId">
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="f-password"
                                                            class="  col-sm-3 text-right control-label col-form-label">Status</label>
                                                        <div class="  col-sm-8">
                                                            <!-- <template v-for="(item, index) in availableChannels"> -->
                                                            <!-- <input type="checkbox" value="ACTIVE" v-model="commChannel.accountStatus"> Aktif<br /> -->
                                                            <input style="cursor:pointer" type="checkbox" id="accStatus"
                                                                v-model="commChannel.status" true-value="ACTIVE"
                                                                false-value="INACTIVE" class="filled-in chk-col-indigo">
                                                            <label class="ml-2" for="accStatus">Aktif</label>
                                                        </div>
                                                        <!-- </template> -->
                                                    </div>
                                                    <div class="row">
                                                        <label for="f-isNotifSender"
                                                            class="  col-sm-3 text-right control-label col-form-label">&nbsp;</label>
                                                        <div class="  col-sm-8">
                                                            <!-- <template v-for="(item, index) in availableChannels"> -->
                                                            <!-- <input type="checkbox" value="ACTIVE" v-model="commChannel.accountStatus"> Aktif<br /> -->
                                                            <input style="cursor:pointer" type="checkbox" id="f-isNotifSender"
                                                                v-model="commChannel.isNotifSender" class="filled-in chk-col-indigo">
                                                            <label class="ml-2" for="accStatus">Hanya Pengiriman Notifikasi</label>
                                                        </div>
                                                        <!-- </template> -->
                                                    </div>
                                                    <div class="row mt-0 pt-0" v-if="['WACLOUDAPI', 'OPENWA', 'WABOXAPP', 'WABAILEYS'].indexOf(commChannel.channelType) >= 0">
                                                        <label for="f-is-wa-business"
                                                            class="  col-sm-3 text-right control-label col-form-label">&nbsp;</label>
                                                        <div class="  col-sm-8">
                                                            <!-- <template v-for="(item, index) in availableChannels"> -->
                                                            <!-- <input type="checkbox" value="ACTIVE" v-model="commChannel.accountStatus"> Aktif<br /> -->
                                                            <input type="checkbox" id="accIsBusinessWA"
                                                                v-model="commChannel.isWABusiness" class="filled-in chk-col-indigo">
                                                            <label for="accStatus" class="ml-2">WA Bisnis</label>
                                                        </div>
                                                        <!-- </template> -->
                                                    </div>
                                                    <ErrorMessage :error="commChannelDetailFlag.error" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                    <div class="scrollbar-thumb scrollbar-thumb-x"
                                        style="width: 661px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                    <div class="scrollbar-thumb scrollbar-thumb-y"
                                        style="height: 445.289px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                            </div>
                            <div class="button-wrapp">
                                <button class="btn btn-primary" id="btn-save" @click="saveChannel">
                                    <i v-if="commChannelDetailFlag.isSaving" class="fa fa-circle-o-notch fa-spin"></i>
                                    &nbsp;
                                    Simpan
                                </button>
                                <button v-if="commChannel.botState == 'RUNNING' || commChannel.botState == 'GRACEFULLY_STOP'" 
                                    class="btn btn-primary ml-2" id="btn-save" 
                                    @click="setChannelBot('auto-reply', commChannel.isAutoReplyActive ? false : true)">
                                    <i v-if="commChannelDetailFlag.isLoadingBotAutoReply" class="fa fa-circle-o-notch fa-spin"></i>
                                    &nbsp;
                                    {{ commChannel.isAutoReplyActive ? 'Hentikan' : 'Aktifkan' }} Balas Otomatis
                                </button>
                                <button v-if="commChannel.botState == 'RUNNING' || commChannel.botState == 'GRACEFULLY_STOP'"
                                    class="btn btn-primary ml-2" id="btn-save" 
                                    @click="setChannelBot('state', 'STOP')">
                                    <i v-if="commChannelDetailFlag.isLoadingBotState" class="fa fa-circle-o-notch fa-spin"></i>
                                    &nbsp;
                                    Hentikan Segera Bot
                                </button>
                                <button v-if="['WACLOUDAPI', 'WABAILEYS'].indexOf(commChannel.channelType) >= 0" 
                                    class="btn btn-primary ml-2" id="btn-save" 
                                    @click="setChannelBot('state', commChannel.botState != 'RUNNING' ? 'RUNNING' : 'GRACEFULLY_STOP')">
                                    <i v-if="commChannelDetailFlag.isLoadingBotState" class="fa fa-circle-o-notch fa-spin"></i>
                                    &nbsp;
                                    {{ commChannel.botState != 'RUNNING' ? 'Aktifkan' : 'Hentikan' }} Bot
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <SuccessNotif />

        <WAQrLogin 
            v-bind:imageUrl="qrCode" 
            v-bind:serverState="openWaState.state" />

        <SyncMessageDialog 
            :show="showDialog" 
            :commChannel="commChannel"
            @close-dialog="closeDialog" />

        <ChannelSessionDialog 
            ref="session"
            :show="showDialogSession" 
            :commChannel="commChannel"
            @close-dialog="closeDialog" />
    </div>
</template>
<script>
    import axios from 'axios'
    import Scrollbar from 'smooth-scrollbar'
    import ErrorMessage from '../components/ErrorMessage'
    import MainLayout from "../layouts/MainLayout"
    import SuccessNotif from "../components/SuccessNotif"
    import WAQrLogin from '../components/WAQrLogin'
    import SyncMessageDialog from '../components/SyncMessageDialog.vue'
    import ChannelSessionDialog from '../components/ChannelSessionDialog.vue'
    import Toast from '../plugins/vuenotification'

    import {
        OPEN_WA_STATE_MAP,
        STATE_OFF,
        STATE_RUNNING,
        STATE_RUN_FAILED
    } from '../utils/ui-constant/'

    import {
        // mapActions,
        mapGetters,
        mapMutations,
        mapState
    } from 'vuex'
    import countryCodes from "../utils/country-codes"

    const $ = require("jquery")
    const debounce = require('lodash.debounce')
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup')
    var moment = require('moment');
    const { toFriendlyDateTime } = require('../utils/date-utils');

    const USER_ROW_PER_PAGE = 25
    var userScrollBar = null

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo,
                qrCode: state => state.qrCode,
                connectedChannels: state => state.connectedChannels
            }),
            ...mapGetters(['getChannelState']),
            allowStartOpenWA() {
                return this.commChannel.channelType == 'OPENWA' && this.commChannel.apiMode == 'Local' && (this
                    .openWaState == null || this.openWaState.state == null ||
                    this.openWaState.state == STATE_RUN_FAILED || this.openWaState.state == STATE_OFF);
            },
            allowStopOpenWA() {
                return this.commChannel.channelType == 'OPENWA' && this.openWaState.state == STATE_RUNNING && this
                    .commChannel.apiMode == 'Local'
            },
            allowShutdownOpenWA() {
                return this.commChannel.channelType == 'OPENWA' && this.openWaState.state != STATE_OFF && this
                    .commChannel.apiMode == 'Local'
            }
        },
        data: function () {
            return {
                showDialogSession: false,
                showDialog: false,
                OPEN_WA_STATE_MAP: OPEN_WA_STATE_MAP,
                openWaState: {},
                countryCodes: countryCodes,
                availableChannels: [{
                    id: "WABOXAPP",
                    name: "WA via WABOXAPP"
                }, {
                    id: "OPENWA",
                    name: "WA via OPENWA"
                }, {
                    id: "WABAILEYS",
                    name: "WA via BAILEYS"
                }, {
                    id: "WACLOUDAPI",
                    name: "WA CLOUD API"
                }, {
                    id: "FBMSGR",
                    name: "Facebook Messenger"
                }, {
                    id: "INSTAGRAM",
                    name: "Instagram"
                }, {
                    id: "SMS",
                    name: "SMS"
                }],
                openWAApiModes: ["Local", "Remote"],
                commChannelList: [],
                commChannelUIFlag: {
                    error: {},
                    currentPage: 1,
                    searchTerm: '',
                    selectedUser: '',
                    hasMoreData: true,
                    isLoading: false
                },
                commChannel: {
                    mode: '',
                    _id: '',
                    name: '',
                    status: 'INACTIVE',
                    webhook: '',
                    isNotifSender: false,
                    apiUrl: '',
                    channelType: '',
                    apiMode: 'Remote',
                    waPhoneNumberId: '',
                    waboxToken: '',
                    waboxPhoneNumber: '',
                    waboxDefaultCountryCode: '',
                    isWABusiness: false,
                    fbmsgrVerifyToken: '',
                    fbmsgrPageId: '',
                    fbmsgrPageAccessToken: '',
                    fbmsgrAppSecret: '',
                    fbmsgrAppId: '',
                    igBusinessAccId: '',
                    lastChannelTimeSync: String(),
                    lastMessageTimeSync: String(),
                    successSync: String(),
                    botState: 'STOP',
                    isAutoReplyActive: false         
                },
                qrImageUrl: '',
                commChannelDetailFlag: {
                    error: {},
                    isLoading: false,
                    isSaving: false,
                    isLoadingBotState: false,
                    isLoadingBotAutoReply: false
                },
                isTyping: false
            }
        },
        watch: {
            connectedChannels: function (val) {
                console.log('state list changed: ', val)
                const tmp = this.getChannelState(this.commChannel._id)
                console.log('tmp:', tmp)
                this.openWaState = tmp ? tmp : {
                    state: STATE_OFF
                }
                //this.$bvModal.hide("modal-wa-qr-login");
            },
            'commChannelUIFlag.searchTerm': debounce(function () {
                this.isTyping = false;
            }, 1000),
            $route: async function (to, from) {
                if (this.$helpers.isBlank(to.params.id)) {
                    this.resetToEmptyForm();
                } else if (to.params.id != from.params.id) {
                    this.selectCommChannel(decodeURIComponent(to.params.id));
                }
            },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            },
            isTyping: function (value) {
                if (!value) {
                    this.loadCommChannel();
                }
            }
        },
        methods: {
            ...mapMutations({
                updateWAClientSessionState: 'updateWAClientSessionState',
                setQrCode: 'setQrCode'
            }),
            // ...mapActions({
            //     logoutOpenWASession: 'logoutOpenWASession'
            // }),
            closeDialog: function(val) {
                this.showDialog = val
                this.showDialogSession = val
                
            },
            openDialog: async function (dialogType) {
                if(dialogType == 'sync') this.showDialog = true
                if(dialogType == 'session') this.showDialogSession = true 
            },
            selectCommChannel: async function (commChannelId) {
                this.commChannelList.forEach(element => {
                    if (element._id == commChannelId) {
                        element.selected = true
                    } else {
                        element.selected = false
                    }
                })
                this.commChannelDetailFlag.error = {}
                this.commChannelDetailFlag.isLoading = true;
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/commchannel/' + commChannelId);
                    var jsonData = resp.data;
                    this.commChannel = {
                        ...this.commChannel,
                        mode: 'edit',
                        _id: jsonData._id,
                        name: jsonData.name,
                        status: jsonData.status,
                        webhook: jsonData.webhook,
                        isNotifSender: jsonData.isNotifSender,
                        apiUrl: jsonData.api_url,
                        apiMode: jsonData.api_mode,
                        channelType: jsonData.channel_type,
                        waboxToken: jsonData.wabox_token,
                        waPhoneNumberId: jsonData.wa_phone_number_id,
                        waboxPhoneNumber: jsonData.wabox_phone_number,
                        waboxDefaultCountryCode: jsonData.wabox_default_country_code,
                        isWABusiness: jsonData.is_wa_business,
                        fbmsgrVerifyToken: jsonData.fbmsgr_verify_token,
                        fbmsgrPageId: jsonData.fbmsgr_page_id,
                        fbmsgrPageAccessToken: jsonData.fbmsgr_page_access_token,
                        fbmsgrAppSecret: jsonData.fbmsgr_app_secret,
                        fbmsgrAppId: jsonData.fbmsgr_app_id,
                        igBusinessAccId: jsonData.fbmsgr_ig_business_account_id,
                        lastChannelTimeSync: jsonData.lastChannelTimeSync ? toFriendlyDateTime(jsonData.lastChannelTimeSync) : null,
                        lastMessageTimeSync: jsonData.lastMessageTimeSync ? toFriendlyDateTime(jsonData.lastMessageTimeSync) : null,
                        successSync: jsonData.successSync ? jsonData.successSync : null,
                        botState: jsonData.botState,
                        isAutoReplyActive: jsonData.isAutoReplyActive                      
                    }

                    if(jsonData.botState == 'GRACEFULLY_STOP') {
                        Toast.showToast("warning", "Status Bot segara dimatikan, pesan terakhir yang masuk akan diselesaikan terlebih dahulu oleh bot")
                    }

                    if (this.commChannel.channelType == 'OPENWA') {
                        try {
                            var response = await axios.get(process.env.VUE_APP_API_URL + '/commchannel/openwa/' +
                                this.commChannel._id);
                            console.log(response.data);
                            this.updateWAClientSessionState(response.data)
                            this.openWaState = response.data
                        } catch (error) {
                            if (error.response) {
                                this.commChannelDetailFlag.error = this.$helpers.createError(error)
                            }
                        }
                    }
                } catch (error) {
                    this.commChannelDetailFlag.error = this.$helpers.createError(error)
                }
                this.commChannelDetailFlag.isLoading = false
            },
            loadCommChannel: async function () {
                this.commChannelList = []
                this.commChannelUIFlag.currentPage = 1
                await this.loadUserOnPage()
            },
            loadMoreUsers: async function () {
                this.commChannelUIFlag.currentPage++
                this.loadUserOnPage()
            },
            loadUserOnPage: async function () {
                this.commChannelUIFlag.error = {}
                this.commChannelUIFlag.hasMoreData = true;
                this.commChannelUIFlag.isLoading = true;

                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/commchannel/', {
                        params: {
                            page: this.commChannelUIFlag.currentPage,
                            sort: this.commChannelUIFlag.sortOrder,
                            term: this.commChannelUIFlag.searchTerm,
                            size: USER_ROW_PER_PAGE
                        }
                    });
                    this.commChannelUIFlag.isLoading = false
                    var jsonData = resp.data
                    this.commChannelUIFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    this.commChannelList.splice(this.commChannelList.length, 0, ...jsonData.content);
                } catch (error) {
                    this.commChannelList.error = this.$helpers.createError(error)
                }
                this.commChannelUIFlag.isLoading = false
            },
            loadInitialData: async function () {
                await this.loadCommChannel();
                if (this.$route.params.id != null) {
                    this.selectCommChannel(decodeURIComponent(this.$route.params.id));
                }
            },
            saveChannel: async function (event) {
                event.preventDefault()
                var errors = []
                if (this.$helpers.isBlank(this.commChannel.name)) {
                    errors.push("'Nama' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.commChannel.channelType)) {
                    errors.push("'Jenis Kanal' harus dipilih")
                }
                if (this.$helpers.isBlank(this.commChannel.waboxToken) && ['OPENWA', 'WABOXAPP', 'WABAILEYS'].indexOf(this.commChannel.channelType) >= 0) {
                    errors.push("'Token' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.commChannel.apiUrl) && ['OPENWA', 'WABAILEYS'].indexOf(this.commChannel.channelType) >= 0) {
                    errors.push("'API URL' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.commChannel.waboxPhoneNumber) && ['OPENWA', 'WABOXAPP', 'WABAILEYS'].indexOf(this.commChannel.channelType) >= 0) {
                    errors.push("'No Telepon' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.commChannel.waboxDefaultCountryCode) && ['OPENWA', 'WABOXAPP', 'WABAILEYS'].indexOf(this.commChannel.channelType) >= 0) {
                    errors.push("'Negara' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.commChannel.fbmsgrPageId) && 
                    (this.commChannel.channelType == 'FBMSGR' || this.commChannel.channelType == 'INSTAGRAM')) {
                    errors.push("'Page ID' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.commChannel.fbmsgrPageAccessToken) && 
                    (this.commChannel.channelType == 'FBMSGR' || this.commChannel.channelType == 'INSTAGRAM')) {
                    errors.push("'Page Access Token' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.commChannel.igBusinessAccId) && (this.commChannel.channelType == 'INSTAGRAM')) {
                    errors.push("'Business Account ID' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.commChannel.fbmsgrAppSecret) && 
                    (this.commChannel.channelType == 'FBMSGR' || this.commChannel.channelType == 'INSTAGRAM')) {
                    errors.push("'Application Secret' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.commChannel.fbmsgrAppId) &&
                    (this.commChannel.channelType == 'FBMSGR' || this.commChannel.channelType == 'INSTAGRAM')) {
                    errors.push("'Application ID' tidak boleh kosong")
                }

                if (errors.length > 0) {
                    this.commChannelDetailFlag.error = this.$helpers.createErrorFromList(errors)
                } else {
                    var data = {
                        name: this.commChannel.name,
                        status: this.commChannel.status,
                        channel_type: this.commChannel.channelType
                    }

                    if (this.commChannel.channelType == 'WABOXAPP') {
                        data.wabox_token = this.commChannel.waboxToken
                        data.wabox_phone_number = this.commChannel.waboxPhoneNumber
                        data.wabox_default_country_code = this.commChannel.waboxDefaultCountryCode
                    } else if (this.commChannel.channelType == 'OPENWA') {
                        data.apiMode = this.commChannel.apiMode
                        data.wabox_token = this.commChannel.waboxToken
                        data.api_url = this.commChannel.apiUrl
                        data.api_mode = this.commChannel.apiMode
                        data.wabox_phone_number = this.commChannel.waboxPhoneNumber
                        data.is_wa_business = this.commChannel.isWABusiness
                        data.wabox_default_country_code = this.commChannel.waboxDefaultCountryCode
                    } else if (this.commChannel.channelType == 'WABAILEYS') {
                        data.wabox_token = this.commChannel.waboxToken
                        data.api_url = this.commChannel.apiUrl
                        data.is_notif_sender = this.commChannel.isNotifSender
                        data.api_mode = this.commChannel.apiMode
                        data.wabox_phone_number = this.commChannel.waboxPhoneNumber
                        data.is_wa_business = this.commChannel.isWABusiness
                        data.wabox_default_country_code = this.commChannel.waboxDefaultCountryCode
                    } else if (this.commChannel.channelType == 'WACLOUDAPI') {
                        data.apiMode = this.commChannel.apiMode
                        data.wabox_token = this.commChannel.waboxToken
                        data.api_url = this.commChannel.apiUrl
                        data.wa_phone_number_id = this.commChannel.waPhoneNumberId,
                        data.wabox_phone_number = this.commChannel.waboxPhoneNumber
                        data.wabox_default_country_code = this.commChannel.waboxDefaultCountryCode
                    } else if (this.commChannel.channelType == 'FBMSGR' ) {
                        data.fbmsgr_page_id = this.commChannel.fbmsgrPageId
                        data.fbmsgr_page_access_token = this.commChannel.fbmsgrPageAccessToken
                        data.fbmsgr_app_secret = this.commChannel.fbmsgrAppSecret
                        data.fbmsgr_app_id = this.commChannel.fbmsgrAppId
                    } else if (this.commChannel.channelType == 'INSTAGRAM') {
                        data.fbmsgr_page_id = this.commChannel.fbmsgrPageId
                        data.fbmsgr_page_access_token = this.commChannel.fbmsgrPageAccessToken
                        data.fbmsgr_app_secret = this.commChannel.fbmsgrAppSecret
                        data.fbmsgr_app_id = this.commChannel.fbmsgrAppId
                        data.fbmsgr_ig_business_account_id = this.commChannel.igBusinessAccId
                    }

                    this.commChannelDetailFlag.isSaving = true
                    
                    try {
                        var resp
                        if (this.commChannel.mode == 'edit') {
                            resp = await axios.put(process.env.VUE_APP_API_URL +
                                `/commchannel/${this.commChannel._id}`,
                                data)
                            var foundIdx = this.commChannelList.findIndex(el => el._id == this.commChannel._id)
                            if (foundIdx < 0) {
                                this.commChannelList.splice(0, 0, resp.data)
                            } else {
                                this.commChannelList.splice(foundIdx, 1, resp.data)
                            }
                            this.selectCommChannel(this.commChannel._id)
                        } else {
                            data._id = this.commChannel.generatedId
                            resp = await axios.post(process.env.VUE_APP_API_URL + '/commchannel/', data)
                            this.commChannelList.splice(0, 0, resp.data)
                            this.selectCommChannel(resp.data._id)
                        }

                        this.$bvToast.show('success-notif')
                    } catch (error) {
                        this.commChannelDetailFlag.error = this.$helpers.createError(error)
                    }
                    this.commChannelDetailFlag.isSaving = false
                }
            },
            deleteChannel: async function () {
                var modalResult = await this.$bvModal.msgBoxConfirm(`Hapus data kanal '${this.commChannel.name}'?`)
                if (modalResult) {
                    var id = this.commChannel._id
                    await axios.delete(process.env.VUE_APP_API_URL + `/commchannel/${id}`)
                    var foundIdx = this.commChannelList.findIndex(el => el._id == id)
                    if (foundIdx >= 0) {
                        this.commChannelList.splice(foundIdx, 1)
                    }
                    this.$router.push('/commchannel/')
                }
            },
            loginOpenWa: async function () {
                if (this.commChannel.channelType == 'OPENWA') {
                    this.setQrCode({
                        qrCode: null
                    })
                    try {
                        await axios.get(process.env.VUE_APP_API_URL + '/commchannel/openwaqr/' + this
                            .commChannel._id);
                        console.log(`start server on channel ${this.commChannel._id}`)
                        this.$socket.emit('START_WA_CLIENT', {
                            channelId: this.commChannel._id
                        });
                        this.$bvModal.show("modal-wa-qr-login");
                    } catch (error) {
                        if (error.response) {
                            this.commChannelDetailFlag.error = this.$helpers.createError(error)
                        }
                    }
                }
            },
            logoutOpenWa: async function () {
                if (this.commChannel.channelType == 'OPENWA') {
                    var modalResult = await this.$bvModal.msgBoxConfirm(
                        `Logout whatsapp '${this.commChannel.name}'?`)
                    if (modalResult) {
                        try {
                            await axios.put(process.env.VUE_APP_API_URL + '/commchannel/openwa-logout/' + this
                                .commChannel._id);
                            console.log(`logging out ${this.sessionInfo.tenantId}  ${this.commChannel._id}`)
                            // this.logoutOpenWASession(this.sessionInfo.tenantId + ':' + this.commChannel._id)
                        } catch (error) {
                            if (error.response) {
                                this.commChannelDetailFlag.error = this.$helpers.createError(error)
                            }
                        }
                    }
                }
            },
            shutdownOpenWa: async function () {
                if (this.commChannel.channelType == 'OPENWA') {
                    var modalResult = await this.$bvModal.msgBoxConfirm(
                        `Matikan whatsapp '${this.commChannel.name}'?`)
                    if (modalResult) {
                        try {
                            await axios.put(process.env.VUE_APP_API_URL + '/commchannel/openwa-kill/' + this
                                .commChannel._id);
                            console.log(`logging out ${this.sessionInfo.tenantId}  ${this.commChannel._id}`)
                        } catch (error) {
                            if (error.response) {
                                this.commChannelDetailFlag.error = this.$helpers.createError(error)
                            }
                        }
                    }
                }
            },
            setChannelBot: async function (kind, status) {
                if (this.commChannel.channelType == 'WACLOUDAPI' || this.commChannel.channelType == 'WABAILEYS') {
                    try {
                        const body = {
                            kind: kind,
                            status: status
                        }
                        
                        const { data } = await axios.put(process.env.VUE_APP_API_URL + '/commchannel/set-bot/' + this.commChannel._id, body);

                        this.commChannel.botState = data.botState
                        this.commChannel.isAutoReplyActive = data.isAutoReplyActive

                        this.commChannelDetailFlag.isLoadingBotState= false
                        this.commChannelDetailFlag.isLoadingBotAutoReply = false

                        if(status == 'GRACEFULLY_STOP') {
                            Toast.showToast("warning", "Status Bot segara dimatikan, pesan terakhir yang masuk akan diselesaikan terlebih dahulu oleh bot")
                        }
                    } catch (error) {
                        this.commChannelDetailFlag.isLoadingBotState= false
                        this.commChannelDetailFlag.isLoadingBotAutoReply = false
                        if (error.response) {
                            this.commChannelDetailFlag.error = this.$helpers.createError(error)
                        }
                    }
                    
                }
            },
            resetToEmptyForm: function () {
                this.commChannelList.forEach(element => {
                    element.selected = false
                })
                this.commChannel = {
                    ...this.commChannel,
                    mode: '',
                    _id: '',
                    name: '',
                    isNotifSender: false,
                    status: 'INACTIVE',
                    webhook: '',
                    channelType: '',
                    waboxToken: '',
                    waboxPhoneNumber: '',
                    waboxDefaultCountryCode: '',
                    fbmsgrVerifyToken: '',
                    fbmsgrPageId: '',
                    fbmsgrPageAccessToken: '',
                    igBusinessAccId: '',
                    fbmsgrAppSecret: '',
                    fbmsgrAppId: '',
                    botState: 'STOP',
                    isAutoReplyActive: false
                }
            },
            utcToLocal: function(date, format) {
                var utcLocal = moment(date).local().format(format);
                return utcLocal;
            },
            utcToLocalView: function(date, format) {
                var utcLocal = moment(date).format(format);
                return utcLocal;
            },
            localToUtc: function(date, format) {
                var utcLocal = moment(date).utc().format(format);
                return utcLocal;
            },
        },
        created: function () {
            this.$emit('update:layout', MainLayout);
        },
        mounted: function () {
            this.showDialog = false
            this.showDialogSession = false
            
            Scrollbar.initAll();
            userScrollBar = Scrollbar.get($('#left-inner-scrollbar').get()[0]);

            var self = this;
            userScrollBar.addListener((status) => {
                if (this.commChannelUIFlag.hasMoreData && status.offset.y == userScrollBar.limit.y) {
                    setTimeout(() => self.loadMoreUsers(), 500);
                }
            });
            if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
                this.loadInitialData();
            }
            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }
        },
        components: {
            ErrorMessage,
            SuccessNotif,
            WAQrLogin,
            SyncMessageDialog,
            ChannelSessionDialog
        }
    }
</script>