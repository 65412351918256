var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card card m-b-0"},[_c('div',{staticClass:"chat-main-box"},[_c('div',{staticClass:"chat-right-aside layer-opacity",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"chat-main-header"}),_c('div',{staticClass:"chat-rbox static-content"},[_c('div',{staticClass:"wrapp-chat-content",staticStyle:{"overflow":"hidden","outline":"none"},attrs:{"data-scrollbar":"true","tabindex":"1"}},[_c('div',{staticClass:"scroll-content"},[_c('div',{staticClass:"contact-add"},[_c('form',{staticClass:"form-horizontal form-material"},[_vm._v(" Daftar Kategori "),_c('a',{staticClass:"btn btn-primary pull-right",on:{"click":function($event){_vm.showAddAutoTemplate = true}}},[_vm._v(" Tambah Kategori ")]),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"wrapp-input-contact m-t-20"},[(!_vm.showAddAutoTemplate)?_c('div',{staticClass:"list-auto-template"},[_vm._m(0),_vm._m(1)]):_vm._e(),(_vm.showAddAutoTemplate)?_c('div',{staticClass:"add-auto-template"},[_vm._v(" Auto Template "),_c('v-switch',{staticClass:"pull-right m-t-0",attrs:{"label":"Aktif"}}),_c('div',{staticClass:"clearfix"}),_c('br'),_vm._m(2),_vm._m(3),_vm._m(4),_c('br'),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-12 col-lg-3 text-right control-label col-form-label",attrs:{"for":"f-user-full-name"}}),_c('div',{staticClass:"col-sm-12 col-lg-8"},[_c('a',{staticClass:"btn btn-primary m-r-10",on:{"click":function($event){_vm.showAddAutoTemplate = false}}},[_vm._v(" Simpan ")]),_c('a',{staticClass:"btn btn-light",on:{"click":function($event){_vm.showAddAutoTemplate = false}}},[_vm._v(" Batal ")])])])],1):_vm._e()])])])]),_vm._m(5),_vm._m(6)])])])])])]),_c('SuccessNotif')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-lg-4"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Cari Judul"}})]),_c('div',{staticClass:"col-sm-12 col-lg-2"},[_c('a',{staticClass:"btn btn-primary",attrs:{"href":""}},[_vm._v(" Cari ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped m-t-20 table-bordered text-center"},[_c('thead',[_c('tr',[_c('th',{staticClass:"font-weight-bold",attrs:{"scope":"col"}},[_vm._v("Waktu Modifikasi")]),_c('th',{staticClass:"font-weight-bold",attrs:{"scope":"col"}},[_vm._v("Kategori")]),_c('th',{staticClass:"font-weight-bold",attrs:{"scope":"col"}},[_vm._v("Status")]),_c('th',{staticClass:"font-weight-bold",attrs:{"scope":"col"}},[_vm._v("Deskripsi")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("06/05/2021")]),_c('td',[_vm._v("Shopping")]),_c('td',[_vm._v("aktif")]),_c('td',[_vm._v("Pesanan nomor 10000100 dengan total pembelanjaan RP 10.000.000, untuk tujuan pengiriman jalan pasir honje no 348, cimeunyan, bandung, jawa barat, 40191 menggunakan kurir JNE Oke, dengan berat 2,5 kg dan ongkos kiri yang harus dibayarkan sebersar Rp 15.000.")])]),_c('tr',[_c('td',[_vm._v("05/05/2021")]),_c('td',[_vm._v("Retur")]),_c('td',[_vm._v("inaktif")]),_c('td',[_vm._v("Status pengembaian produk AT-011 pada nota 1000010, ‘berhasil/tidak berhasil’. Terima kasih banyak telah membeli produk tuneeca.")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-12 col-lg-3 text-right control-label col-form-label",attrs:{"for":"f-password"}},[_vm._v(" Kategori")]),_c('div',{staticClass:"col-sm-12 col-lg-6 m-t-0 p-t-0"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"f-user-full-name","placeholder":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-12 col-lg-3 text-right control-label col-form-label",attrs:{"for":"f-user-full-name"}},[_vm._v(" Judul")]),_c('div',{staticClass:"col-sm-12 col-lg-6 m-t-0 p-t-0"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"f-user-full-name","placeholder":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-12 col-lg-3 text-right control-label col-form-label",attrs:{"for":"f-user-full-name"}},[_vm._v(" Deskripsi")]),_c('div',{staticClass:"col-sm-12 col-lg-8"},[_c('textarea',{staticClass:"form-control",attrs:{"id":"exampleFormControlTextarea1","rows":"3"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scrollbar-track scrollbar-track-x",staticStyle:{"display":"none"}},[_c('div',{staticClass:"scrollbar-thumb scrollbar-thumb-x",staticStyle:{"width":"661px","transform":"translate3d(0px, 0px, 0px)"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scrollbar-track scrollbar-track-y",staticStyle:{"display":"block"}},[_c('div',{staticClass:"scrollbar-thumb scrollbar-thumb-y",staticStyle:{"height":"445.289px","transform":"translate3d(0px, 0px, 0px)"}})])
}]

export { render, staticRenderFns }