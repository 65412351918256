<template>
    <div>
        <layout-wrapper>
            <span>{{ $route.params.id ? 'Detail Email Ditangani' : "Detail Email Ditangani" }}</span>
            <v-btn class="float-right" color="deep-purple accent-4" dark @click="openDialog('send')">
                <span class="text-white">
                    Buat Email
                </span>
            </v-btn>
            <div class="clearfix"></div>
            <br>
            <div class="clearfix"></div>
            <div class="wrapp-input-contact m-t-20 myEmail">
                <div v-if="$route.params.id">
                    <DetailThread :thread="thread" :routeName="'myemail'" @open-dialog="openDialog"
                        @open-dialog-close="openDialogClose" />
                </div>
                <div v-else>
                    <v-row>
                          <v-col cols="12" md="4" class="mb-0-mobile pb-0-mobile">
                            <v-text-field v-model="term" :rules="rules.required" placeholder="Cari ..."></v-text-field>
                        </v-col>

                        <v-col  cols="12" md="4" class="pt-4 pt-0-mobile mt-0-mobile mb-6">
                            <v-btn class="btn-primary mr-2" @click="handleSearch">
                                Cari
                            </v-btn>
                            <v-btn @click="resetFilter">
                                Reset
                            </v-btn>
                        </v-col>

                    </v-row>
                    <v-data-table show-select :headers="headers" :items="desserts" :items-per-page="10"
                        :options.sync="options" :server-items-length="totalElements" :loading="isLoading"
                        :sort-desc="true" sort-by="createdOn" v-model="selected" class="elevation-1 my-5">
                        <template v-slot:item.status="{ item }">
                            <div @click="detailThread(item.id)">
                                <v-icon v-if="!item.isReply" title="Belum Dibalas">mdi mdi-information-outline</v-icon>
                            </div>
                        </template>
                        <template v-slot:item.from="{ item }">
                            <div class="wrapp-link-inbox" @click="detailThread(item.id)">
                                <span class="Sender" :class="{ 'thread-read': !item.isRead }">
                                    {{ item.from }}
                                </span>
                            </div>
                        </template>
                        <template v-slot:item.snippet="{ item }">
                            <div class="emailPreview wrapp-link-inbox" @click="detailThread(item.id)">
                                <span :class="{ 'thread-read': !item.isRead }">{{ item.messages[0].title }}</span>
                            </div>
                        </template>
                        <template v-slot:item.createdOn="{ item }">
                            <div class="wrapp-link-inbox" @click="detailThread(item.id)">
                                <span :class="{ 'thread-read': !item.isRead }">{{ item.time }}</span>
                            </div>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <div style="min-width:155px">
                                <div class="btn-assign pull-left p-0">
                                    <b-button variant="outline-secondary" size="sm" class="m-r-10"
                                        color="btn float-left" small @click="openDialogClose({...item}, 'close')">Tutup
                                    </b-button>
                                    <b-button variant="outline-secondary" size="sm" class="m-r-10"
                                        color="btn float-left" small @click="openDialogClose({...item}, 'pending')">
                                        Tunda</b-button>
                                </div>
                            </div>
                        </template>
                    </v-data-table>
                </div>
            </div>
        </layout-wrapper>

        <v-dialog max-width="600" v-model="closeDialog" persistent>
            <template>
                <v-card>
                    <v-toolbar color="deep-purple accent-4" dark>
                        <span class="text-white">
                            {{ dialogCloseType == 'close' ? 'Tutup' : 'Tunda' }} Percakapan
                        </span>
                    </v-toolbar>
                    <v-card-text>
                        <div class="my-5">
                            <span>
                                {{ dialogCloseType == 'close' ? 'Tutup' : 'Tunda' }} percakapan dengan
                                <strong>'{{ thread.from }}'</strong> ini sekarang?
                            </span>
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn text @click="closeDialog = false">Nanti</v-btn>
                        <v-btn v-if="dialogCloseType == 'close'" class="btn btn-primary text-white"
                            @click="closeEmail(thread._id)" :loading="isLoadingAssign">
                            Sekarang
                        </v-btn>
                        <v-btn v-else class="btn btn-primary text-white" @click="pendingEmail(thread._id)"
                            :loading="isLoadingAssign">
                            Sekarang
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <v-dialog max-width="600" v-model="sendEmailDialog" persistent>
            <template>
                <v-card>
                    <v-toolbar color="deep-purple accent-4" dark>
                        <span class="text-white">
                            {{ dialogType == 'send' ? 'Kirim Email' : 'Balas Email' }}
                        </span>
                    </v-toolbar>
                    <v-card-text>
                        <v-form class="my-5">
                            <v-text-field outlined required :disabled="dialogType != 'send'" v-model="formDialog.to"
                                label="Dikirim Ke"></v-text-field>
                            <v-text-field outlined required :disabled="dialogType != 'send'"
                                v-model="formDialog.subject" label="Subjek"></v-text-field>
                            <v-textarea outlined v-model="formDialog.text" label="Deskripsi"></v-textarea>
                            <v-file-input chips counter multiple show-size truncate-length="15" v-model="files"
                                label="Lampiran"></v-file-input>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn text @click="sendEmailDialog = false">Kembali</v-btn>
                        <v-btn class="btn btn-primary text-white" @click="sendEmail" :loading="isLoadingSend">
                            {{ dialogType == 'send' ? 'Kirim' : 'Balas' }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <SuccessNotif />
    </div>
</template>
<script>
    import axios from 'axios'
    import Scrollbar from 'smooth-scrollbar'
    // import ErrorMessage from '../components/ErrorMessage'
    import MainLayout from "../layouts/MainLayout"
    import SuccessNotif from "../components/SuccessNotif"
    import {
        mapState,
        mapActions,
        mapMutations
    } from 'vuex'

    const $ = require("jquery")
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup')
    var moment = require('moment');

    const {
        toFriendlyDateTime
    } = require('../utils/date-utils');

    import DetailThread from '../components/DetailThread.vue'
import LayoutWrapper from '../layouts/components/LayoutWrapper.vue'

    var messageScrollBar = null;

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo,
                broadcastCallback: state => state.socket.broadcastCallback,
                threadCallback: state => state.socket.emailThreadCallback,
                threadUpdateCallback: state => state.socket.emailThreadUpdateCallback,
                messageCallback: state => state.socket.emailMessageCallback
            })
        },
        data: function () {
            return {
                totalElements: 0,

                dialogType: 'send',
                dialogCloseType: 'close',

                term: String(),
                
                desserts: Array(),
                selected: Array(),
                
                options: Object(),
                thread: Object(),
                
                isLoading: false,
                formValid: false,
                sendEmailDialog: false,
                closeDialog: false,
                isLoadingSend: false,
                isLoadingAssign: false,

                files: null,

                formDialog: {
                    to: String(),
                    subject: String(),
                    text: String()
                },

                headers: [{
                        text: 'Status',
                        value: 'status'
                    },{
                        text: 'Pengirim',
                        value: 'from'
                    },
                    {
                        text: 'Subjek',
                        value: 'snippet',
                        sortable: false
                    },
                    {
                        text: 'Tanggal',
                        value: 'createdOn'
                    },
                    {
                        text: 'Aksi',
                        value: 'action',
                        sortable: false
                    },
                ],

                rules: {
                    required: [(v) => !!v || ""],
                    requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                    requiredArray: [(v) => v.length > 0 || ""],
                },
            }
        },
        watch: {
            options: {
                async handler() {
                    await this.loadEmailList();
                },
                deep: true,
            },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            },
            $route: async function(val) {
                const params = val.params

                this.thread = Object()
                if(params.id) {
                    await this.loadEmail(params.id)
                    messageScrollBar.update();
                    messageScrollBar.scrollTo(0, messageScrollBar.getSize().content.height, 0);
                }
            },
            sendEmailDialog: function(val) {
                if(!val) {
                    this.formDialog = {
                        to: String(),
                        subject: String(),
                        text: String()
                    }

                    this.files = null
                }
            },
            // closeDialog: function(val) {
            //     if(!val) {
            //         this.thread = Object()
            //     }
            // }
            threadCallback: function(val) {
                if(val) {
                    if(val.status != 'open') return
                    if(!val.operator) return
                    if(val.operator.id != this.sessionInfo.userId) return

                    let desserts = [...this.desserts]
                    const index = desserts.findIndex(e => e._id == val._id)

                    val.id = val._id
                    val.time = toFriendlyDateTime(val.createdOn)

                    if(index >= 0) desserts.splice(index, 1)
    
                    desserts.unshift(val)
                    
                    this.desserts = desserts.slice(0, 10)
                }
            },
            threadUpdateCallback: function(val) {
                if(val) {
                    let desserts = [...this.desserts]
                    const index = desserts.findIndex(e => e._id == val._id)

                    val.id = val._id
                    val.createdOn = parseInt(val.createdOn)
                    val.time = toFriendlyDateTime(val.createdOn)

                    let deletedIndex = null
                    
                    if(index >= 0) {
                        desserts.splice(index, 1, val)

                        if(val.status != 'open') deletedIndex = index
                        if(val.operator) deletedIndex = index
                    } 

                    if(deletedIndex != null) desserts.splice(deletedIndex, 1)

                    this.desserts = desserts
                }
            },
            messageCallback: function(val) {
                if(val && this.thread && val.threadId == this.thread._id) {
                    let data = { ...this.thread }
                    const index = data.messages.findIndex(e => e._id == val._id)

                    if(index < 0) {
                        val.time = toFriendlyDateTime(val.createdOn)
    
                        if(val.attachments && val.attachments.length > 0) {
                            val.attachments = val.attachments.map(el => {
                                const splitted = el.mimeType.split("/")
                                el.contentType = splitted[0]
    
                                return el
                            })
                        }
    
                        if(val.direction == 'IN' && val.from.includes("<")) {
                            const splitted = val.from.split('<')
                            val.fromName = splitted[0].substring(0, splitted[0].length - 1); 
                            val.fromEmail = splitted[1].substring(0, splitted[1].length - 1); 
                        }
    
                        data.messages.push(val)
    
                        this.thread = data
                    }

                    messageScrollBar.update();

                    setTimeout(() => {
                        messageScrollBar.scrollTo(0, messageScrollBar.getSize().content.height, 0);
                    })
                }
            }
        },
        methods: {
            ...mapActions({
                actGetThreadCount: 'getThreadCount'
            }),
            ...mapMutations({
                setNewMyEmail: 'setNewMyEmail',
            }),
            openDialogClose: async function(item, kind) {
                this.dialogCloseType = kind
                this.thread = item
                this.closeDialog = true
            },
            openDialog: async function(kind) {
                this.dialogType = kind

                if(kind == 'reply') {
                    const formDialog = {
                        to: this.thread.from,
                        subject: this.thread.messages[0].title,
                        text: String()
                    }
    
                    if(this.thread.from.includes("<")) {
                        const str = this.thread.from.split("<")[1]
    
                        formDialog.to = str.slice(0, -1)
                    }

                    this.formDialog = formDialog
                } 
                
                this.sendEmailDialog = true
            },
            handleSearch: async function() {
                this.options.page = 1
                await this.loadEmailList()
            },
            resetFilter: async function() {
                this.term = String()

                await this.loadEmailList()
            },
            loadInitialData: async function () {
                this.loadEmailList()
            },
            detailThread: async function(id) {
                this.$router.push({
                    name: 'myemail',
                    params: { id: encodeURIComponent(id) }
                });
            },
            closeEmail: async function(id) {
                try {
                    this.isLoadingAssign = true

                    const body = Object()

                    await axios.put(process.env.VUE_APP_API_URL + `/email/threads-close/${id}`, body)

                    this.isLoadingAssign = false
                    this.closeDialog = false
                    
                    if(this.$route.params.id) {
                        this.$router.push({ name: 'myemail' });
                    } else {
                        await this.loadEmailList()
                    }
                } catch (error) {
                    this.isLoadingAssign = false
                    console.log("assign-email", error);      
                }
            },
            pendingEmail: async function(id) {
                try {
                    this.isLoadingAssign = true

                    const body = Object()

                    await axios.put(process.env.VUE_APP_API_URL + `/email/threads-pending/${id}`, body)

                    this.isLoadingAssign = false
                    this.closeDialog = false

                    if(this.$route.params.id) {
                        this.$router.push({ name: 'myemail' });
                    } else {
                        await this.loadEmailList()
                    }
                } catch (error) {
                    this.isLoadingAssign = false
                    console.log("assign-email", error);      
                }
            },
            sendEmail: async function() {
                try {
                    this.isLoadingSend = true

                    const files = this.files ? [...this.files] : Array()
                    const body = { ...this.formDialog }

                    if(files && files.length > 0) {
                        const attachments = Array()

                        for (let index = 0; index < files.length; index++) {
                            const file = files[index];
                            
                            const base64File = await this.fileToBase64(file)

                            attachments.push({
                                contentType: file.type,
                                fileName: file.name,
                                data: base64File.split(',')[1],
                            })
                        }

                        body.attachments = attachments
                    }
                
                    if(this.dialogType == 'reply') {
                        body.id = this.thread.messages[0]._id
                        body.from = this.thread.toEmail
                    }
                    
                    const { data } = await axios.post(process.env.VUE_APP_API_URL + `/email/send`, body)

                    this.isLoadingSend = false
                    this.sendEmailDialog = false

                    if(this.dialogType == 'reply') {
                        const thread = { ...this.thread } 
                        const messages = [ ...thread.messages, data ] 
                        thread.messages = messages

                        this.thread = thread
                    } else {
                        await this.loadEmailList()
                    }
                } catch (error) {
                    this.isLoadingSend = false
                    console.log("send-email", error);
                }
            },
            loadEmail: async function(id) {
                this.isLoading = true
                
                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + `/email/threads/${id}`)

                    data.messages = data.messages.map(e => {
                        e.time = toFriendlyDateTime(e.createdOn)

                        if(e.attachments && e.attachments.length > 0) {
                            e.attachments = e.attachments.map(el => {
                                const splitted = el.mimeType.split("/")
                                el.contentType = splitted[0]

                                return el
                            })
                        }

                        if(e.direction == 'IN' && e.from.includes("<")) {
                            const splitted = e.from.split('<')
                            e.fromName = splitted[0].substring(0, splitted[0].length - 1); 
                            e.fromEmail = splitted[1].substring(0, splitted[1].length - 1); 
                        }

                        return e
                    })
                    
                    if(data.to.includes("<")){
                        const splitted = data.to.split('<')

                        data.toEmail = splitted[1].substring(0, splitted[1].length - 1);
                    } else {
                        data.toEmail = data.to
                    }

                    this.thread = data
                } catch (error) {
                    console.log("load-email-list", error);
                }

                this.isLoading = false
            },
            loadEmailList: async function() {
                this.isLoading = true
                let params = Object();

                params.term = this.term
                params.sort = 'createdOn,desc'

                if (this.options && this.options.page) params.page = this.options.page;
                if (this.options && this.options.itemsPerPage) params.size = this.options.itemsPerPage;
                if (this.options && this.options.sortBy && this.options.sortBy.length > 0) {
                    const sortBy = this.options.sortBy[0];
                    const sortOrder = this.options.sortDesc[0] ? 'desc' : 'asc';
                    params.sort = sortBy + ',' + sortOrder
                }
                
                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/email/threads-assigned', {
                        params
                    })

                    this.desserts = data.content.map(e => {
                        e.id = e._id
                        e.time = toFriendlyDateTime(e.createdOn)
                        
                        return e
                    })

                    this.totalElements = data.page.totalElements
                } catch (error) {
                    console.log("load-email-list", error);
                }
                this.isLoading = false
            },
            fileToBase64: function(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            },
            utcToLocal: function(date, format) {
                var utcLocal = moment(date).local().format(format);
                return utcLocal;
            },
            utcToLocalView: function(date, format) {
                var utcLocal = moment(date).format(format);
                return utcLocal;
            },
            localToUtc: function(date, format) {
                var utcLocal = moment(date).utc().format(format);
                return utcLocal;
            },
        },
        created: function () {
            this.$emit('update:layout', MainLayout);
        },
        mounted: async function () {
            Scrollbar.initAll();
            messageScrollBar = Scrollbar.get($('.wrapp-chat-content').get()[0]);

            if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
                //     this.loadInitialData();
                this.actGetThreadCount();
                this.setNewMyEmail(false)
            }
            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }

            if(this.$route.params && this.$route.params.id) {
                await this.loadEmail(this.$route.params.id)

                messageScrollBar.update();
                messageScrollBar.scrollTo(0, messageScrollBar.getSize().content.height, 0);
            }
        },
        components: {
            'layout-wrapper': LayoutWrapper,
            // ErrorMessage,
            SuccessNotif,
            DetailThread
        }
        
    }
</script>