<template>
    <div class="row">
        <div class="col-12">
            <div class="card card m-b-0">
                <!-- .chat-row -->
                <div class="chat-main-box">
                    <!-- .chat-left-panel -->
                    <div class="chat-left-aside">
                        <div class="open-panel" hidden>
                            <i class="ti-angle-right"></i>
                        </div>
                        <div class="chat-left-inner form-horizontal" style="height: 3px;">
                            <div class="form-material">
                                <div class="input-group searchbox" v-if="!showConversation">
                                    <input style="border:none;" type="text" class="form-control" id="uname"
                                        @keyup.enter="searchContactFlag" placeholder="Cari.." autocomplete="off"
                                        v-model="contactUiFlag.searchTerm">
                                    <div style="border:none;" class="input-group-addon">
                                        <!-- <a v-b-modal.modal-center>
                                                <v-icon>mdi-sort</v-icon>
                                            </a> -->
                                        <a v-if="!showFilter" @click="showFilter = !showFilter">
                                            <v-icon>mdi-sort</v-icon>
                                        </a>
                                        <a v-if="showFilter" @click="resetCustomFilter">
                                            <v-icon>mdi-arrow-left</v-icon>
                                        </a>
                                    </div>
                                </div>
                                <div v-else>
                                    <a id="cancelSearch" class="btn m-2 text-muted" @click="cancelSearchPersonalMessage()">
                                        <v-icon class="">mdi-close</v-icon> <span class="ml-2 pt-1">Cari Pesan</span>  
                                    </a>
                                </div>
                                <b-modal id="modal-center" centered title="Filter & Urutkan"
                                    @hidden="resetCustomFilter" @ok="customFilter">
                                    <div>
                                        <div class="filter-sort-chat">
                                            <span class="filter">
                                                <input type="text" class="mb-4 form-control" id="uname"
                                                    placeholder="Cari.." autocomplete="off"
                                                    v-model="customFilterUiFlag.searchTerm">
                                                <h5 class="mt-0">Filter</h5>
                                                <b-button pill
                                                    :variant="customFilterUiFlag.selectedSearchBy == 'contact' ? 'primary' : 'secondary'"
                                                    class="mr-2"
                                                    @click="customFilterUiFlag.selectedSearchBy = 'contact'">
                                                    <i class="mdi mdi-account"></i> Kontak
                                                </b-button>
                                                <b-button pill
                                                    :variant="customFilterUiFlag.selectedSearchBy == 'messages' ? 'primary' : 'secondary'"
                                                    @click="customFilterUiFlag.selectedSearchBy = 'messages'">
                                                    <i class="mdi mdi-message"></i> Pesan
                                                </b-button>
                                                <h5 class="mt-4">Urutkan</h5>
                                                <div v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                                    <b-button pill
                                                        :variant="customFilterUiFlag.sortOrder == 'lastActive' && customFilterUiFlag.sortDir == 'desc' ? 'primary' : 'secondary'"
                                                        class="mr-2"
                                                        @click="customFilterUiFlag.sortOrder = 'lastActive', customFilterUiFlag.sortDir = 'desc'">
                                                        Paling Baru
                                                    </b-button>
                                                    <b-button pill
                                                        :variant="customFilterUiFlag.sortOrder == 'lastActive' && customFilterUiFlag.sortDir == 'asc' ? 'primary' : 'secondary'"
                                                        class="mr-2"
                                                        @click="customFilterUiFlag.sortOrder = 'lastActive', customFilterUiFlag.sortDir = 'asc'">
                                                        Paling Lama
                                                    </b-button>

                                                    <b-button pill
                                                        :variant="customFilterUiFlag.sortOrder == 'name' ? 'primary' : 'secondary'"
                                                        class="mr-2"
                                                        @click="customFilterUiFlag.sortOrder = 'name', customFilterUiFlag.sortDir = 'asc'">
                                                        Pengirim
                                                    </b-button>
                                                </div>
                                                <div v-if="customFilterUiFlag.selectedSearchBy != 'contact'">
                                                    <b-button pill
                                                        :variant="customFilterUiFlag.sortMessage == 'desc' ? 'primary' : 'secondary'"
                                                        class="mr-2"
                                                        @click="customFilterUiFlag.sortMessage = 'desc'">
                                                        Paling Baru
                                                    </b-button>
                                                    <b-button pill
                                                        :variant="customFilterUiFlag.sortMessage == 'asc' ? 'primary' : 'secondary'"
                                                        class="mr-2"
                                                        @click="customFilterUiFlag.sortMessage = 'asc'">
                                                        Paling Lama
                                                    </b-button>
                                                </div>
                                                <br>
                                                <div v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                                    <h5 class="mt-4 mb-0">Peran Pelanggan</h5>
                                                    <v-autocomplete class="label-label"
                                                        v-model="customFilterUiFlag.custroles"
                                                        :items="customerRoleList" hide-selected multiple
                                                        item-text="name" item-value="id"
                                                        :loading="custRoleLoading"
                                                        label="Tekan Esc jika sudah memilih">
                                                        <template v-slot:selection="data">
                                                            <v-chip v-bind="data.attrs"
                                                                :input-value="data.selected" close
                                                                @click="data.select"
                                                                @click:close="removeSelected(data.item, 'role', true)">
                                                                {{ data.item.name }}
                                                            </v-chip>
                                                        </template>
                                                    </v-autocomplete>
                                                    <h5 class="mt-2 mb-0">Label</h5>
                                                    <v-autocomplete class="label-label"
                                                        v-model="customFilterUiFlag.labels" :items="labels"
                                                        hide-selected multiple persistent-hint chips
                                                        label="Tekan Esc jika sudah memilih">
                                                        <template v-slot:selection="data">
                                                            <v-chip v-bind="data.attrs"
                                                                :input-value="data.selected" close
                                                                @click="data.select"
                                                                @click:close="removeSelected(data.item, 'label', true)">
                                                                {{ data.item }}
                                                            </v-chip>
                                                        </template>
                                                    </v-autocomplete>
                                                </div>
                                            </span>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                </b-modal>
                                <div class="clearfix"></div>
                            </div>
                            <div v-if="!showSearchMessages && !showConversation && !showFilter"
                                class="wrapp-filter-sort">
                                <b-badge class="sort-chat" pill v-if="contactUiFlag.sortOrder && contactUiFlag.sortDir">
                                    <v-icon class="icon-sort-filter text-primary">mdi-sort</v-icon>
                                    {{ contactUiFlag.sortOrder == 'name' ? 'Pengirim' : contactUiFlag.sortOrder == 'lastActive' && contactUiFlag.sortDir == 'desc' ? 'Paling Baru' : 'Paling Lama'}}
                                </b-badge>
                                <b-badge pill class="sort-chat">
                                    <v-icon class="icon-sort-filter text-primary" left>mdi-account</v-icon>
                                    {{ selectedSearchBy }}
                                </b-badge>
                                 <b-badge class="tag-label badge badge-warning badge-pill"  v-if="selectedStars">
                                    <span>
                                        <v-icon class="icon-sort-filter text-primary" left>mdi-star</v-icon> Ditandai
                                        <a title="Hapus Filter" @click="removeSelected('', 'stars', false)">
                                            <i class="mdi mdi-close"></i>
                                        </a>
                                    </span>
                                </b-badge>
                                <b-badge class="tag-label" pill variant="primary"
                                    v-for="(item, index) in selectedLabels" :key="index">
                                    <span v-if="index < 2">
                                        <v-icon style="font-size:10px !important" class="icon-sort-filter text-primary"
                                            left>mdi-tag</v-icon> {{ item }} <a title="Hapus Filter"
                                            @click="removeSelected(item, 'label', false)"><i
                                                class="mdi mdi-close"></i></a>
                                    </span>
                                </b-badge>
                                <v-tooltip bottom v-if="selectedLabels.length > 2">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span class="tag-label badge badge-primary badge-pill" v-bind="attrs" v-on="on">
                                            +{{ selectedLabels.length - 2 }}
                                        </span>
                                    </template>
                                    <div v-for="(item, index) in selectedLabels" :key="item">
                                        <span v-if="index > 1">
                                            <v-icon class="icon-sort-filter text-white" left>mdi-tag</v-icon> {{ item }}
                                        </span>
                                    </div>
                                </v-tooltip>
                                <b-badge class="cusRole" pill variant="primary" v-for="(item, index) in selectedRoles"
                                    :key="item">
                                    <span v-if="index < 2">
                                        <v-icon style="font-size:10px !important" class="icon-sort-filter text-primary"
                                            left>mdi-account-multiple</v-icon> {{ item }} <a title="Hapus Filter"
                                            @click="removeSelected(item, 'role', false)"><i
                                                class="mdi mdi-close"></i></a>
                                    </span>
                                </b-badge>
                                <v-tooltip bottom v-if="selectedRoles.length > 2">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span class="cusRole badge badge-primary badge-pill" v-bind="attrs" v-on="on">
                                            +{{ selectedRoles.length - 2 }}
                                        </span>
                                    </template>
                                    <div v-for="(item, index) in selectedRoles" :key="item">
                                        <span v-if="index > 1">
                                            <v-icon class="icon-sort-filter text-white" left>mdi-account-multiple
                                            </v-icon> {{ item }}
                                        </span>
                                    </div>
                                </v-tooltip>
                            </div>
                            <div v-if="showSearchMessages && !showConversation && !showFilter"
                                class="wrapp-filter-sort">
                                <b-badge class="sort-chat" pill>
                                    <v-icon class="icon-sort-filter text-primary" left>mdi-sort</v-icon>
                                    {{ contactUiFlag.sortBy == 'desc' ? 'Paling Baru' : 'Paling Lama' }}
                                </b-badge>

                                <b-badge pill class="sort-chat">
                                    <v-icon class="icon-sort-filter text-primary" left>mdi-account</v-icon>
                                    {{ selectedSearchBy }}
                                </b-badge>
                            </div>
                            <div class="clearfix"></div>
                            <div class="filter-sort-chat" v-if="showFilter">
                                <span class="filter">
                                    <h6 class="mt-0">Filter</h6>
                                    <b-button pill
                                        :variant="customFilterUiFlag.selectedSearchBy == 'contact' ? 'primary' : 'secondary'"
                                        class="mr-2" size="sm" @click="customFilterUiFlag.selectedSearchBy = 'contact'">
                                        <i class="mdi mdi-account"></i> Kontak
                                    </b-button>
                                    <b-button pill size="sm"
                                        :variant="customFilterUiFlag.selectedSearchBy == 'messages' ? 'primary' : 'secondary'"
                                        @click="customFilterUiFlag.selectedSearchBy = 'messages'">
                                        <i class="mdi mdi-message"></i> Pesan
                                    </b-button>
                                    <h6 class="mt-4">Urutkan</h6>
                                    <div v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                        <b-button pill
                                            :variant="customFilterUiFlag.sortOrder == 'lastActive' && customFilterUiFlag.sortDir == 'desc' ? 'primary' : 'secondary'"
                                            class="mr-2" size="sm"
                                            @click="customFilterUiFlag.sortOrder = 'lastActive', customFilterUiFlag.sortDir = 'desc'">
                                            Paling Baru
                                        </b-button>
                                        <b-button pill
                                            :variant="customFilterUiFlag.sortOrder == 'lastActive' && customFilterUiFlag.sortDir == 'asc' ? 'primary' : 'secondary'"
                                            class="mr-2" size="sm"
                                            @click="customFilterUiFlag.sortOrder = 'lastActive', customFilterUiFlag.sortDir = 'asc'">
                                            Paling Lama
                                        </b-button>
                                        <b-button pill
                                            :variant="customFilterUiFlag.sortOrder == 'name' ? 'primary' : 'secondary'"
                                            class="mr-2" size="sm"
                                            @click="customFilterUiFlag.sortOrder = 'name', customFilterUiFlag.sortDir = 'asc'">
                                            Pengirim
                                        </b-button>
                                    </div>
                                    <div v-if="customFilterUiFlag.selectedSearchBy != 'contact'">
                                        <b-button pill
                                            :variant="customFilterUiFlag.sortMessage == 'desc' ? 'primary' : 'secondary'"
                                            class="mr-2" size="sm" @click="customFilterUiFlag.sortMessage = 'desc'">
                                            Paling Baru
                                        </b-button>
                                        <b-button pill
                                            :variant="customFilterUiFlag.sortMessage == 'asc' ? 'primary' : 'secondary'"
                                            class="mr-2" size="sm" @click="customFilterUiFlag.sortMessage = 'asc'">
                                            Paling Lama
                                        </b-button>
                                    </div>
                                    <div v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                        <h6 class="mt-4">Kontak Ditandai</h6>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.stars ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.stars = true">
                                            Ya
                                        </b-button>

                                        <b-button pill size="sm"
                                            :variant="!customFilterUiFlag.stars ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.stars = false">
                                            Tidak
                                        </b-button>
                                    </div>
                                    <div class="mt-4" v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                        <v-autocomplete class="label-label mb-0 pb-0" v-model="customFilterUiFlag.custroles"
                                            :items="customerRoleList" hide-selected multiple item-text="name"
                                            item-value="id" :loading="custRoleLoading" label="Peran Pelanggan">
                                            <template v-slot:selection="data">
                                                <v-chip v-bind="data.attrs" :input-value="data.selected" close
                                                    @click="data.select"
                                                    @click:close="removeSelected(data.item, 'role', true)">
                                                    {{ data.item.name }}
                                                </v-chip>
                                            </template>
                                        </v-autocomplete>
                                        <v-autocomplete class="label-label" v-model="customFilterUiFlag.labels"
                                            :items="labels" hide-selected multiple persistent-hint chips label="Label">
                                            <template v-slot:selection="data">
                                                <v-chip v-bind="data.attrs" :input-value="data.selected" close
                                                    @click="data.select"
                                                    @click:close="removeSelected(data.item, 'label', true)">
                                                    {{ data.item }}
                                                </v-chip>
                                            </template>
                                        </v-autocomplete>
                                    </div>
                                </span>
                                <span class="pull-right">
                                    <b-button variant="secondary" class="mr-2" size="sm" @click="resetCustomFilter">
                                        Batal
                                    </b-button>
                                    <b-button variant="primary" class="mr-2" size="sm" @click="customFilter">
                                        Simpan
                                    </b-button>
                                </span>
                            </div>
                            <!-- <div class="p-15 filter-chat" style="padding-bottom:0; " v-if="showFilter">
                                <v-combobox small-chips multiple v-model="value" :items="items"
                                    :menu-props="{ maxHeight: '400' }" label="Filter" persistent-hint>
                                </v-combobox>
                            </div> -->
                            <div class="form-body" v-if="!$helpers.isBlank(contactUiFlag.error)">
                                <div class="form-material form-filter">
                                    <ErrorMessage :error="contactUiFlag.error" />
                                </div>
                            </div>
                            <div id="contact-loader-indicator" v-bind:class="{'d-none': !contactUiFlag.isLoading}" class="text-center">
                                 <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                            </div>
                            
                            <ContactList
                                :page="'unassigned'"
                                :contactList="contactList"
                                :searchMessageList="searchMessageList"
                                :msgUiFlag="msgUiFlag"
                                :contactUiFlag="contactUiFlag"
                                :showConversation="showConversation"
                                :showSearchMessages="showSearchMessages"
                                :showFilter="showFilter"
                                @jumpToElement="jumpToElement"
                                @selectContact="selectContact" />
                        </div>
                    </div>
                    <!-- .chat-left-panel -->
                    <!-- .chat-right-panel -->
                    <div class="chat-right-aside layer-opacity">
                        <div class="layer-disable"></div>
                        <div class="chat-rbox">
                            <MessageHeader 
                                :page="'unassigned'"
                                :msgUiFlag="msgUiFlag"
                                :contactUiFlag="contactUiFlag"
                                :showConversation="showConversation"
                                @searchPersonalMessage="searchPersonalMessage"
                                @saveContactCallback="saveContactCallback"
                            />

                            <MessageList
                                :page="'unassigned'"
                                :msgUiFlag="msgUiFlag"
                                :messageList="messageList"
                                :loadingJump="loadingJump"
                                @jumpToElement="jumpToElement"
                                @openNote="openNote" />
                        </div>
                        <div class="btn-assign" style="padding: 20px; margin: auto; text-align: center;"
                            v-show="showButtonAdd">
                            <p class="text-muted mb-2">Alihkan penanganan pesan ke:</p>
                            <a class="btn btn-primary mr-2" @click="takeConversation">Saya</a>
                            <b-dropdown style="display: inline-block;" text="Rekan">
                                <span class="" style="padding: 8px 1rem; display:block">
                                    <input class="form-control" type="text" placeholder="Search" @input="filterReturn($event)" aria-label="Search" />
                                </span>
                                <b-dropdown-item v-for="(item) in filterOperator" :key="item.id" class="list-account">
                                    <span class="wrap-userImage" style="display:none">
                                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg=="
                                            v-bind:title="item.name"
                                            @click="transferConversation($event, item._id, item.name)">
                                        <!-- <img src="https://via.placeholder.com/150"> -->
                                    </span>

                                    <span class="pull-left wrapp-list-account itemName" href="#">
                                        <a class="" @click="transferConversation($event, item._id, item.name)"
                                            v-bind:value="item.name">
                                            {{item.name}}
                                        </a>
                                    </span>
                                    <span class="online-status" v-if="item.active == 1">
                                        <i style="color:green" class="mdi mdi-circle"></i>
                                    </span>
                                    <div class="clearfix"></div>

                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <!-- .chat-right-panel -->
                    </div>
                    <!-- /.chat-row -->
                </div>
            </div>
        </div>
        <UpdateNote v-bind:contactId="contactUiFlag.selectedContactId" v-bind:messageId="selectedMessage._id" :sessionId="contactUiFlag.selectedSessionId"
            v-bind:currentNote="selectedMessage.internalNotes" :showDialog="dialogNote" @close-dialog="closeDialog" />
        <TakeConversation v-bind:contactId="contactUiFlag.selectedContactId"
            v-bind:contactName="contactUiFlag.selectedContactName"
            v-bind:sessionId="contactUiFlag.selectedSessionId"
            v-on:take-callback="conversationTransferedCallback" />
        <TransferConversation v-bind:contactId="contactUiFlag.selectedContactId"
            v-bind:contactName="contactUiFlag.selectedContactName" v-bind:toOperatorId="assignToData.operatorId"
            v-bind:toOperatorName="assignToData.operatorName" v-on:take-callback="conversationTransferedCallback"
            :sessionId="contactUiFlag.selectedSessionId" />
        <AddToContact v-bind:addedContactId="contactUiFlag.selectedContactId"
            v-bind:phoneNumber="contactUiFlag.selectedContactId" v-on:save-callback="saveContactCallback" />
        <ImageViewer v-bind:imageUrl="selectedImage.imageUrl" v-bind:caption="selectedImage.caption" />
        <Alert :message="alertMessage" />
    </div>
</template>

<script>
    import Scrollbar from 'smooth-scrollbar';
    import axios from "axios";
    import MainLayout from "../layouts/MainLayout"
    import TakeConversation from '../components/TakeConversation'
    import TransferConversation from '../components/TransferConversation'
    import AddToContact from '../components/AddToContact'
    import ImageViewer from '../components/ImageViewer'
    import ErrorMessage from '../components/ErrorMessage'
    import Alert from '../components/Alert'
    import IMAGE_ACC from '../../public/images/dummy.jpg'
    import IMAGE_MESSAGE from '../../public/images/image-not-found.png'
    import UpdateNote from '../components/UpdateNote'
    import MessageList from '../components/MessageList.vue';
    import ContactList from '../components/ContactList.vue';
    import MessageHeader from '../components/MessageHeader.vue';

    import {
        mapState,
        mapActions
    } from 'vuex'

    const $ = require("jquery")
    const {
        toFriendlyDateTime
    } = require('../utils/date-utils');
    const {
        updateContactList
    } = require('../utils/contact-utils');
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup');
    var firebase = require("firebase");
    var moment = require('moment');
    var debounce = require('lodash.debounce');
    const {
        MODULES
    } = require('../utils/const')

    const CONTACT_ROW_PER_PAGE = 25;
    const MESSAGE_ROW_PER_PAGE = 25;

    var messageScrollBar = null;
    var contactScrollBar = null;

    var messageListnerList = [];
    var removeContactListener = null;

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo,
                operatorList: state => state.operator.operatorList,
                operatorActive: state => state.socket.activeOperatorIds,
                labels: state => state.select.labels,
                customerRoleList: state => state.select.customerRoles,
                isAccountTest: state => state.session.sessionInfo.userId == "bippofb"
            })
        },
        data: function () {
            return {
                selectedMessage: Object(),
                dialogNote: false,
                //operatorList: [],
                showSearchMessages: false,
                showConversation: false,
                custRoleLoading: false,
                loadingJump: false,
                selectedLabels: [],
                selectedRoles: [],
                selectedSearchBy: 'contact',
                selectedStars: false,
                // labels: [],
                searchMessageList: {
                    personal: [],
                    global: [],
                },
                searchLabel: String(),
                items: ['Sudah dibaca', 'Belum dibaca', 'Retur', 'Komplain', 'Shopping'],
                showFilter: false,
                value: [],
                // customerRoleList: [],
                filterOperator: [],
                contactList: [],
                messageList: [],
                contactUiFlag: {
                    isLoading: false,
                    currentPage: 1,
                    searchTerm: '',
                    sortOrder: 'lastActive',
                    sortDir: 'desc',
                    selectedContactId: '',
                    selectedContactName: '',
                    selectedContactRole: '',
                    selectedIsUnknonwnContact: null,
                    hasMoreData: true,
                    error: {},
                    labels: [],
                    accountLabels: [],
                    custroles: [],
                    sortBy: '',
                    selectedSessionId: '',
                    selectedProfilePicUrl: '',
                    selectedStars: false,
                    isAffiliator: false,
                    currentAffiliator: null,
                },
                customFilterUiFlag: {
                    sortOrder: 'lastActive',
                    sortDir: 'desc',
                    labels: [],
                    custroles: [],
                    selectedSearchBy: 'contact',
                    sortMessage: 'desc',
                    stars: false,
                },
                msgUiFlag: {
                    isLoading: false,
                    currentPage: 1,
                    globalCurrentPage: 1,
                    searchTerm: String(),
                    hasMoreData: true,
                    hasMoreDataNext: true,
                    globalHasMoreData: true,
                    error: {}
                },
                assignToData: {
                    operatorId: '',
                    operatorName: '',
                    sessionId: ''
                },
                newContact: {
                    id: '',
                    name: '',
                    saveErrorMessage: ''
                },
                isTyping: false,
                isTypingMsg: false,
                selectedImage: {
                    imageUrl: '',
                    caption: ''
                },
                showButtonAdd: false,
                alertMessage: {}
            }
        },
        watch: {
            'contactUiFlag.searchTerm': debounce(async function () {
                if (this.contactUiFlag.searchTerm == "") {
                    if (this.selectedSearchBy == 'contact') {
                        await this.loadContact()
                    }
                }
            }, 1000),
            'msgUiFlag.searchTerm': debounce(function (value) {
                if (value == '') {
                    this.showConversation = false
                    return
                }
                this.resetCustomFilter()
                // this.showConversation = true
                // this.isTypingMsg = false;
                // this.msgUiFlag.hasMoreDataNext = true
            }, 1000),
            // selectedLabels: debounce(async function() {
            //    await this.loadContact()
            // }, 1000),
            selectedSearchBy: function (value) {
                this.showConversation = false
                if (value == 'messages') {
                    this.showSearchMessages = true
                } else {
                    this.showSearchMessages = false
                }
            },
            // isTyping: async function (value) {
            //     if (!this.showFilter) {                    
            //         if (!value) {
            //             if (this.selectedSearchBy == 'contact') {
            //                 await this.loadContact();
            //                 this.showConversation = false
            //             } else {
            //                 await this.searchGlobalMessage();
            //             }
            //         }
            //     }
            // },
            isTypingMsg: async function (value) {
                if (this.msgUiFlag.searchTerm == '') {
                    this.showConversation = false
                    return
                }
                if (!value) {
                    await this.searchPersonalMessage();
                }
            },
            $route(to, from) {
                if (from == null || (to.params.id != from.params.id)) {
                    if (to.params.id) {
                        this.selectChat(decodeURIComponent(to.params.id));
                    }
                }

                const self = this
                if (to.query.chatId) {
                    setTimeout(async function () {
                        await self.jumpToElementGlobal(decodeURIComponent(to.query.chatId))
                    }, 1000)
                }
            },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            }
        },
        methods: {
            ...mapActions({
                initOperatorList: 'operator/initOperatorList',
                actGetLabel: 'select/actGetLabel',
                actGetCustomerRoles: 'select/actGetCustomerRoles',
                actGetThreadCount: 'getThreadCount',
                actGetCommentCount: 'getCommentCount'
            }),
            closeDialog: function(val) {
                this.dialogNote = val
            },
            openNote: function (message) {
                this.selectedMessage = message
                this.dialogNote = true
                // this.$bvModal.show("update-note-modal");
            },
            listSearchMessageMapper(messageList) {
                const mapped = messageList.map((val) => {
                    val.time = toFriendlyDateTime(val.createdOn);

                    return val;
                });

                return mapped
            },
            filterReturn: function (event) {
                this.filterOperator = this.operatorList.filter(e => e.name.toLowerCase().includes(event.target.value.toLowerCase()))
            },
            selectContact: function (item) {
                this.$router.push({
                    name: 'unassigned',
                    params: {
                        id: encodeURIComponent(item.id)
                    },
                    query: {
                        ...this.pageQuery,
                    }
                });
            },
            handleImageError: function (event, type) {
                if (type == 'contact') event.target.src = IMAGE_ACC;
                if (type == 'message') event.target.src = IMAGE_MESSAGE;
            },
            searchContactFlag: async function () {
                if (this.selectedSearchBy == 'contact') {
                    this.showSearchMessages = false
                    this.loadContact();
                } else {
                    this.showSearchMessages = true
                    this.searchGlobalMessage();
                }
            },
            removeSelected: async function (item, type, isFilter) {
                if (isFilter) {
                    if (type == 'role') {
                        const index = this.customFilterUiFlag.custroles.indexOf(item.id)
                        if (index >= 0) this.customFilterUiFlag.custroles.splice(index, 1)
                    } else {
                        const index = this.customFilterUiFlag.labels.indexOf(item)
                        if (index >= 0) this.customFilterUiFlag.labels.splice(index, 1)
                    }
                } else {
                    if (type == 'role') {
                        const index = this.selectedRoles.indexOf(item)
                        if (index >= 0) {
                            this.selectedRoles.splice(index, 1)
                            this.customFilterUiFlag.custroles = [...this.selectedRoles]
                            this.contactUiFlag.custroles = this.selectedRoles.join(",")
                            localStorage.setItem('custroles', this.selectedRoles.join(","))
                        }
                    } else if(type == 'stars') {
                        this.selectedStars = false
                        this.customFilterUiFlag.stars = false
                        this.contactUiFlag.stars = this.stars
                    } else {
                        const index = this.selectedLabels.indexOf(item)
                        this.selectedLabels.splice(index, 1)
                        this.customFilterUiFlag.labels = [...this.selectedLabels]
                        this.contactUiFlag.labels = this.selectedLabels.join(",")
                    }
                    await this.loadContact()
                }
            },
            resetCustomFilter: function () {
                // this.$bvModal.hide('modal-center')
                this.customFilterUiFlag.sortDir = this.contactUiFlag.sortDir
                this.customFilterUiFlag.sortOrder = this.contactUiFlag.sortOrder
                this.customFilterUiFlag.selectedSearchBy = this.selectedSearchBy
                this.customFilterUiFlag.labels = [...this.selectedLabels]
                this.customFilterUiFlag.custroles = [...this.selectedRoles]
                this.customFilterUiFlag.sortMessage = this.contactUiFlag.sortBy
                this.customFilterUiFlag.searchTerm = this.contactUiFlag.searchTerm
                this.customFilterUiFlag.stars = this.contactUiFlag.stars
                this.showFilter = false
            },
            customFilter: async function () {
                this.showFilter = false
                // this.$bvModal.hide('modal-center')
                let contactFlag = this.contactUiFlag
                let filter = this.customFilterUiFlag
                this.selectedSearchBy = filter.selectedSearchBy
                this.isTyping = true
                // contactFlag.searchTerm = filter.searchTerm
                contactFlag.sortBy = filter.sortMessage
                if (filter.selectedSearchBy == 'contact') {
                    localStorage.setItem('custroles', filter.custroles.join(","))

                    if (
                        contactFlag.sortDir != filter.sortDir ||
                        contactFlag.sortOrder != filter.sortOrder ||
                        contactFlag.custroles != filter.custroles ||
                        contactFlag.labels != filter.labels ||
                        contactFlag.stars != filter.stars
                    ) {
                        this.contactUiFlag = {
                            ...contactFlag,
                            sortDir: filter.sortDir,
                            sortOrder: filter.sortOrder,
                            custroles: filter.custroles.join(","),
                            labels: filter.labels.join(","),
                            stars: filter.stars,
                        }
                        this.selectedLabels = [...filter.labels]
                        this.selectedRoles = [...filter.custroles]
                        this.selectedStars = filter.stars
                    }
                    await this.loadContact()
                } else {
                    await this.searchGlobalMessage()
                }
            },
            changeInitialName: function (name) {
                let changedName = String()

                name.split(" ").forEach(e => {
                    if (changedName.length < 2) changedName += e[0]
                })

                return changedName
            },
            searchGlobalMessage: async function () {
                const term = this.contactUiFlag.searchTerm

                // if (!this.contactUiFlag.searchTerm) {
                //     this.showSearchMessages = false
                //     this.selectedSearchBy = 'contact'
                // }

                this.contactUiFlag.isLoading = true
                this.msgUiFlag.globalCurrentPage = 1;
                this.msgUiFlag.globalHasMoreData = true;

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/message/search/unassigned', {
                        params: {
                            term,
                            page: this.msgUiFlag.globalCurrentPage,
                            size: 25,
                            sortDir: this.contactUiFlag.sortBy,
                        }
                    });

                    this.searchMessageList.global = this.listSearchMessageMapper(data.content)

                    const self = this

                    contactScrollBar.update()
                    setTimeout(() => {
                        contactScrollBar.scrollTo(0, 0);
                        contactScrollBar.addListener(self.contactScrollBarListener);
                    }, 250)
                } catch (err) {
                    contactScrollBar.addListener(this.contactScrollBarListener);
                    console.log("global-message", err);
                }
                this.contactUiFlag.isLoading = false
            },
            loadMoreGlobalMessage: async function () {
                const term = this.contactUiFlag.searchTerm
                const sort = this.contactUiFlag.sortBy

                this.contactUiFlag.isLoading = true;
                this.msgUiFlag.globalCurrentPage++;

                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/message/search/unassigned', {
                        params: {
                            page: this.msgUiFlag.globalCurrentPage,
                            term: term,
                            size: CONTACT_ROW_PER_PAGE,
                            sortDir: sort,
                        }
                    });

                    var jsonData = resp.data;
                    this.msgUiFlag.globalHasMoreData = jsonData.page.number < jsonData.page.totalPages;

                    var tmp = this.listSearchMessageMapper(jsonData.content)

                    // bottom 
                    var scrollView = Scrollbar.get($('#left-inner-scrollbar').get()[0]);

                    this.searchMessageList.global.splice(this.searchMessageList.global.length, 0, ...tmp);

                    scrollView.update();

                    const self = this

                    setTimeout(() => {
                        scrollView.setPosition(0, scrollView.offset.y);
                        contactScrollBar.addListener(self.contactScrollBarListener);
                    }, 120)

                } catch (error) {
                    this.contactUiFlag.error = this.$helpers.createError(error)
                }
                this.contactUiFlag.isLoading = false;
            },
            jumpToElementGlobal: async function (id) {
                const contactId = this.contactUiFlag.selectedContactId
                this.loadingJump = true;
                this.msgUiFlag.hasMoreDataNext = true

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/message/contact/' + contactId + '/' + id + '?size=5');

                    this.messageList = this.$helpers.listMessageMapper(data.content, this.messageList[0])

                    setTimeout(() => {
                        messageScrollBar.scrollIntoView(document.getElementById(`chatitem-${id}`));
                    }, 100);

                } catch (error) {
                    console.log(error);
                }

                this.loadingJump = false;
            },
            jumpToElement: async function (id) {
                const contactId = this.contactUiFlag.selectedContactId
                this.loadingJump = true;
                this.msgUiFlag.hasMoreDataNext = true

                try {
                    var isMessage = this.messageList.find(e => e._id == id || e.externalId == id)

                    if (!isMessage) {
                        const { data } = await axios.get(process.env.VUE_APP_API_URL + '/message/contact/' + contactId + '/' + id + '?size=5');

                        this.messageList = this.$helpers.listMessageMapper(data.content, this.messageList[0])
                        await this.jumpToElement(id);
                    }

                    if (isMessage) {
                        setTimeout(() => {
                            messageScrollBar.scrollIntoView(document.getElementById(`chatitem-${id}`));
                        }, 100);
                    }

                } catch (error) {
                    console.log(error);
                }

                this.loadingJump = false;
            },
            sortContact: async function (order, dir) {
                this.contactUiFlag.sortOrder = order
                this.contactUiFlag.sortDir = dir
                this.refreshContact()
            },
            loadMoreMessages: async function (isPrev) {
                let params = {
                    size: MESSAGE_ROW_PER_PAGE,
                    direction: isPrev ? 'before' : 'after',
                    timeStamp: isPrev ? this.messageList[0].createdOn : this.messageList[this.messageList
                        .length - 1].createdOn
                }

                var contactId = this.contactUiFlag.selectedContactId;
                this.msgUiFlag.error = {}
                if (this.$helpers.isBlank(contactId)) return;

                this.msgUiFlag.currentPage++;
                // var page = this.msgUiFlag.currentPage;

                if (!isPrev) this.loadingJump = true;

                this.msgUiFlag.isLoading = true;
                try {
                    const resp = await axios.get(process.env.VUE_APP_API_URL + '/message/contact-ts-anchor/' +
                        contactId, {
                            params
                        });

                    var jsonData = resp.data;

                    if (isPrev) {
                        this.msgUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    } else {
                        this.msgUiFlag.hasMoreDataNext = jsonData.page.number < jsonData.page.totalPages;
                    }

                    let tmp = this.$helpers.listMessageMapper(jsonData.content, this.messageList[0])

                    // before 
                    var scrollView = Scrollbar.get($('.wrapp-chat-content').get()[0]);
                    var currentHeight = scrollView.getSize().content.height;

                    if (isPrev) {
                        this.messageList.splice(0, 0, ...tmp);
                    } else {
                        this.messageList = [...this.messageList, ...tmp]
                    }

                    // wait for element tobe rendered, then call setPosition
                    scrollView.update();
                    setTimeout(() => {
                        if (isPrev) {
                            scrollView.setPosition(0, scrollView.getSize().content.height -
                                currentHeight, {
                                    withoutCallbacks: true
                                });
                        } else {
                            scrollView.setPosition(0, scrollView.offset.y);
                        }
                        messageScrollBar.addListener(this.messageScrollBarListener);
                    }, 120)
                } catch (error) {
                    messageScrollBar.addListener(this.messageScrollBarListener)
                    this.msgUiFlag.error = this.$helpers.createError(error)
                }
                this.msgUiFlag.isLoading = false;
                this.loadingJump = false;
            },
            loadMessage: async function (contactId) {
                this.msgUiFlag.isLoading = true;
                this.msgUiFlag.error = {}
                var self = this;
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/message/contact/' + contactId, {
                        params: {
                            size: MESSAGE_ROW_PER_PAGE
                        }
                    });

                    this.msgUiFlag.isLoading = false;
                    var jsonData = resp.data;

                    this.showButtonAdd = true;
                    this.msgUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;

                    this.messageList = this.$helpers.listMessageMapper(jsonData.content, null)

                    messageScrollBar.update();

                    setTimeout(() => {
                        messageScrollBar.scrollTo(0, messageScrollBar.getSize().content.height, 0);
                        messageScrollBar.addListener(self.messageScrollBarListener);
                        if (messageListnerList.indexOf(contactId) < 0) {
                            firebase.database().ref(self.sessionInfo.tenantId + '_' + process.env
                                .VUE_APP_ENV +
                                '/messages/' +
                                contactId).on("child_changed", self.updateMessageFromFb);
                            firebase.database().ref(self.sessionInfo.tenantId + '_' + process.env
                                .VUE_APP_ENV +
                                '/messages/' +
                                contactId).orderByChild("createdOn").limitToLast(1).on(
                                "child_added", self.updateMessageFromFb);
                            messageListnerList.push(contactId)
                        }
                    }, 250)
                } catch (error) {
                    this.msgUiFlag.isLoading = false;
                    this.msgUiFlag.error = this.$helpers.createError(error)
                    messageScrollBar.addListener(this.messageScrollBarListener);
                }
            },
            loadContact: async function () {
                this.contactList = [];
                this.contactUiFlag.error = {};

                this.contactUiFlag.currentPage = 1;
                this.contactUiFlag.hasMoreData = true;

                this.contactUiFlag.isLoading = true;
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/contact/unassigned', {
                        params: {
                            page: this.contactUiFlag.currentPage,
                            sort: this.contactUiFlag.sortOrder,
                            term: this.contactUiFlag.searchTerm,
                            size: CONTACT_ROW_PER_PAGE,
                            labels: this.contactUiFlag.labels,
                            sortDir: this.contactUiFlag.sortDir,
                            custroles: this.contactUiFlag.custroles,
                            stars: this.contactUiFlag.stars,
                        }
                    });

                    this.contactUiFlag.isLoading = false;
                    var jsonData = resp.data;
                    this.contactUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    this.contactList = jsonData.content.map((val) => {
                        val.time = toFriendlyDateTime(val.lastActive);
                        return val;
                    });

                    var self = this

                    contactScrollBar.update()
                    setTimeout(() => {
                        contactScrollBar.scrollTo(0, 0);
                        contactScrollBar.addListener(self.contactScrollBarListener);
                    }, 250)
                } catch (error) {
                    this.contactUiFlag.isLoading = false;
                    contactScrollBar.addListener(this.contactScrollBarListener);
                    this.contactUiFlag.error = this.$helpers.createError(error)
                }
            },
            loadMoreContacts: async function () {
                this.contactUiFlag.isLoading = true;
                this.contactUiFlag.error = {};
                this.contactUiFlag.currentPage++;
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/contact/unassigned', {
                        params: {
                            page: this.contactUiFlag.currentPage,
                            sort: this.contactUiFlag.sortOrder,
                            term: this.contactUiFlag.searchTerm,
                            size: CONTACT_ROW_PER_PAGE,
                            labels: this.contactUiFlag.labels,
                            sortDir: this.contactUiFlag.sortDir,
                            custroles: this.contactUiFlag.custroles,
                            stars: this.contactUiFlag.stars,
                        }
                    });
                    this.contactUiFlag.isLoading = false;
                    var jsonData = resp.data;
                    this.contactUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    var tmp = jsonData.content.map((val) => {
                        val.time = toFriendlyDateTime(val.lastActive);
                        return val;
                    });

                    // bottom 
                    var scrollView = Scrollbar.get($('#left-inner-scrollbar').get()[0]);

                    this.contactList.splice(this.contactList.length, 0, ...tmp);

                    scrollView.update();

                    const self = this

                    setTimeout(() => {
                        scrollView.setPosition(0, scrollView.offset.y);
                        contactScrollBar.addListener(self.contactScrollBarListener);
                    }, 120)
                } catch (error) {
                    this.contactUiFlag.isLoading = false;
                    this.contactUiFlag.error = this.$helpers.createError(error)
                }
            },
            cancelSearchPersonalMessage: function() {
                this.showSearchMessages = false
                this.msgUiFlag.searchTerm = String()
                $(".open-panel").click();
            },
            searchPersonalMessage: async function () {
                const contactId = this.contactUiFlag.selectedContactId
                const term = this.msgUiFlag.searchTerm

                this.showSearchMessages = false
                this.showConversation = true
                this.resetCustomFilter()

                this.msgUiFlag.isLoading = true

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/message/search/' + contactId + '?term=' + term);

                    this.searchMessageList.personal = this.listSearchMessageMapper(data.content)

                } catch (err) {
                    console.log(err);
                }
                this.msgUiFlag.isLoading = false
            },
            /*loadOperator: async function () {
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/user/', {
                        params: {
                            page: 1,
                            size: 250
                        }
                    })
                    this.operatorList = resp.data.content;
                } catch (error) {
                    this.alertMessage = this.$helpers.createError(error)
                }
            },*/
            updateContactListener: async function (snapshot) {
                var self = this

                if (this.contactUiFlag.selectedContactId == snapshot.key) {
                    const data = snapshot.val()

                    this.contactUiFlag = {
                        ...this.contactUiFlag,
                        selectedStars: data.stars
                    }
                }

                const contactUpdate = await updateContactList(
                    MODULES.UNASSIGNED, 
                    this.contactList, 
                    snapshot.val(),
                    snapshot.key, 
                    this.contactUiFlag.sortOrder, 
                    this.contactUiFlag.searchTerm, 
                    !this.contactUiFlag.hasMoreData,
                    '', 
                    '', 
                    this.selectedLabels, 
                    this.selectedRoles, 
                    '')

                if (contactUpdate == 'removed' && self.contactUiFlag.selectedContactId == snapshot.key) {
                    self.messageList = []
                    self.contactUiFlag.selectedContactId = ''
                    self.contactUiFlag.selectedContactName = ''
                }

            },
            conversationTransferedCallback: async function () {
                this.messageList = []
                this.showButtonAdd = false

                this.contactUiFlag = {
                    isLoading: false,
                    currentPage: 1,
                    searchTerm: '',
                    sortOrder: 'lastActive',
                    sortDir: 'desc',
                    selectedContactId: '',
                    selectedContactName: '',
                    selectedContactRole: '',
                    selectedIsUnknonwnContact: null,
                    hasMoreData: true,
                    error: {},
                    labels: [],
                    accountLabels: [],
                    custroles: [],
                    sortBy: '',
                    selectedProfilePicUrl: '',
                }

                this.msgUiFlag = {
                    isLoading: false,
                    currentPage: 1,
                    searchTerm: String(),
                    hasMoreData: true,
                    hasMoreDataNext: true,
                    error: {}
                }

                this.$router.push({
                    name: 'unassigned'
                });

                // await this.loadContact()

            },
            updateNotif: function () {
                /*
                var putData = {

                };
                fetch(process.env.VUE_APP_API_URL + '/notif/assigned', {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json; charset=utf-8"
                    },
                    credentials: 'include',
                    body: JSON.stringify(putData)
                }).then(function (response) {
                    return response.json();
                }).then(function (jsonData) {
                    if (responseCode == 200) {
                        console.log('success update Notif');

                    } else {
                        console.log('error');
                    }
                }).catch(error => console.error('Error:', error));*/
            },
            updateMessageFromFb: function (snapshot) {
                var contactId = snapshot.ref.parent.key;
                var messageId = snapshot.key;
                var messageContent = snapshot.val();

                const convertDate = messageContent.createdOn ? new Date(messageContent.createdOn) : new Date();
                messageContent.createdOn = convertDate.toISOString()
                messageContent.text = messageContent.text ? this.$helpers.formatTabMessages(messageContent.text) : ""

                if(messageContent.url && process.env.VUE_APP_IMAGE_ORIGINAL_URL && process.env.VUE_APP_IMAGE_CDN_URL) {
                    messageContent.cdnUrl = messageContent.url.replace(process.env.VUE_APP_IMAGE_ORIGINAL_URL, process.env.VUE_APP_IMAGE_CDN_URL)
                }

                messageContent.showReaction = false

                if (messageContent.noteAction) {
                    messageContent.noteAction.time = toFriendlyDateTime(messageContent.noteAction.createdOn)
                }

                if (messageContent.internalNotes) {
                    messageContent.internalNotes.forEach(note => {
                        note.time = toFriendlyDateTime(note.createdOn)
                        note.createdOn = new Date(note.createdOn).toISOString(note.createdOn)
                        note.kind = note.kind ? note.kind : []
                    })
                    messageContent.internalNotes = messageContent.internalNotes ? this.$helpers.formatInternalNotes(messageContent.internalNotes) : ""
                }

                if (messageContent.isResponseTime) {
                    if(messageContent.responseTime < 60) {
                        messageContent.convertedResponseTime = "0:" + messageContent.responseTime
                    } else {
                        const minute = Math.floor(messageContent.responseTime / 60)
                        const seconds = messageContent.responseTime % 60
                        messageContent.convertedResponseTime = minute + ":" + seconds
                    }
                }

                let showOpHeader = false

                if(this.messageList.length > 0) {
                    const selectedMsg = this.messageList[this.messageList.length - 1]

                    if(selectedMsg.from == 'cust' || selectedMsg.operatorId != messageContent.userId) {
                        showOpHeader = true
                    }
                }

                var msgData = {
                    ...messageContent,
                    _id: messageId,
                    contactId: contactId,
                    time: toFriendlyDateTime(messageContent.createdOn),
                    showOpHeader: showOpHeader
                }

                // if (messageContent.url) {
                //     msgData.url = messageContent.url;
                //     msgData.thumb = messageContent.thumb;
                // }

                // if (msgData.from == 'op' || msgData.from == 'op-wa') {
                //     msgData.sentTo = messageContent.sentTo;
                //     msgData.status = messageContent.status;
                //     msgData.operatorId = messageContent.operatorId;
                //     msgData.operatorName = messageContent.operatorName;
                // } else {
                //     msgData.fromChannelId = messageContent.fromChannelId;
                //     msgData.fromName = messageContent.fromName;
                // }

                if (contactId == this.contactUiFlag.selectedContactId) {
                    if (this.messageList.length == 0) {
                        this.messageList.push(msgData);
                    } else if (!this.messageList[0].createdOn || moment(this.messageList[0].createdOn)
                        .isSameOrBefore(messageContent.createdOn)) {
                        var i = 0;
                        var found = false;
                        do {
                            if (this.messageList[i]._id == messageId) {
                                this.messageList.splice(i, 1, msgData)
                                found = true;
                            } else if (moment(this.messageList[i].createdOn).isSameOrAfter(messageContent
                                    .createdOn)) {
                                this.messageList.splice(i, 0, msgData)
                                found = true;
                            }
                            i++;
                        } while (i < this.messageList.length && !found);

                        if (!found) {
                            this.messageList.push(msgData);
                        }

                        messageScrollBar.update();
                        const index = this.messageList.findIndex(e => e._id == msgData._id)

                        if (this.messageList.length == index + 1) {
                            setTimeout(() => {
                                messageScrollBar.scrollTo(0, messageScrollBar.getSize().content.height, 0);
                                messageScrollBar.addListener(this.messageScrollBarListener);
                            })
                        }
                    }
                } else {
                    var self = this;
                    firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV +
                        '/messages/' +
                        contactId).off(
                        "child_changed",
                        self.updateMessageFromFb);
                    firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV +
                            '/messages/' +
                            contactId)
                        .orderByChild(
                            "createdOn").limitToLast(1).off("child_added", self.updateMessageFromFb);
                    messageListnerList.splice(messageListnerList.indexOf(contactId), 1);
                }
            },
            messageScrollBarListener: function (status) {
                if (this.msgUiFlag.hasMoreData && status.offset.y == 0 && this.contactUiFlag.selectedContactName) {
                    messageScrollBar.removeListener(this.messageScrollBarListener);
                    this.loadMoreMessages(true);
                }
                if (this.msgUiFlag.hasMoreDataNext && status.limit.y == status.offset.y && this.contactUiFlag
                    .selectedContactName) {
                    messageScrollBar.removeListener(this.messageScrollBarListener);
                    this.loadMoreMessages(false);
                }
            },
            contactScrollBarListener: function (status) {
                if (this.contactUiFlag.hasMoreData && status.limit.y == status.offset.y) {
                    const self = this
                    setTimeout(async () => await self.loadMoreContacts(), 300);
                } else {
                    if (this.msgUiFlag.globalHasMoreData && status.limit.y == status.offset.y) {
                        const self = this
                        setTimeout(async () => await self.loadMoreGlobalMessage(), 300);
                    }
                }
            },
            getClass: function (url, channel) {
                if (url) {
                    if (channel == 'cust') {
                        return 'box bg-light-inverse wrapp-chat-img';
                    } else {
                        return 'box bg-light-info wrapp-chat-img';
                    }
                } else {
                    if (channel == 'op') {
                        return 'box bg-light-info';
                    } else {
                        return 'box bg-light-inverse ';
                    }
                }
            },
            viewImage: function (messageData, event) {
                this.$bvModal.show("modal-image-preview");
                event.preventDefault();
                this.selectedImage.imageUrl = messageData.url;
                this.selectedImage.caption = messageData.text;
            },
            showAddContactModal: function (event) {
                this.$bvModal.hide('add-to-contact-modal')
                event.preventDefault()
            },
            saveContactCallback: function () {

            },
            selectChat: async function (contactId) {
                messageScrollBar.removeListener(this.messageScrollBarListener);
                this.msgUiFlag.error = {}
                this.messageList = [];
                this.contactUiFlag.selectedContactName = '';

                this.contactUiFlag.selectedContactId = contactId;
                this.msgUiFlag.currentPage = 1;
                this.msgUiFlag.hasMoreData = true;

                const contact = this.contactList.find(e => e.id == contactId)

                if (contactId != null) {
                    var found = false;
                    this.contactList = this.contactList.map((item) => {
                        if (item.id == contactId) {
                            this.contactUiFlag.selectedContactName = item.name;
                            this.contactUiFlag.selectedContactRole = item.customerRole;
                            this.contactUiFlag.selectedSessionId = item.sessionId;
                            this.contactUiFlag.selectedIsUnknonwnContact = item.unknownContact;
                            this.contactUiFlag.accountLabels = contact ? contact.labels : [];
                            this.contactUiFlag.selectedProfilePicUrl = contact.profilePicUrl
                            if(contact) {
                                this.contactUiFlag.selectedStars = contact.stars ? contact.stars : false
                                this.contactUiFlag.isAffiliator = contact.isAffiliator ? contact.isAffiliator : false
                                this.contactUiFlag.currentAffiliator = contact.currentAffiliator ? contact.currentAffiliator : null
                            }
                            this.loadMessage(contactId);
                            item.selected = true;
                            found = true;
                        } else {
                            item.selected = false;
                        }
                        return item;
                    });

                    if (!found) {
                        try {
                            var resp = await axios.get(process.env.VUE_APP_API_URL + '/contact/idcommunicator/' +
                                contactId);
                            var jsonData = resp.data;
                            // if (jsonData.currentOperatorId != null) {
                                this.contactUiFlag.selectedContactName = jsonData.name;
                                this.contactUiFlag.selectedIsUnknonwnContact = jsonData
                                    .unknownContact;
                            // }
                            this.loadMessage(contactId);
                        } catch (error) {
                            this.msgUiFlag.error = this.$helpers.createError(error)
                        }
                    }
                }
            },
            refreshContact: function () {
                this.msgUiFlag.currentPage = 1;
                this.loadContact();
            },
            takeConversation: function (event) {
                this.$bvModal.show("take-conversation-dlg");
                event.preventDefault();
            },
            transferConversation: function (event, toUserId, toUserName) {
                this.$bvModal.show("transfer-conversation-dlg");
                event.preventDefault();

                this.assignToData.operatorId = toUserId;
                this.assignToData.operatorName = toUserName;
                this.assignToData.sessionId = this.contactUiFlag.selectedSessionId;
            },
            loadInitialData: async function () {
                var self = this
                removeContactListener = firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env
                    .VUE_APP_ENV + '/contact/').on(
                    "child_removed",
                    function (oldChildSnapshot) {
                        var foundIndex = self.contactList.findIndex(contact => {
                            return contact.id === oldChildSnapshot.key;
                        })
                        if (foundIndex >= 0) {
                            if (self.contactList[foundIndex].selected) {
                                self.messageList = []
                                self.contactUiFlag.selectedContactId = ''
                                self.contactUiFlag.selectedContactName = ''
                            }
                            self.contactList.splice(foundIndex, 1);
                        }
                    });
                firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/contact/')
                    .orderByChild(
                        "lastActive")
                    .limitToLast(1).on("child_added", this.updateContactListener);
                firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/contact/').on(
                    "child_changed",
                    this.updateContactListener);

                //this.loadOperator();
                var opListResp = this.initOperatorList()
                var contactResp = this.loadContact()

                await opListResp
                await contactResp

                if (this.$route.params.id != null) {
                    this.selectChat(decodeURIComponent(this.$route.params.id));
                }
            },
        },
        created: function () {
            this.$emit('update:layout', MainLayout);
        },
        mounted: async function () {
            if (localStorage.getItem('custroles')) {
                let custRoles = localStorage.getItem('custroles')
                this.selectedRoles = custRoles.split(",");
                this.customFilterUiFlag.custroles = custRoles.split(",");
                this.pageQuery = {
                    ...this.pageQuery,
                    custroles: custRoles
                }
                if (this.$route.query.custroles != custRoles) this.$router.push({
                    query: {
                        ...this.pageQuery
                    }
                })
            }

            Scrollbar.initAll();
            messageScrollBar = Scrollbar.get($('.wrapp-chat-content').get()[0]);
            contactScrollBar = Scrollbar.get($('#left-inner-scrollbar').get()[0]);

            if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
                this.loadInitialData();
                this.actGetThreadCount();
                this.actGetCommentCount();
            }
            try {
                mainuisetup();
                commonpagesetup("message", this.$route.params.id);
            } catch (err) {
                console.log(err);
            }

            await this.actGetLabel()
            await this.actGetCustomerRoles()
            this.filterOperator = this.operatorList
        },
        destroyed: function () {
            var self = this;
            firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/contact/').off(
                "child_removed",
                removeContactListener);
            firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/contact/').off(
                "child_changed",
                this.updateContactListener);
            firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/contact/')
                .orderByChild(
                    "lastActive")
                .limitToLast(1).off("child_added", this.updateContactListener);

            for (var i = 0; i < messageListnerList.length; i++) {
                firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/messages/' +
                    messageListnerList[
                        i]).off(
                    "child_changed", self.updateMessageFromFb);
                firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/messages/' +
                        messageListnerList[
                            i])
                    .orderByChild("createdOn").limitToLast(1).off("child_added", self.updateMessageFromFb);
            }
            messageListnerList = [];
        },
        components: {
            TakeConversation,
            TransferConversation,
            AddToContact,
            ImageViewer,
            ErrorMessage,
            Alert,
            UpdateNote,
            MessageList,
            ContactList,
            MessageHeader
        }
    }
</script>