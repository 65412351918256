<template>
    <b-dropdown no-caret title="Template Pesan">
        <template #button-content>
            <i class="fa fa-comment"></i>
        </template>
        <div class="wrapp-chat-template">
            <span class="" style="padding: 8px 1rem; display:block">
                <input class="form-control" type="text" placeholder="Search" @input="filterReturn($event)" aria-label="Search" />
            </span>
            <div v-for="(item, index) in quickChatOrderList" :key="index">
                <span class="title-chat-template">{{ item.title }}</span>
                <b-dropdown-item v-for="(e, i) in item.data" :key="i">
                    <div class="row">
                        <div class="col-md-8 col-8">
                            <div class="wrapp-auto-template text-secondary">
                                {{ e.title }}
                            </div>
                        </div>
                        <div class="col-md-3 col-3 text-right pull-right">
                            <button class="btn btn-sm btn-secondary mr-2" :disabled="e.title == 'Link Login'"
                                @click="sendQuickChat(e, false, $event)">
                                Salin
                            </button>
                            <button class="btn btn-sm btn-primary" 
                                @click="sendQuickChat(e, true, $event)">
                                Kirim
                            </button>
                        </div>
                    </div>
                </b-dropdown-item>
            </div>

        </div>
    </b-dropdown>
</template>
<script>
import axios from 'axios'

const template = require('lodash.template');

import QUICK from '../utils/templates/quickchat'
import QUICKGP from '../utils/templates/quickchatgp'
import { mapState } from 'vuex';

export default {
    props: ['parentId', 'contactId'],

    components: {},

    computed: {
        ...mapState({
            sessionInfo: state => state.session.sessionInfo,
        }),
    },

    data: function() {
        return {
            quickChatOrderList: Array(),
            
            isLoading: false,

            rules: {
                required: [(v) => !!v || ""],
                requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                requiredArray: [(v) => v.length > 0 || ""],
            },
        }
    },

    methods: {
        filterReturn: function (event) {
            let temp = []
            const templateList = this.sessionInfo.tenantId == 'gitapelangi2' ? QUICKGP : QUICK
            const filteredData = templateList.filter(e => e.title.toLowerCase().includes(event.target.value
                .toLowerCase()))
            filteredData.forEach(item => {
                const check = temp.find(e => e.title == item.group)

                if (!check) {
                    temp.push({
                        title: item.group,
                        data: [item]
                    })
                } else {
                    const index = temp.map(e => e.title).indexOf(item.group)
                    temp[index].data = [...temp[index].data, item]
                }
            })

            this.quickChatOrderList = temp
        },
        sendQuickChat: async function (templates, send, event) {
            event.preventDefault();
            const operator = this.sessionInfo
            
            let cust = Object()

            try {
                this.$emit('set-loading-message', true)

                if (templates.title == 'format order lengkap' || templates.title == 'format alamat lengkap') {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/sales-order', {
                        params: {
                            dateType: 'creationTime',
                            customerId: this.parentId ? this.parentId : this.contactId,
                            page: 0,
                            size: 5,
                            sortField: 'modificationTime',
                            sortOrder: 'DESCENDING'
                        }
                    })

                    let isNewOrder = false
                    let isLastOrder = false

                    for (let i = 0; i < data.content.length; i++) {
                        if (isNewOrder && isLastOrder) break

                        if (data.content[i].status == 'BOOKED' && !isNewOrder) {
                            const res = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/sales-order/' + data.content[i].formalId)
                            res.data.amount = this.formatCurrency(res.data.amount)
                            res.data.lines = res.data.lines.map(e => {
                                e.finalPrice = this.formatCurrency(e.finalPrice)
                                return e
                            })
                            cust.newOrder = res.data
                            isNewOrder = true
                        }

                        if (data.content[i].status == 'COMPLETE' && !isLastOrder) {
                            const res2 = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/sales-order/' + data.content[i].formalId)
                            const address = res2.data.shippingAddress
                            cust.lastOrder = res2.data
                            cust.mergedAddress = `${address.street}, ${address.district}, ${address.city}, ${address.province}. ${address.postalCode}`
                            isLastOrder = true
                        }
                    }
                }

                if (templates.title == 'Link Login Website' || templates.title == 'Link Login Mobile App') {
                    this.$emit('send-login-link-commerce', templates.title == 'Link Login Mobile App' ? true : false)
                } else {
                    const str = template(templates.template)({
                        operator,
                        cust
                    })
    
                    if (send) {
                        this.$emit('send-info-message-template', str)
                    } else {
                        this.$emit('copy-info-message-template', str)
                    }
                }

                this.$emit('set-loading-message', false)
            } catch (error) {
                this.$emit('set-loading-message', false)
                console.log("send-quick-chat", error);
            }
        },
        getQuickChat: function () {
            let temp = []
            const templateList = this.sessionInfo.tenantId == 'gitapelangi2' ? QUICKGP : QUICK
            templateList.forEach(item => {
                const check = temp.find(e => e.title == item.group)

                if (!check) {
                    temp.push({
                        title: item.group,
                        data: [item]
                    })
                } else {
                    const index = temp.map(e => e.title).indexOf(item.group)
                    temp[index].data = [...temp[index].data, item]
                }
            })

            this.quickChatOrderList = temp
        },
        formatCurrency(price) {
            let val = (price / 1).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
    },

    watch: {},

    mounted: function() {
        this.getQuickChat()
    },
}

</script>
