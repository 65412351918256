<template>
    <div v-if="show">
        <div>
            <div class="row mt-4">
                <div class="col-12 text-center">
                    <h3>Performansi Operator</h3>
                </div>
                <div class="col-3"></div>
                <div class="col-6 text-center">
                    <DashboardPie 
                        :chartId="time.id"
                        :chartData="time.data"
                        :datasetIdKey="time.datasetIdKey"
                        :width="chart.width"
                        :height="chart.height"
                        :cssClasses="chart.cssClasses"
                        :styles="chart.styles"
                        :plugins="chart.plugins"
                    />
                </div>
                <div class="col-3"></div>
            </div>
            <v-row v-for="item in desserts" :key="item._id" class="RepsonseTime">
               
                <div class="col-md-12 col-sm-12" v-if="item.responseTime">
                     <div class="card">
                        <div class="card-header">
                            <!-- Featured -->
                            <h6><i class="mdi mdi-account"></i> {{ item._id }}</h6>
                        </div>
                        <div class="card-body">
                            <v-row>
                                  <div class="col-md-6 col-sm-6 text-center pb-0">
                                    <b class="">Response Time</b>
                                    <GaugeChart 
                                        :refid="item._id"
                                        :needleValue="item.responseTime.avgValue"
                                        :centralLabel="item.responseTime.avgValueLabel" />
                                  </div>
                                  <div class="col-md-6 col-sm-6 text-center pb-0">
                                        <div class="" v-if="item.responseTimeReal">
                                            <b>Response Time Real</b>
                                            <GaugeChart 
                                                :refid="item._id + 'real'"
                                                :needleValue="item.responseTimeReal.avgValue"
                                                :centralLabel="item.responseTimeReal.avgValueLabel" />
                                        </div>
                                  </div>
                            </v-row>
                        </div>
                       
                    </div>
                </div>
            </v-row>
            <!-- <div class="row">
                <div class="col">
                    <DashboardBar
                        :chartId="chartData.id"
                        :chartData="chartData.data"
                        :datasetIdKey="chartData.datasetIdKey"
                        :width="chart.width"
                        :height="chart.height"
                        :cssClasses="chart.cssClasses"
                        :styles="chart.styles"
                        :plugins="chart.plugins"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <DashboardBar
                        :chartId="chartDataReal.id"
                        :chartData="chartDataReal.data"
                        :datasetIdKey="chartDataReal.datasetIdKey"
                        :width="chart.width"
                        :height="chart.height"
                        :cssClasses="chart.cssClasses"
                        :styles="chart.styles"
                        :plugins="chart.plugins"
                    />
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import { mapState } from 'vuex'

    // import DashboardBar from "./DashboardBar.vue"
    import DashboardPie from "./DashboardPie.vue"
    import GaugeChart from './GaugeChart.vue'

    var moment = require('moment');

    export default {
        props: ['show', 'selectedType', 'selectedStartDate', 'selectedFilter', 'startDate', 'selectedMonth'],

        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            })
        },

        data: function () {
            return {
                desserts: Array(),

                chart: {
                    width: 400,
                    height: 400,
                    cssClasses: String(),
                    styles: Object(),
                    plugins: Array(),
                },

                // chartData: {
                //     id: 'response-time-day',
                //     datasetIdKey: 'response-time-day',
                //     data: {
                //         labels: [],
                //         datasets: []
                //     },
                // },

                // chartDataReal: {
                //     id: 'responsetime-real-day',
                //     datasetIdKey: 'responsetime-real-day',
                //     data: {
                //         labels: [],
                //         datasets: []
                //     },
                // },

                time: {
                    id: 'time',
                    datasetIdKey: 'time',
                    data: {
                        labels: [],
                        datasets: [{
                            backgroundColor: ['#008450', '#008450', '#EFB700', '#B81D13', '#B81D13'],
                            data: []
                        },]
                    },
                },
            }
        },


        watch: {

        },

        mounted() {

        },

        methods: {
            loadData: async function() {
                const params = Object()
                params.startDate = this.selectedStartDate ? moment(this.selectedStartDate).format() : ""
                params.endDate = this.selectedStartDate ? moment(this.selectedStartDate).endOf('day').format() : ""

                try {
                    const res = await axios.get(process.env.VUE_APP_API_URL + '/message/report/response-time/by-day/bytime', {
                        params
                    })
                    
                    this.formatPie(res.data)

                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/message/report/response-time/by-day/byavgop', {
                        params
                    })

                    this.formatGauge(data)

                } catch (error) {
                    console.log("error response time", error);
                }
            },

            loadDataByMonth: async function() {
                var selectedMonth = this.selectedMonth.split('-');

                const params = Object()
                params.year = selectedMonth[0]
                params.month = selectedMonth[1]


                try {
                    const res = await axios.get(process.env.VUE_APP_API_URL + '/message/report/response-time/by-month/bytime', {
                        params
                    })
                    
                    this.formatPie(res.data)

                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/message/report/response-time/by-month/byavgop', {
                        params
                    })

                    this.formatGauge(data)

                } catch (error) {
                    console.log("error response time", error);
                }
            },

            formatGauge(data) {
                const desserts = Array()

                data.responseTime.forEach(e => {
                    const avgValue = e.average * 10
                    e.avgValue = avgValue > 99 ? 99 : avgValue
                    e.avgValueLabel = e.average.toString()

                    desserts.push({ _id: e._id, responseTime: e, responseTimeReal: null })
                })

                data.responseTimeReal.forEach(e => {
                    const avgValue = e.average * 10
                    e.avgValue = avgValue > 99 ? 99 : avgValue
                    e.avgValueLabel = e.average.toString()

                    const index = desserts.findIndex(el => el._id == e._id)

                    if(index == -1) {
                        desserts.push({ _id: e._id, responseTime: null, responseTimeReal: e })
                    } else {
                        desserts[index].responseTimeReal = e
                    }
                })

                this.desserts = desserts
            },

            formatBar(data) {
                const labels = Array()
                const datasets = [{
                    backgroundColor: "#198754",
                    label : "Waktu Response (Menit) ",
                    data: []
                }]

                const labelsReal = Array()
                const datasetsReal = [{
                    backgroundColor: "#f87979",
                    label : "Waktu Response Real (Menit) ",
                    data: []
                }]

                data.responseTime.forEach(e => {
                    labels.push(e._id)
                    datasets[0].data.push(e.average)
                });

                data.responseTimeReal.forEach(e => {
                    labelsReal.push(e._id)
                    datasetsReal[0].data.push(e.average)
                });

                this.chartData.data = {
                    labels,
                    datasets
                }

                this.chartDataReal.data = {
                    labels: labelsReal,
                    datasets: datasetsReal
                }
                
            },

            formatPie(data) {
                const datasets = [0, 0, 0, 0, 0] 

                data.forEach(e => {
                    const index = e._id == '<1' ? 0 : e._id == '1-2' ? 1 : e._id == '3-5' ? 2 : e._id == '5-15' ? 3 : 4
                    datasets[index] = e.totalMessage
                });
                
                this.time.data.labels = ['<1', '1-2', '3-5', '5-15', '>15']
                this.time.data.datasets[0].data = datasets
            },
        },

        components: {
            // DashboardBar,
            DashboardPie,
            GaugeChart
        },

    }

</script>
