<template>
    <div>
        <vue-gauge
            :refid="refid"
            :class="'meticsGauge'"
            :options="{
                needleValue: needleValue,
                centralLabel: centralLabel,
                needleColor: needleColor,
                arcDelimiters: arcDelimiters,
                arcColors: arcColors,
                hasNeedle: hasNeedle,
                rangeLabel: rangeLabel,
            }"
        ></vue-gauge>
    </div>
</template>
<script>
    import VueGauge from "vue-gauge";

    export default {
        computed: {},

        data: function() {
            return {

            }
        },

        props: {
            refid: {
                type: String,
                default: ''
            },
            needleValue: {
                type: Number,
                default: 0
            },
            centralLabel: {
                type: String,
                default: '0'
            },
            needleColor: {
                type: String,
                default: '#410082'
            },
            arcDelimiters: {
                type: Array,
                default: () => [30, 50, 99]
            },
            arcColors: {
                type: Array,
                default: () => ['#008450', '#EFB700', '#B81D13']
            },
            chartWidth: {
                type: Number,
                default: 500
            },
            hasNeedle: {
                type: Boolean,
                default: true
            },
            rangeLabel: {
                type: Array,
                default: () => ['', '']
            },
        },

        methods: {},

        watch: {},

        mounted() {},

        components: {
            VueGauge,
        },

    }
</script>