<template>
    <div class="row">
        <div class="col-12">
            <div class="card card m-b-0">
                <!-- .chat-row -->
                <div class="chat-main-box">
                    <div class="chat-right-aside layer-opacity" style="width:100%">
                        <div class="chat-main-header"></div>
                        <div class="chat-rbox static-content">
                            <div class="wrapp-chat-content" data-scrollbar="true" tabindex="1" style="overflow: hidden; outline: none; height:90vh;">
                                <div class="scroll-content">
                                    <div class="wrapp-dashboard">
                                        Kalender Libur
                                        <v-btn class="text-white pull-right btn btn-primary" @click="dialog = true">
                                            Tambah
                                        </v-btn>
                                        <div class="form-group row m-t-20">
                                            <div class="col-sm-12 col-lg-12">
                                                <div class="form-horizontal form-material">
                                                    <div class="wrapp-input-contact">
                                                        <v-data-table class="elevation-1"
                                                            :headers="headers" 
                                                            :items="calendar"
                                                            :loading="isLoading"
                                                        >
                                                            <template v-slot:item.date="{ item }">
                                                                <div>
                                                                    {{ item.date ? utcToLocal(item.date, "DD-MM-YYYY") : "" }}
                                                                </div>
                                                            </template>
                                                            <template v-slot:item.action="{ item }">
                                                                <div>
                                                                    <v-btn icon color="primary" @click="showEdit(item)">
                                                                        <v-icon title="Ubah">mdi-pencil</v-icon>
                                                                    </v-btn>
                                                                    <v-btn icon color="red" @click="deleteCalendar(item._id)">
                                                                        <v-icon title="Ubah">mdi-delete</v-icon>
                                                                    </v-btn>
                                                                </div>
                                                            </template>
                                                        </v-data-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                    <div class="scrollbar-thumb scrollbar-thumb-x"
                                        style="width: 661px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                    <div class="scrollbar-thumb scrollbar-thumb-y"
                                        style="height: 445.289px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SuccessNotif />

        <v-dialog
            v-model="dialog"
            persistent
            max-width="500px"
        >
            <v-card>
                <v-card-title>
                    <span>{{ id ? "Edit" : "Tambah" }} Kalender Libur</span>
                    <v-spacer />
                </v-card-title>
                <v-divider />
                <v-form v-model="form">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-menu offset-y
                                    ref="date" 
                                    v-model="isOpenDate" 
                                    :close-on-content-click="false" 
                                    :nudge-right="40"
                                    :return-value.sync="selectedDate" 
                                    transition="scale-transition" 
                                    min-width="290px" 
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field readonly dense hide-details
                                            :value="selectedDate ? utcToLocal(selectedDate,'DD-MM-YYYY') : ''"
                                            label="Tanggal Libur" 
                                            :rules="rules.required"
                                            v-on="on" />
                                    </template>
                                    <v-date-picker 
                                        v-model="date" no-title scrollable
                                        @input="$refs.date.save(date)" />
                                </v-menu>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field type="text" v-model="eventName" label="Nama Acara" :rules="rules.required" />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text @click="dialog = false">
                            Kembali
                        </v-btn>
                        <v-btn class="text-white pull-right btn btn-primary"  @click="putCalendar" :loading="isLoadingAdd" :disabled="!form" v-if="id">
                            Simpan
                        </v-btn>
                        <v-btn class="text-white pull-right btn btn-primary" @click="postCalendar" :loading="isLoadingAdd" :disabled="!form" v-else>
                            Simpan
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
                
        </v-dialog>
    </div>
</template>
<script>
    import axios from 'axios'
    import Scrollbar from 'smooth-scrollbar'
    // import ErrorMessage from '../components/ErrorMessage'
    import MainLayout from "../layouts/MainLayout"
    // import SuccessNotif from "../components/SuccessNotif"
    import {
        mapActions,
        mapState
    } from 'vuex'
    // import countryCodes from "../utils/country-codes"
    // import LineChart from "../components/LineChart"

    const $ = require("jquery")
    // const debounce = require('lodash.debounce')
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup')

    // const USER_ROW_PER_PAGE = 25
    // var userScrollBar = null

    var moment = require('moment');
    
    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            })
        },
        data: function () {
            return {
                id: String(),
                selectedDate: String(),
                date: String(),
                eventName: String(),

                calendar: Array(),

                isLoading: false,
                isLoadingAdd: false,
                dialog: false,
                isOpenDate: false,
                form: false,

                headers: [
                    { text: "Tanggal", value: "date", sortable: false },
                    { text: "Nama Acara", value: "eventName", sortable: false },
                    { text: "Aksi", value: "action", align: "center", sortable: false },
                ],

                rules: {
                    required: [(v) => !!v || ""],
                    requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                    requiredArray: [(v) => v.length > 0 || ""],
                },
            }
        },
        watch: {
            dialog: function(val) {
                if(!val) {
                    this.resetForm()
                }
            }
        },

        methods: {
            ...mapActions({
                initOperatorList: 'operator/initOperatorList'
            }),

            getCalendar: async function() {
                try {
                    this.isLoading = true

                    const config = Object()
                    config["params"] = Object()
                    config["params"]["size"] = 100
                    config["params"]["page"] = 1

                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/calendar', config)
                    
                    this.calendar = data.content 

                    this.isLoading = false
                } catch (error) {
                    this.isLoading = false
                    console.log(error);
                }
            },

            postCalendar: async function() {
                try {
                    this.isLoadingAdd = true

                    const body = {
                        date: this.selectedDate,
                        eventName: this.eventName
                    }

                    await axios.post(process.env.VUE_APP_API_URL + '/calendar', body)
                    
                    this.isLoadingAdd = false
                    this.dialog = false
                    this.getCalendar()

                } catch (error) {
                    this.isLoadingAdd = false
                    console.log(error);
                }
            },

            putCalendar: async function() {
                try {
                    this.isLoadingAdd = true

                    const body = {
                        date: this.selectedDate,
                        eventName: this.eventName
                    }

                    await axios.put(process.env.VUE_APP_API_URL + '/calendar/' + this.id, body)
                    
                    this.isLoadingAdd = false
                    this.dialog = false
                    this.getCalendar()

                } catch (error) {
                    this.isLoadingAdd = false
                    console.log(error);
                }
            },

            deleteCalendar: async function(id) {
                try {
                    await axios.delete(process.env.VUE_APP_API_URL + '/calendar/' + id)
                    
                    this.getCalendar()

                } catch (error) {
                    console.log(error);
                }
            },

            showEdit(calendar) {
                this.dialog = true
                this.selectedDate = calendar.date
                this.date = calendar.date
                this.eventName = calendar.eventName
                this.id = calendar._id
            },

            resetForm() {
                this.selectedDate = String()
                this.date = String()
                this.eventName = String()
                this.id = String()
            },

            utcToLocal: function(date, format) {
                var utcLocal = moment(date).local().format(format);
                return utcLocal;
            },

            utcToLocalView: function(date, format) {
                var utcLocal = moment(date).format(format);
                return utcLocal;
            },

            localToUtc: function(date, format) {
                var utcLocal = moment(date).utc().format(format);
                return utcLocal;
            },
        },

        created: function () {
            this.$emit('update:layout', MainLayout);
        },

        mounted: async function () {
            Scrollbar.initAll();
            Scrollbar.get($('#left-inner-scrollbar').get()[0]);

            // var self = this;
            // userScrollBar.addListener((status) => {
            //     if (this.commChannelUIFlag.hasMoreData && status.offset.y == userScrollBar.limit.y) {
            //         setTimeout(() => self.loadMoreUsers(), 500);
            //     }
            // });

            // if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
            //     await this.loadInitialData();
            // }

            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }

            await this.initOperatorList()

            await this.getCalendar()
        },

        components: {

        }
    }
</script>