<template>
    <div class="row">
        <div class="col-12">
            <div class="card card m-b-0">
                <!-- .chat-row -->
                <div class="chat-main-box">
                    <!-- .chat-left-panel -->
                    <div class="chat-left-aside">
                        <div class="open-panel" hidden>
                            <i class="ti-angle-right"></i>
                        </div>
                        <div class="chat-left-inner form-horizontal" style="height: 3px;">
                            <div class="form-material">
                                <div class="input-group searchbox" v-if="!showConversation">
                                    <input style="border:none;" type="text" class="form-control" id="uname"
                                        @keyup.enter="searchContactFlag" placeholder="Cari Kontak.." autocomplete="off"
                                        v-model="contactUiFlag.searchTerm">
                                    <div style="border:none;" class="input-group-addon">
                                        <!-- <a v-b-modal.modal-center>
                                            <v-icon>mdi-sort</v-icon>
                                        </a> -->
                                        <a v-if="!showFilter" @click="showFilter = !showFilter">
                                            <v-icon>mdi-sort</v-icon>
                                        </a>
                                        <a v-if="showFilter" @click="resetCustomFilter">
                                            <v-icon>mdi-arrow-left</v-icon>
                                        </a>
                                    </div>
                                </div>
                                <div v-else>
                                    <a id="cancelSearch" class="btn m-2 text-muted" @click="cancelSearchPersonalMessage()">
                                        <v-icon class="">mdi-close</v-icon> <span class="ml-2 pt-1">Cari Pesan</span>  
                                    </a>
                                </div>
                            </div>
                            <b-modal id="modal-center" centered title="Filter & Urutkan" @hidden="resetCustomFilter" @ok="customFilter">
                                <div>
                                    <span>
                                        <span class="filter">
                                            <input type="text" class="mb-4 form-control" id="uname"
                                                placeholder="Cari.." autocomplete="off"
                                                v-model="customFilterUiFlag.searchTerm">
                                            <h5 class="mt-0">Filter</h5>
                                            <b-button pill size="sm"
                                                :variant="customFilterUiFlag.selectedSearchBy == 'contact' ? 'primary' : 'secondary'"
                                                class="mr-2"
                                                @click="customFilterUiFlag.selectedSearchBy = 'contact'"> <i
                                                    class="mdi mdi-account"></i>
                                                Kontak</b-button>
                                            <b-button pill size="sm"
                                                :variant="customFilterUiFlag.selectedSearchBy == 'messages' ? 'primary' : 'secondary'"
                                                @click="customFilterUiFlag.selectedSearchBy = 'messages'">
                                                <i class="mdi mdi-message"></i>
                                                Pesan</b-button>
                                            <div v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                                <h5 class="mt-4">Pesan</h5>
                                                <b-button pill size="sm"
                                                    :variant="customFilterUiFlag.lastMessagesDirection == '' ? 'primary' : 'secondary'"
                                                    class="mr-2"
                                                    @click="customFilterUiFlag.lastMessagesDirection = ''">
                                                    Semua Pesan
                                                </b-button>
                                                <b-button pill size="sm"
                                                    :variant="customFilterUiFlag.lastMessagesDirection == 'IN' ? 'primary' : 'secondary'"
                                                    class="mr-2"
                                                    @click="customFilterUiFlag.lastMessagesDirection = 'IN'">
                                                    Belum Balas
                                                </b-button>

                                                <b-button pill size="sm"
                                                    :variant="customFilterUiFlag.lastMessagesDirection == 'OUT' ? 'primary' : 'secondary'"
                                                    class="mr-2"
                                                    @click="customFilterUiFlag.lastMessagesDirection = 'OUT'">
                                                    Balas
                                                </b-button>
                                            </div>
                                            
                                            <h5 class="mt-4">Urutkan</h5>
                                            <div v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                                <b-button pill size="sm"
                                                    :variant="customFilterUiFlag.sortOrder == 'lastActive' && customFilterUiFlag.sortDir == 'desc' ? 'primary' : 'secondary'"
                                                    class="mr-2"
                                                    @click="customFilterUiFlag.sortOrder = 'lastActive', customFilterUiFlag.sortDir = 'desc'">
                                                    Paling Baru
                                                </b-button>
                                                <b-button pill size="sm"
                                                    :variant="customFilterUiFlag.sortOrder == 'lastActive' && customFilterUiFlag.sortDir == 'asc' ? 'primary' : 'secondary'"
                                                    class="mr-2"
                                                    @click="customFilterUiFlag.sortOrder = 'lastActive', customFilterUiFlag.sortDir = 'asc'">
                                                    Paling Lama
                                                </b-button>

                                                <b-button pill size="sm"
                                                    :variant="customFilterUiFlag.sortOrder == 'name' ? 'primary' : 'secondary'"
                                                    class="mr-2"
                                                    @click="customFilterUiFlag.sortOrder = 'name', customFilterUiFlag.sortDir = 'asc'">
                                                    Pengirim
                                                </b-button>
                                            </div>
                                            <div v-if="customFilterUiFlag.selectedSearchBy != 'contact'">
                                                <b-button pill size="sm"
                                                    :variant="customFilterUiFlag.sortMessage == 'desc' ? 'primary' : 'secondary'" 
                                                    class="mr-2" 
                                                    @click="customFilterUiFlag.sortMessage = 'desc'">
                                                    Paling Baru
                                                </b-button>
                                                <b-button pill size="sm"
                                                    :variant="customFilterUiFlag.sortMessage == 'asc' ? 'primary' : 'secondary'" 
                                                    class="mr-2" 
                                                    @click="customFilterUiFlag.sortMessage = 'asc'">
                                                    Paling Lama
                                                </b-button>
                                            </div>

                                            <br>
                                            <div v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                                <h5 class="mt-4 mb-0">Peran Pelanggan</h5>
                                                <v-autocomplete class="label-label"
                                                    v-model="customFilterUiFlag.custroles"
                                                    :items="customerRoleList" 
                                                    hide-selected multiple
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Tekan Esc jika sudah memilih"
                                                    :loading="custRoleLoading"
                                                >
                                                    <template v-slot:selection="data">
                                                        <v-chip
                                                            v-bind="data.attrs"
                                                            :input-value="data.selected"
                                                            close
                                                            @click="data.select"
                                                            @click:close="removeSelected(data.item, 'role', true)"
                                                        >
                                                            {{ data.item.name }}
                                                        </v-chip>
                                                    </template>
                                                </v-autocomplete>
                                                <h5 class="mt-2 mb-0">Label</h5>
                                                <v-autocomplete class="label-label" 
                                                    v-model="customFilterUiFlag.labels"
                                                    :items="labels"
                                                    hide-selected multiple persistent-hint chips
                                                     label="Tekan Esc jika sudah memilih"
                                                    
                                                >
                                                    <template v-slot:selection="data">
                                                        <v-chip
                                                            v-bind="data.attrs"
                                                            :input-value="data.selected"
                                                            close
                                                            @click="data.select"
                                                            @click:close="removeSelected(data.item, 'label', true)"
                                                        >
                                                            {{ data.item }}
                                                        </v-chip>
                                                    </template>
                                                </v-autocomplete>
                                            </div>

                                        </span>

                                        <div class="clearfix"></div>
                                    </span>
                                </div>
                            </b-modal>
                            <div class="clearfix"></div>
                            <div v-if="selectedSearchBy == 'contact' && !showFilter && !showConversation" class="wrapp-filter-sort">
                                 <b-badge class="sort-chat" pill  v-if="pageQuery.sortOrder && pageQuery.sortDir">
                                      <v-icon class="icon-sort-filter text-primary" left>mdi-sort</v-icon> {{ pageQuery.sortOrder == 'name' ? 'Pengirim' : pageQuery.sortOrder == 'lastActive' && pageQuery.sortDir == 'desc' ? 'Paling Baru' : 'Paling Lama'}} 
                                 </b-badge>

                                 <b-badge pill class="sort-chat">
                                    <v-icon class="icon-sort-filter text-primary" left>mdi-account</v-icon> {{ selectedSearchBy }} 
                                </b-badge>

                                <b-badge pill class="sort-chat" v-if="selectedOperatorId">
                                    <v-icon class="icon-sort-filter text-primary" left>mdi-account</v-icon> {{ selectedOperatorId }} 
                                    <a title="Hapus Filter" @click="removeSelected('', 'operator', false)">
                                        <i class="mdi mdi-close"></i>
                                    </a>
                                </b-badge>

                                <b-badge class="tag-label badge badge-warning badge-pill"  v-if="selectedStars">
                                    <span>
                                        <v-icon class="icon-sort-filter text-primary" left>mdi-star</v-icon> Ditandai
                                        <a title="Hapus Filter" @click="removeSelected('', 'stars', false)">
                                            <i class="mdi mdi-close"></i>
                                        </a>
                                    </span>
                                </b-badge>

                                <b-badge pill variant="primary" v-if="selectedChannelContact != ''">
                                    <v-icon class="icon-sort-filter text-primary" left>
                                        {{ selectedChannelContact == 'WA' ? 'mdi-whatsapp' : 
                                            selectedChannelContact == 'FB' ? 'mdi-facebook-messenger' : 'mdi-instagram' }}
                                    </v-icon>
                                    {{ selectedChannelContact == 'WA' ? 'WhatsApp' : selectedChannelContact == 'FB' ? 'Facebook' : 'Instagram' }}
                                    <a title="Hapus Filter" @click="removeSelected('', 'channel', false)">
                                        <i class="mdi mdi-close"></i>
                                    </a>
                                </b-badge>

                                <b-badge pill variant="primary" v-if="selectedDirection != ''">
                                    <v-icon class="icon-sort-filter text-primary" left>mdi-sort</v-icon> {{ selectedDirection }}  <a title="Hapus Filter" @click="removeSelected('', 'dirction', false)"><i class="mdi mdi-close"></i></a>
                                </b-badge> 

                                <b-badge class="tag-label" pill  v-for="(item, index) in selectedTags" :key="index">
                                    <span v-if="index < 2">
                                        <v-icon class="icon-sort-filter text-primary" left>mdi-tag</v-icon> {{ item }} <a title="Hapus Filter" @click="removeSelected(item, 'label', false)"><i class="mdi mdi-close"></i></a>
                                    </span>
                                </b-badge>
                                <v-tooltip bottom v-if="selectedTags.length > 2">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span class="badge tag-label badge-secondary badge-pill" v-bind="attrs" v-on="on">
                                            +{{ selectedTags.length - 2 }}
                                        </span>
                                    </template>
                                    <div v-for="(item, index) in selectedTags" :key="item">
                                        <span v-if="index > 1">
                                            <v-icon class="icon-sort-filter text-white" left>mdi-tag</v-icon> {{ item }}
                                        </span>
                                    </div>
                                </v-tooltip>
                                <b-badge class="cusRole" pill v-for="(item, index) in selectedRoles" :key="item">
                                    <span v-if="index < 2">
                                        <v-icon style="font-size:10px;  !important" class="icon-sort-filter text-primary" left>mdi-account-multiple</v-icon> {{ item }} <a title="Hapus Filter" @click="removeSelected(item, 'role', false)"><i class="mdi mdi-close"></i></a>
                                    </span>
                                </b-badge>
                                <v-tooltip bottom v-if="selectedRoles.length > 2">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span class="badge cusRole badge-secondary badge-pill" v-bind="attrs" v-on="on">
                                            +{{ selectedRoles.length - 2 }}
                                        </span>
                                    </template>
                                    <div v-for="(item, index) in selectedRoles" :key="item">
                                        <span v-if="index > 1">
                                            <v-icon class="icon-sort-filter text-white" left>mdi-account-multiple</v-icon> {{ item }}
                                        </span>
                                    </div>
                                </v-tooltip>
                            </div>
                            <div v-if="selectedSearchBy == 'messages' && !showFilter" class="wrapp-filter-sort">
                                  <b-badge class="sort-chat" pill >
                                      <v-icon class="icon-sort-filter text-primary" left>mdi-sort</v-icon> {{ contactUiFlag.sortBy == 'desc' ? 'Paling Baru' : 'Paling Lama' }} 
                                 </b-badge>

                                 <b-badge pill class="sort-chat">
                                    <v-icon class="icon-sort-filter text-primary" left>mdi-account</v-icon> {{ selectedSearchBy }} 
                                </b-badge>
                            </div>
                            <div class="filter-sort-chat" v-if="showFilter">
                                <span class="filter">
                                    <h6 class="mt-0">Filter</h6>
                                    <b-button pill size="sm"
                                        :variant="customFilterUiFlag.selectedSearchBy == 'contact' ? 'primary' : 'secondary'"
                                        class="mr-2"
                                        @click="customFilterUiFlag.selectedSearchBy = 'contact'"> <i
                                            class="mdi mdi-account"></i>
                                        Kontak</b-button>
                                    <b-button pill size="sm"
                                        :variant="customFilterUiFlag.selectedSearchBy == 'messages' ? 'primary' : 'secondary'"
                                        @click="customFilterUiFlag.selectedSearchBy = 'messages'">
                                        <i class="mdi mdi-message"></i>
                                        Pesan</b-button>
                                    <div v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                        <h6 class="mt-4">Pesan</h6>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.lastMessagesDirection == '' ? 'primary' : 'secondary'"
                                            class="mr-2"
                                            @click="customFilterUiFlag.lastMessagesDirection = ''">
                                            Semua Pesan
                                        </b-button>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.lastMessagesDirection == 'IN' ? 'primary' : 'secondary'"
                                            class="mr-2"
                                            @click="customFilterUiFlag.lastMessagesDirection = 'IN'">
                                            Belum Balas
                                        </b-button>

                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.lastMessagesDirection == 'OUT' ? 'primary' : 'secondary'"
                                            class="mr-2"
                                            @click="customFilterUiFlag.lastMessagesDirection = 'OUT'">
                                            Balas
                                        </b-button>
                                    </div>
                                    <div v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                        <h6 class="mt-4">Kanal</h6>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.channel == '' ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.channel = ''">
                                            Semua
                                        </b-button>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.channel == 'WA' ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.channel = 'WA'">
                                            WhatsApp
                                        </b-button>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.channel == 'FB' ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.channel = 'FB'">
                                            Facebook
                                        </b-button>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.channel == 'IG' ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.channel = 'IG'">
                                            Instagram
                                        </b-button>
                                    </div>
                                    <h6 class="mt-4">Urutkan</h6>
                                    <div v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.sortOrder == 'lastActive' && customFilterUiFlag.sortDir == 'desc' ? 'primary' : 'secondary'"
                                            class="mr-2"
                                            @click="customFilterUiFlag.sortOrder = 'lastActive', customFilterUiFlag.sortDir = 'desc'">
                                            Paling Baru
                                        </b-button>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.sortOrder == 'lastActive' && customFilterUiFlag.sortDir == 'asc' ? 'primary' : 'secondary'"
                                            class="mr-2"
                                            @click="customFilterUiFlag.sortOrder = 'lastActive', customFilterUiFlag.sortDir = 'asc'">
                                            Paling Lama
                                        </b-button>

                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.sortOrder == 'name' ? 'primary' : 'secondary'"
                                            class="mr-2"
                                            @click="customFilterUiFlag.sortOrder = 'name', customFilterUiFlag.sortDir = 'asc'">
                                            Pengirim
                                        </b-button>
                                    </div>
                                    <div v-if="customFilterUiFlag.selectedSearchBy != 'contact'">
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.sortMessage == 'desc' ? 'primary' : 'secondary'" 
                                            class="mr-2" 
                                            @click="customFilterUiFlag.sortMessage = 'desc'">
                                            Paling Baru
                                        </b-button>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.sortMessage == 'asc' ? 'primary' : 'secondary'" 
                                            class="mr-2" 
                                            @click="customFilterUiFlag.sortMessage = 'asc'">
                                            Paling Lama
                                        </b-button>
                                    </div>

                                    <div v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                        <h6 class="mt-4 mb-4">Kontak Ditandai</h6>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.stars ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.stars = true">
                                            Ya
                                        </b-button>

                                        <b-button pill size="sm"
                                            :variant="!customFilterUiFlag.stars ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.stars = false">
                                            Tidak
                                        </b-button>
                                    </div>
                                    <div>
                                        <v-autocomplete class="label-label mb-0"
                                            v-model="customFilterUiFlag.operatorId"
                                            :items="operatorList" 
                                            hide-selected
                                            item-text="name"
                                            item-value="_id"
                                            label="Operator"
                                        >
                                        </v-autocomplete>
                                    </div>
                                    <div class="" v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                        <v-autocomplete class="label-label mb-0"
                                            v-model="customFilterUiFlag.custroles"
                                            :items="customerRoleList" 
                                            hide-selected multiple
                                            item-text="name"
                                            item-value="id"
                                            label="Peran Pelanggan"
                                            :loading="custRoleLoading"
                                        >
                                            <template v-slot:selection="data">
                                                <v-chip
                                                    v-bind="data.attrs"
                                                    :input-value="data.selected"
                                                    close
                                                    @click="data.select"
                                                    @click:close="removeSelected(data.item, 'role', true)"
                                                >
                                                    {{ data.item.name }}
                                                </v-chip>
                                            </template>
                                        </v-autocomplete>
                                        <v-autocomplete class="label-label" 
                                            v-model="customFilterUiFlag.labels"
                                            :items="labels"
                                            hide-selected multiple persistent-hint chips
                                                label="Label"
                                            
                                        >
                                            <template v-slot:selection="data">
                                                <v-chip
                                                    v-bind="data.attrs"
                                                    :input-value="data.selected"
                                                    close
                                                    @click="data.select"
                                                    @click:close="removeSelected(data.item, 'label', true)"
                                                >
                                                    {{ data.item }}
                                                </v-chip>
                                            </template>
                                        </v-autocomplete>
                                    </div>

                                </span>
                                <span class="pull-right">
                                    <b-button variant="secondary" class="mr-2" size="sm" @click="resetCustomFilter" style="border:1px solid rgb(237 237 237);">
                                        Batal
                                    </b-button>
                                    <b-button variant="primary" class="mr-2" size="sm" @click="customFilter">
                                        Simpan
                                    </b-button>
                                </span>
                                <div class="clearfix"></div>
                            </div>
                            <div class="wrapp-btn-filter" v-if="!showFilter && !showConversation && !showSearchMessages">
                                <router-link class="btn"
                                    :to="{name: 'allmessage', query: {...pageQuery, status: 'open'}}"
                                    v-bind:class="{ 'btn-primary': pageQuery.status == 'open', 'btn-closed': pageQuery.status != 'open'}">
                                    Open</router-link>
                                <router-link class="btn"
                                    :to="{name: 'allmessage', query: {...pageQuery, status: 'closed'}}"
                                    v-bind:class="{ 'btn-primary': pageQuery.status == 'closed', 'btn-closed': pageQuery.status != 'closed'}">
                                    Closed</router-link>
                                <!--a class="btn"
                                        v-bind:class="{ 'btn-open': pageQuery.status == 'open', 'btn-closed': pageQuery.status != 'open' }"
                                        @click="switchStatus($event, 'open')">Open</a>
                                    <a class="btn"
                                        v-bind:class="{ 'btn-open': pageQuery.status == 'closed', 'btn-closed': pageQuery.status != 'closed' }"
                                        @click="switchStatus($event, 'closed')">Closed</a-->
                            </div>
                            <div class="form-body" v-if="!$helpers.isBlank(contactUiFlag.error)">
                                <div class="form-material form-filter">
                                    <ErrorMessage :error="contactUiFlag.error" />
                                </div>
                            </div>
                            <div id="contact-loader-indicator" class="text-center mt-4"
                                v-bind:class="{'d-none': !contactUiFlag.isLoading}">
                                <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                            </div>
                            
                            <ContactList
                                :page="'all-message'"
                                :contactList="contactList"
                                :searchMessageList="searchMessageList"
                                :msgUiFlag="msgUiFlag"
                                :contactUiFlag="contactUiFlag"
                                :showConversation="showConversation"
                                :showSearchMessages="showSearchMessages"
                                :showFilter="showFilter"
                                @jumpToElement="jumpToElement"
                                @selectContact="selectContact" />
                        </div>
                    </div>
                    <!-- .chat-left-panel -->
                    <!-- .chat-right-panel -->
                    <div class="chat-right-aside layer-opacity">
                        <div class="layer-disable"></div>
                        <div class="chat-rbox">
                            <MessageHeader 
                                :page="'all-message'"
                                :msgUiFlag="msgUiFlag"
                                :contactUiFlag="contactUiFlag"
                                :showConversation="showConversation"
                                @searchPersonalMessage="searchPersonalMessage"
                                @saveContactCallback="saveContactCallback"
                            />

                            <MessageList
                                :page="'all-message'"
                                :msgUiFlag="msgUiFlag"
                                :messageList="messageList"
                                :loadingJump="loadingJump"
                                @jumpToElement="jumpToElement"
                                @openNote="openNote" />
                        </div>
                        <!-- <div v-if="loadingJump">
                            <div class="d-flex justify-content-center">
                                <b-spinner label="Loading..."></b-spinner>
                            </div>
                        </div> -->
                        <div class="btn-assign" style="padding: 20px; margin: auto; text-align: center;">
                            <div v-if="showReOpenBtn" class="btn-assign"
                                style="padding: 20px; margin: auto; text-align: center;">
                                <a data-toggle="modal" @click="confirmReopen" class="btn btn-primary">Chat
                                    Kembali</a>
                            </div>
                            <div class="" v-if="showTextArea">
                                <p class="mb-2">Alihkan penanganan pesan ke:</p>
                                <a class="btn btn-primary mr-2" @click="takeConversation">Saya</a>
                                <b-dropdown style="display: inline-block;" text="Rekan">
                                    <b-dropdown-item v-for="(item) in operatorList" :key="item.id">
                                        <div class="d-flex">
                                            <input type="button" class="dropdown-item"
                                            @click="transferConversation($event, item._id, item.name)"
                                            v-bind:value="item.name">
                                            <span v-if="item.active == 1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="#00FF00" class="bi bi-dot" viewBox="0 0 16 16">
                                                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                                                </svg>
                                            </span>
                                        </div>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                    </div>
                    <!-- .chat-right-panel -->
                </div>
                <!-- /.chat-row -->
            </div>
        </div>
        <UpdateNote v-bind:contactId="contactUiFlag.selectedContactId" v-bind:messageId="selectedMessage._id" :sessionId="contactUiFlag.selectedSessionId"
            v-bind:currentNote="selectedMessage.internalNotes" :showDialog="dialogNote" @close-dialog="closeDialog" />
        <AddToContact v-bind:phoneNumber="contactUiFlag.selectedContactId" :sessionId="contactUiFlag.selectedSessionId"
            v-bind:addedContactId="contactUiFlag.selectedContactId" v-on:save-callback="saveContactCallback" />
        <SendImage v-bind:contactId="contactUiFlag.selectedContactId"
            v-bind:destNumber="contactUiFlag.selectedContactPrimaryNumber" />
        <Alert :message="alertMessage" />
        <CloseChat :contactId="contactUiFlag.selectedContactId" :contactName="contactUiFlag.selectedContactName"
            @close-callback="closeChatCallback" />
        <ReopenChat :contactId="contactUiFlag.selectedContactId" :contactName="contactUiFlag.selectedContactName"
            @reopen-callback="reopenChatCallback" />
        <TakeConversation v-bind:contactId="contactUiFlag.selectedContactId"
            v-bind:contactName="contactUiFlag.selectedContactName"
            :sessionId="contactUiFlag.selectedSessionId" />
        <TransferConversation v-bind:contactId="contactUiFlag.selectedContactId"
            v-bind:contactName="contactUiFlag.selectedContactName" v-bind:toOperatorId="assignToData.operatorId"
            v-bind:toOperatorName="assignToData.operatorName" :sessionId="contactUiFlag.selectedSessionId" />
    </div>
</template>

<script>
    import Scrollbar from 'smooth-scrollbar';
    import axios from "axios";
    import MainLayout from "../layouts/MainLayout"
    import AddToContact from '../components/AddToContact'
    import SendImage from '../components/SendImage'
    import ErrorMessage from '../components/ErrorMessage'
    import Alert from '../components/Alert'
    import CloseChat from '../components/CloseChat'
    import ReopenChat from '../components/ReopenChat'
    import TakeConversation from '../components/TakeConversation'
    import TransferConversation from '../components/TransferConversation'
    import IMAGE_ACC from '../../public/images/dummy.jpg'
    import IMAGE_MESSAGE from '../../public/images/image-not-found.png'
    import UpdateNote from '../components/UpdateNote'
    import MessageList from '../components/MessageList.vue';
    import ContactList from '../components/ContactList.vue';
    import MessageHeader from '../components/MessageHeader.vue';

    import {
        mapState,
        mapActions
    } from 'vuex'

    const {
        MODULES
    } = require('../utils/const')
    const $ = require("jquery")
    const {
        toFriendlyDateTime
    } = require('../utils/date-utils');
    const {
        updateContactList
    } = require('../utils/contact-utils');
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup');
    var firebase = require("firebase");
    var moment = require('moment');
    var debounce = require('lodash.debounce');
    const uuidv4 = require('uuid/v4');

    const CONTACT_ROW_PER_PAGE = 25;
    const MESSAGE_ROW_PER_PAGE = 25;

    var messageScrollBar = null;
    var contactScrollBar = null;

    var messageListnerList = [];
    var removeContactListener = null;

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo,
                operatorList: state => state.operator.operatorList,
                operatorActive: state => state.socket.activeOperatorIds,
                labels: state => state.select.labels,
                customerRoleList: state => state.select.customerRoles,
                isAccountTest: state => state.session.sessionInfo.userId == "bippofb"
            })
        },
        data: function () {
            return {
                selectedMessage: Object(),
                dialogNote: false,
                searchMessageList: {
                    personal: [],
                    global: [],
                },
                isTypingMsg: false,
                loadingJump: false,
                custRoleLoading: false,
                selectedDirection: '',
                selectedTags: [],
                selectedLabels: [],
                selectedRoles: [],
                selectedStars: false,
                selectedOperatorId: String(),
                selectedChannelContact: String(),
                searchLabel: String(),
                showConversation: false,
                showSearchMessages: false,
                contactList: [],
                messageList: [],
                showFilter: false,
                selectedSearchBy: 'contact',
                pageQuery: {
                    status: 'open',
                    sortOrder: 'lastActive',
                    sortDir: 'desc',
                    custroles: '',
                    labels: '',
                    lastMessagesDirection: '',
                    stars: false,
                    operatorId: '',
                    channel: ''
                },
                customFilterUiFlag: {
                    sortOrder: 'lastActive',
                    sortDir: 'desc',
                    labels: [],
                    custroles: [],
                    selectedSearchBy: 'contact',
                    sortMessage: 'desc',
                    lastMessagesDirection: '',
                    stars: false,
                    operatorId: '',
                    channel: '',
                },
                items: ['Retur', 'Komplain', 'Shopping'],
                contactUiFlag: {
                    isLoading: false,
                    selectedContactChannel: [],
                    currentPage: 1,
                    searchTerm: '',
                    selectedContactId: '',
                    selectedContactName: '',
                    selectedContactRole: '',
                    selectedContactPrimaryNumber: '',
                    selectedContactDefaultChannel: '',
                    selectedIsUnknonwnContact: '',
                    hasMoreData: true,
                    selectedSessionId: '',
                    error: {},
                    sortBy: '',
                    selectedStars: false,
                    selectedProfilePicUrl: '',
                    isAffiliator: false,
                    currentAffiliator: null
                },
                newMessage: {
                    messageText: '',
                    isLoading: false
                },
                isSendInProgress: false,
                logErrorMsg: '',
                msgUiFlag: {
                    isLoading: false,
                    currentPage: 1,
                    globalCurrentPage: 1,
                    hasMoreData: true,
                    hasMoreDataNext: true,
                    globalHasMoreData: true,
                    error: {},
                    searchTerm: '',
                },
                assignToData: {
                    operatorId: '',
                    operatorName: ''
                },
                newContact: {
                    id: '',
                    name: '',
                    saveErrorMessage: ''
                },
                isTyping: false,
                showButtonAdd: false,
                showTextArea: false,
                showReOpenBtn: false,
                onClickClose: false,
                onClickOpen: true,
                alertMessage: {}
            }
        },
        watch: {
            'contactUiFlag.searchTerm': debounce(async function () {
                if (this.contactUiFlag.searchTerm == "") {
                    if(this.selectedSearchBy == 'contact') {
                        await this.loadContact()
                    }
                }
            }, 1000),
            'msgUiFlag.searchTerm': debounce(function () {
                if(this.msgUiFlag.searchTerm == '') {
                    this.showConversation = false
                    return
                }
                // this.showConversation = true
                // this.isTypingMsg = false;
                // this.msgUiFlag.hasMoreDataNext = true
            }, 1000),
            selectedLabels: function(value) {
                let queryLabel = value.join(',')
                this.pageQuery.labels = queryLabel 
                this.$router.push({query: {...this.pageQuery, labels: queryLabel}})
            },
            selectedSearchBy: function (value) {
                this.showConversation = false
                if (value == 'messages') {
                    this.showSearchMessages = true
                } else {
                    this.showSearchMessages = false
                }
            },
            // isTyping: async function (value) {
            //     if(!this.showFilter) {
            //         if (!value) {
            //             if (this.selectedSearchBy == 'contact') {
            //                 await this.loadContact();
            //             } else {
            //                 await this.searchGlobalMessage();
            //             }
            //         }
            //     }
            // },
            isTypingMsg: function (value) {
                if(this.msgUiFlag.searchTerm == '') {
                    this.showConversation = false
                    return
                }
                if (!value) {
                    this.searchPersonalMessage();
                }
            },
            $route: async function (to, from) {
                var refreshContact = false;

                if (to.query.status != from.query.status) {
                    this.pageQuery.status = to.query.status;

                    if (this.pageQuery.status == 'closed') {
                        this.showReOpenBtn = false;
                        this.showTextArea = false;
                        this.onClickClose = true;
                        this.onClickOpen = false;
                    } else {
                        this.showReOpenBtn = false;
                        this.showTextArea = false;
                        this.onClickClose = false;
                        this.onClickOpen = true;
                    }
                    refreshContact = true
                }
                if (to.query.sortOrder != from.query.sortOrder) {
                    this.pageQuery.sortOrder = to.query.sortOrder;
                    refreshContact = true
                }

                if (to.query.sortDir != from.query.sortDir) {
                    this.pageQuery.sortDir = to.query.sortDir;
                    refreshContact = true
                }

                if(to.query.labels != from.query.labels) {
                    refreshContact = true
                }

                if (to.query.custroles != from.query.custroles) {
                    refreshContact = true
                }

                if (to.query.lastMessagesDirection != from.query.lastMessagesDirection) {
                    refreshContact = true
                }

                const toStar = to.query.stars == true || to.query.stars == 'true' ? true : false
                const fromStar = from.query.stars == true || to.query.stars == 'true' ? true : false

                if (toStar != fromStar) {
                    this.pageQuery.stars = toStar
                    refreshContact = true
                }

                if (to.query.operatorId != from.query.operatorId) {
                    this.pageQuery.operatorId = to.query.operatorId || String()
                    refreshContact = true
                }

                if (to.query.channel != from.query.channel) {
                    this.pageQuery.channel = to.query.channel;
                    refreshContact = true
                }

                if( to.query.status == 'open' && to.query.sortOrder == 'lastActive' && to.query.sortDir == 'desc' && 
                    this.$helpers.isBlank(to.params.id) && to.query.labels == '' && to.query.lastMessagesDirection == '' && 
                    to.query.operatorId == '' && !toStar ) {
                    refreshContact = true
                }

                if (refreshContact) {
                    this.msgUiFlag.currentPage = 1;
                    await this.loadContact();
                }

                if (this.$helpers.isBlank(to.params.id)) {
                    this.clearChatPanel();
                } else if ((to.params.id != from.params.id) || to.query.status != from.query.status) {
                    this.selectChat(decodeURIComponent(to.params.id));
                }

                const self = this
                if (to.query.chatId) {
                    setTimeout(async function () {
                        await self.jumpToElementGlobal(decodeURIComponent(to.query.chatId))
                    }, 1000)
                }
            },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            }
        },
        methods: {
            ...mapActions({
                initOperatorList: 'operator/initOperatorList',
                actGetLabel: 'select/actGetLabel',
                actGetCustomerRoles: 'select/actGetCustomerRoles',
                actGetCommentCount: 'getCommentCount'
            }),
            closeDialog: function(val) {
                this.dialogNote = val
            },
            listSearchMessageMapper(messageList) {
                const mapped = messageList.map((val) => {
                    val.time = toFriendlyDateTime(val.createdOn);

                    return val;
                });

                return mapped
            },
            openNote: function (message) {
                this.selectedMessage = message
                this.dialogNote = true
                // this.$bvModal.show("update-note-modal");
            },
            selectContact: function (item) {
                this.$router.push({
                    name: 'allmessage',
                    params: {
                        id: encodeURIComponent(item.id)
                    },
                    query: {
                        ...this.pageQuery,
                    }
                });
            },
            handleImageError: function (event, type) {
                if(type == 'contact') event.target.src = IMAGE_ACC;
                if(type == 'message') event.target.src = IMAGE_MESSAGE;
            },
            searchContactFlag: async function() {
                if (this.selectedSearchBy == 'contact') {
                    this.showSearchMessages = false
                    this.loadContact();
                } else {
                    this.showSearchMessages = true
                    this.searchGlobalMessage();
                }
            },
            removeSelected: function(item, type, isFilter) {
                if(isFilter) {
                    if(type == 'role') {
                        const index = this.customFilterUiFlag.custroles.indexOf(item.id)
                        if (index >= 0) this.customFilterUiFlag.custroles.splice(index, 1)
                    } else {
                        const index = this.customFilterUiFlag.labels.indexOf(item)
                        if (index >= 0) this.customFilterUiFlag.labels.splice(index, 1)
                    }
                } else {
                    if(type == 'role') {
                        const index = this.selectedRoles.indexOf(item)
                        if (index >= 0) {
                            this.selectedRoles.splice(index, 1)
                            this.customFilterUiFlag.custroles = [...this.selectedRoles]
                            this.pageQuery = {
                                ...this.pageQuery,
                                custroles: this.selectedRoles.join(",")
                            }
                            localStorage.setItem('custroles', this.selectedRoles.join(","))
                        }
                    } else if (type == 'label') {
                        const index = this.selectedTags.indexOf(item)
                        this.selectedTags.splice(index, 1)
                        this.customFilterUiFlag.labels = [...this.selectedTags]
                        this.pageQuery = {
                            ...this.pageQuery,
                            labels: this.selectedTags.join(",")
                        }
                    } else if(type == 'stars') {
                        this.selectedStars = false
                        this.customFilterUiFlag.stars = false
                        this.pageQuery = {
                            ...this.pageQuery,
                            stars: false
                        }
                    } else if(type == 'operator') {
                        this.selectedOperatorId = String()
                        this.customFilterUiFlag.operatorId = String()
                        this.pageQuery = {
                            ...this.pageQuery,
                            operatorId: String()
                        }
                    } else if (type == 'channel') {
                        this.selectedChannelContact = ''
                        this.customFilterUiFlag.channel = ''
                        this.pageQuery = {
                            ...this.pageQuery,
                            channel: ''
                        }
                    }else {
                        this.selectedDirection = ''
                        this.customFilterUiFlag.lastMessagesDirection = ''
                        this.pageQuery = {
                            ...this.pageQuery,
                            lastMessagesDirection: ''
                        }
                    }
                    this.$router.push({ query: {...this.pageQuery} })
                }
            },
            resetCustomFilter: function() {
                // this.$bvModal.hide('modal-center')
                this.customFilterUiFlag.sortDir = this.pageQuery.sortDir
                this.customFilterUiFlag.sortOrder = this.pageQuery.sortOrder
                this.customFilterUiFlag.selectedSearchBy = this.selectedSearchBy
                this.customFilterUiFlag.labels = [...this.selectedTags]
                this.customFilterUiFlag.custroles = [...this.selectedRoles]
                this.customFilterUiFlag.sortMessage = this.contactUiFlag.sortBy
                this.customFilterUiFlag.searchTerm = this.contactUiFlag.searchTerm
                this.customFilterUiFlag.lastMessagesDirection = this.pageQuery.lastMessagesDirection
                this.customFilterUiFlag.stars = this.pageQuery.stars
                this.customFilterUiFlag.operatorId = this.pageQuery.operatorId
                this.customFilterUiFlag.channel = this.pageQuery.channel
                this.showFilter = false
            },
            customFilter:async function(){
                // this.$bvModal.hide('modal-center')
                this.showFilter = false
                let query = this.pageQuery
                let filter = this.customFilterUiFlag
                this.selectedSearchBy = filter.selectedSearchBy
                this.isTyping = true
                // this.contactUiFlag.searchTerm = filter.searchTerm
                this.contactUiFlag.sortBy = filter.sortMessage
                if(filter.selectedSearchBy == 'contact') {
                    localStorage.setItem('custroles', filter.custroles.join(","))

                    if(
                        query.sortDir != filter.sortDir ||
                        query.sortOrder != filter.sortOrder ||
                        query.custroles != filter.custroles ||
                        query.labels != filter.labels ||
                        query.lastMessagesDirection != filter.lastMessagesDirection ||
                        query.stars != filter.stars ||
                        query.operatorId != filter.operatorId ||
                        query.channel != filter.channel
                    ) {
                        this.pageQuery = {
                            ...query,
                            sortDir: filter.sortDir,
                            sortOrder: filter.sortOrder,
                            custroles: filter.custroles.join(","),
                            labels: filter.labels.join(","),
                            lastMessagesDirection: filter.lastMessagesDirection,
                            stars: filter.stars,
                            operatorId: filter.operatorId,
                            channel: filter.channel
                        }
                        this.selectedTags = [...filter.labels]
                        this.selectedRoles = [...filter.custroles]
                        this.selectedDirection = filter.lastMessagesDirection
                        this.selectedStars = filter.stars
                        this.selectedOperatorId = filter.operatorId
                        this.selectedChannelContact = filter.channel
                        this.$router.push({ query: {...this.pageQuery} })
                    } else {
                        await this.loadContact()
                    }
                } else {
                    await this.searchGlobalMessage()
                }
            },
            changeInitialName: function (name) {
                let changedName = String()

                name.split(" ").forEach(e => {
                    if(changedName.length < 2) changedName += e[0]
                })

                return changedName
            },
            showUploadImageDialog: function (event) {
                event.preventDefault()
                this.$bvModal.show('send-image-modal')
            },
            changeChannel: async function (event, channel, number) {
                event.preventDefault();
                this.contactUiFlag.selectedContactPrimaryNumber = number
                this.contactUiFlag.selectedContactDefaultChannel = channel;
                this.contactUiFlag.error = {}

                var contactId = this.contactUiFlag.selectedContactId;
                const putData = {
                    "phoneNumber": number,
                    "channel": channel
                }

                try {
                    await axios.put(process.env.VUE_APP_API_URL + '/contact/defaultchannel/' + contactId, putData);
                    var idx = this.contactList.findIndex((item) => {
                        return item.id == contactId;
                    })

                    if (idx >= 0) {
                        var data = this.contactList[idx];
                        data.primaryPhoneNumber = number;
                        data.defaultChannel = channel;
                        this.contactList.splice(idx, 1, data);
                    }
                } catch (error) {
                    this.contactUiFlag.error = this.$helpers.createError(error)
                }
            },
            sendMessage: async function (event) {
                event.preventDefault();
                var self = this;
                var postData = {
                    "id": uuidv4(),
                    "number": this.contactUiFlag.selectedContactPrimaryNumber,
                    "text": this.newMessage.messageText
                };
                this.msgUiFlag.error = {}

                const createdOn = (new Date()).getTime();
                var msgData = {
                    _id: postData.id,
                    channel: this.contactUiFlag.selectedContactDefaultChannel,
                    contactId: this.contactUiFlag.selectedContactId,
                    createdOn: createdOn,
                    time: toFriendlyDateTime(createdOn),
                    from: 'op',
                    text: this.newMessage.messageText,
                    sentTo: this.contactUiFlag.selectedContactPrimaryNumber,
                    status: 'pending',
                    operatorId: global.userId,
                    operatorName: global.userFullName
                }
                this.messageList.splice(this.messageList.length, 0, msgData);
                messageScrollBar.update();
                setTimeout(() => {
                    messageScrollBar.scrollTo(0, messageScrollBar.getSize().content.height, 0);
                    messageScrollBar.addListener(self.messageScrollBarListener);
                }, 50)

                this.newMessage.isLoading = true
                try {
                    await axios.post(process.env.VUE_APP_API_URL + '/message/' + this.contactUiFlag
                        .selectedContactId + '/' + this.contactUiFlag
                        .selectedContactDefaultChannel, postData);
                    this.newMessage.messageText = ''
                } catch (error) {
                    this.msgUiFlag.error = this.$helpers.createError(error)
                    if (this.msgUiFlag.error.httpStatus == 424) {
                        this.newMessage.messageText = ''
                    }
                }
                this.newMessage.isLoading = false
            },
            confirmReopen: function (event) {
                event.preventDefault();
                this.$bvModal.show("reopen-chat-dialog");
            },
            reopenChatCallback: async function (isSuccess, extras) {
                if (isSuccess) {
                    this.$router.push({
                        name: 'myconversation',
                        params: {
                            id: extras
                        },
                        query: {
                            ...this.pageQuery,
                            status: 'open'
                        }
                    })
                } else {
                    this.msgUiFlag.error = this.$helpers.createError(extras)
                }
            },
            confirmClose: function (event) {
                event.preventDefault();
                this.$bvModal.show("close-chat-dialog");
            },
            closeChatCallback: async function (isSuccess, error) {
                if (isSuccess) {
                    messageScrollBar.removeListener(this.messageScrollBarListener);
                    this.messageList = [];
                    this.contactUiFlag.selectedContactName = '';
                    this.contactUiFlag.selectedContactPrimaryNumber = '';
                    this.contactUiFlag.selectedContactDefaultChannel = '';
                    this.contactUiFlag.selectedIsUnknonwnContact = '';
                    this.showReOpenBtn = false;
                    this.showTextArea = false;
                } else {
                    this.msgUiFlag.error = this.$helpers.createError(error)
                }
            },
            loadMoreMessages: async function (isPrev) {
                const params = {
                    size: MESSAGE_ROW_PER_PAGE,
                    direction: isPrev ? 'before' : 'after',
                    timeStamp: isPrev ? this.messageList[0].createdOn : this.messageList[this.messageList
                        .length - 1].createdOn
                }
                
                var contactId = this.contactUiFlag.selectedContactId;
                this.msgUiFlag.error = {}

                if (this.$helpers.isBlank(contactId)) return;

                this.msgUiFlag.currentPage++;
                // var page = this.msgUiFlag.currentPage;

                if (!isPrev) this.loadingJump = true;

                this.msgUiFlag.isLoading = true;
                try {

                    const resp = await axios.get(process.env.VUE_APP_API_URL + '/message/contact-ts-anchor/' + contactId, {
                            params
                        });

                    var jsonData = resp.data;

                    if (isPrev) {
                        this.msgUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    } else {
                        this.msgUiFlag.hasMoreDataNext = jsonData.page.number < jsonData.page.totalPages;
                    }

                    this.msgUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;

                    const tmp = this.$helpers.listMessageMapper(jsonData.content, this.messageList[0])

                    // before 
                    var scrollView = Scrollbar.get($('.wrapp-chat-content').get()[0]);
                    var currentHeight = scrollView.getSize().content.height;
                    
                    if (isPrev) {
                        this.messageList.splice(0, 0, ...tmp);
                    } else {
                        this.messageList = [...this.messageList, ...tmp]
                    }

                    // wait for element tobe rendered, then call setPosition
                    scrollView.update();
                    setTimeout(() => {
                        if (isPrev) {
                            scrollView.setPosition(0, scrollView.getSize().content.height -
                                currentHeight, {
                                    withoutCallbacks: true
                                });
                        } else {
                            scrollView.setPosition(0, scrollView.offset.y);
                        }
                        messageScrollBar.addListener(this.messageScrollBarListener);
                    }, 120)
                } catch (error) {
                    messageScrollBar.addListener(this.messageScrollBarListener);
                    this.msgUiFlag.error = this.$helpers.createError(error)
                }
                this.msgUiFlag.isLoading = false;
                this.loadingJump = false;
            },
            loadMessage: async function (contactId) {
                this.msgUiFlag.isLoading = true;
                this.msgUiFlag.error = {}
                var self = this;
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/message/contact/' + contactId, {
                        params: {
                            size: MESSAGE_ROW_PER_PAGE
                        }
                    });
                    this.msgUiFlag.isLoading = false;
                    var jsonData = resp.data;
                    this.showButtonAdd = true;
                    this.msgUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;

                    this.messageList = this.$helpers.listMessageMapper(jsonData.content, null)

                    messageScrollBar.update();

                    setTimeout(() => {
                        messageScrollBar.scrollTo(0, messageScrollBar.getSize().content.height,
                            0);
                        messageScrollBar.addListener(self.messageScrollBarListener);
                        if (messageListnerList.indexOf(contactId) < 0) {
                            firebase.database().ref(self.sessionInfo.tenantId + '_' + process.env
                                .VUE_APP_ENV +
                                '/messages/' +
                                contactId).on("child_changed", self.updateMessageFromFb);
                            firebase.database().ref(self.sessionInfo.tenantId + '_' + process.env
                                .VUE_APP_ENV +
                                '/messages/' +
                                contactId).orderByChild("createdOn").limitToLast(1).on(
                                "child_added", self.updateMessageFromFb);
                            messageListnerList.push(contactId)
                        }
                    }, 250)

                    this.$bvModal.hide('modal-image-loader');
                } catch (error) {
                    this.msgUiFlag.isLoading = false;
                    messageScrollBar.addListener(this.messageScrollBarListener);
                    this.msgUiFlag.error = this.$helpers.createError(error)
                    this.$bvModal.hide('modal-image-loader');
                }
            },
            loadContact: async function () {
                this.contactList = [];
                this.contactUiFlag.error = {};

                this.showConversation = false
                this.contactUiFlag.currentPage = 1;
                this.contactUiFlag.hasMoreData = true;

                this.contactUiFlag.isLoading = true;
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/contact/listallmessage', {
                        params: {
                            page: this.contactUiFlag.currentPage,
                            sort: this.pageQuery.sortOrder,
                            term: this.contactUiFlag.searchTerm,
                            status: this.pageQuery.status,
                            size: CONTACT_ROW_PER_PAGE,
                            labels: this.pageQuery.labels,
                            sortDir: this.pageQuery.sortDir,
                            custroles: this.pageQuery.custroles,
                            lastMessagesDirection: this.pageQuery.lastMessagesDirection,
                            stars: this.pageQuery.stars,
                            operatorId: this.pageQuery.operatorId,
                            contactChannel: this.pageQuery.channel,
                        }
                    });
                    this.contactUiFlag.isLoading = false;
                    var jsonData = resp.data;
                    this.contactUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    this.contactList = jsonData.content.map((val) => {
                        val.time = toFriendlyDateTime(val.lastActive);
                        return val;
                    });

                    const self = this

                    contactScrollBar.update()
                    setTimeout(() => {
                        contactScrollBar.scrollTo(0, 0);
                        contactScrollBar.addListener(self.contactScrollBarListener);
                    }, 250)
                } catch (error) {
                    this.contactUiFlag.isLoading = false;
                    this.contactUiFlag.error = this.$helpers.createError(error)
                }
            },
            loadMoreContacts: async function () {
                this.contactUiFlag.isLoading = true;
                this.contactUiFlag.error = {};
                this.contactUiFlag.currentPage++;
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/contact/listallmessage', {
                        params: {
                            page: this.contactUiFlag.currentPage,
                            sort: this.pageQuery.sortOrder,
                            term: this.contactUiFlag.searchTerm,
                            status: this.pageQuery.status,
                            size: CONTACT_ROW_PER_PAGE,
                            labels: this.pageQuery.labels,
                            sortDir: this.pageQuery.sortDir,
                            custroles: this.pageQuery.custroles,
                            lastMessagesDirection: this.pageQuery.lastMessagesDirection,
                            stars: this.pageQuery.stars,
                            operatorId: this.pageQuery.operatorId,
                            contactChannel: this.pageQuery.channel,
                        }
                    });
                    this.contactUiFlag.isLoading = false;
                    var jsonData = resp.data;
                    this.contactUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    var tmp = jsonData.content.map((val) => {
                        val.time = toFriendlyDateTime(val.lastActive);
                        return val;
                    });

                    // bottom 
                    var scrollView = Scrollbar.get($('#left-inner-scrollbar').get()[0]);

                    this.contactList.splice(this.contactList.length, 0, ...tmp);

                    scrollView.update();

                    const self = this

                    setTimeout(() => {
                        scrollView.setPosition(0, scrollView.offset.y);
                        contactScrollBar.addListener(self.contactScrollBarListener);
                    }, 120)
                } catch (error) {
                    this.contactUiFlag.isLoading = false;
                    this.contactUiFlag.error = this.$helpers.createError(error)
                }
            },
            cancelSearchPersonalMessage: function() {
                this.showSearchMessages = false
                this.msgUiFlag.searchTerm = String()
                $(".open-panel").click();
            },
            searchPersonalMessage: async function () {
                const contactId = this.contactUiFlag.selectedContactId
                const term = this.msgUiFlag.searchTerm

                this.showSearchMessages = false
                // this.selectedSearchBy = ''
                this.showConversation = true
                this.resetCustomFilter()

                this.msgUiFlag.isLoading = true

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/message/search/' + contactId + '?term=' + term);

                    this.searchMessageList.personal = this.listSearchMessageMapper(data.content)
                } catch (err) {
                    console.log(err);
                }
                this.msgUiFlag.isLoading = false
            },
            searchGlobalMessage: async function () {
                const term = this.contactUiFlag.searchTerm

                // if (!this.contactUiFlag.searchTerm) {
                //     this.showSearchMessages = false
                //     this.selectedSearchBy = 'contact'
                // }

                this.contactUiFlag.isLoading = true
                this.msgUiFlag.globalCurrentPage = 1;
                this.msgUiFlag.globalHasMoreData = true;

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/message/search', {
                            params: {
                                page:  this.msgUiFlag.globalCurrentPage,
                                term: term,
                                size: CONTACT_ROW_PER_PAGE,
                                sortDir: this.contactUiFlag.sortBy,
                            }
                        }
                    );

                    this.searchMessageList.global = this.listSearchMessageMapper(data.content)

                    const self = this

                    contactScrollBar.update()
                    setTimeout(() => {
                        contactScrollBar.scrollTo(0, 0);
                        contactScrollBar.addListener(self.contactScrollBarListener);
                    }, 250)
                } catch (err) {
                    console.log("global-message", err);
                }
                this.contactUiFlag.isLoading = false
            },
            loadMoreGlobalMessage: async function () {
                const term = this.contactUiFlag.searchTerm
                const sort = this.contactUiFlag.sortBy

                this.contactUiFlag.isLoading = true;
                this.msgUiFlag.globalCurrentPage++;

                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/message/search', {
                        params: {
                            page:  this.msgUiFlag.globalCurrentPage,
                            term: term,
                            size: CONTACT_ROW_PER_PAGE,
                            sortDir: sort,
                        }
                    });

                    var jsonData = resp.data;
                    this.msgUiFlag.globalHasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    const tmp = this.listSearchMessageMapper(jsonData.content)

                    // bottom 
                    var scrollView = Scrollbar.get($('#left-inner-scrollbar').get()[0]);

                    this.searchMessageList.global.splice(this.searchMessageList.global.length, 0, ...tmp);

                    scrollView.update();

                    const self = this

                    setTimeout(() => {
                        scrollView.setPosition(0, scrollView.offset.y);
                        contactScrollBar.addListener(self.contactScrollBarListener);
                    }, 120)

                } catch (error) {
                    this.contactUiFlag.error = this.$helpers.createError(error)
                }
                this.contactUiFlag.isLoading = false;
            },
            jumpToElementGlobal: async function (id) {
                const contactId = this.contactUiFlag.selectedContactId
                this.loadingJump = true;
                this.msgUiFlag.hasMoreDataNext = true

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/message/contact/' + contactId + '/' + id + '?size=5');

                    this.messageList = this.$helpers.listMessageMapper(data.content, this.messageList[0])

                    setTimeout(() => {
                        messageScrollBar.scrollIntoView(document.getElementById(`chatitem-${id}`));
                    }, 100);

                } catch (error) {
                    console.log(error);
                }

                this.loadingJump = false;
            },
            jumpToElement: async function (id) {
                const contactId = this.contactUiFlag.selectedContactId
                this.loadingJump = true;
                this.msgUiFlag.hasMoreDataNext = true

                try {
                    var isMessage = this.messageList.find(e => e._id == id || e.externalId == id)

                    if (!isMessage) {
                        const { data } = await axios.get(process.env.VUE_APP_API_URL + '/message/contact/' + contactId + '/' + id + '?size=5');

                        this.messageList = this.$helpers.listMessageMapper(data.content, this.messageList[0])
                        await this.jumpToElement(id);
                    }

                    if (isMessage) {
                        setTimeout(() => {
                            messageScrollBar.scrollIntoView(document.getElementById(`chatitem-${id}`));
                        }, 100);
                    }

                } catch (error) {
                    console.log(error);
                }

                this.loadingJump = false;
            },
            updateContactListener: function (snapshot) {
                if (this.contactUiFlag.selectedContactId == snapshot.key) {
                    const data = snapshot.val()

                    this.contactUiFlag = {
                        ...this.contactUiFlag,
                        selectedStars: data.stars
                    }
                }

                updateContactList(
                    MODULES.ALL_MESSAGES, 
                    this.contactList, 
                    snapshot.val(), 
                    snapshot.key, 
                    this.pageQuery.sortOrder,
                    this.contactUiFlag.searchTerm,
                    !this.contactUiFlag.hasMoreData,
                    this.sessionInfo.userId,
                    this.pageQuery.status, 
                    this.selectedTags, 
                    this.selectedRoles, 
                    this.pageQuery.lastMessagesDirection
                )
            },
            updateNotif: function () {
                var responseCode = '';
                var putData = {

                };
                fetch(process.env.VUE_APP_API_URL + '/notif/assigned', {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json; charset=utf-8"
                    },
                    credentials: 'include',
                    body: JSON.stringify(putData)
                }).then(function (response) {
                    responseCode = response.status;

                    // $(modalId).modal('hide');
                    return response.json();
                }).then(function () {
                    if (responseCode == 200) {
                        console.log('success update Notif');

                    } else {
                        console.log('error');
                    }
                }).catch(error => console.error('Error:', error));
            },
            updateMessageFromFb: function (snapshot) {
                var contactId = snapshot.ref.parent.key;
                var messageId = snapshot.key;
                var messageContent = snapshot.val();

                const convertDate = messageContent.createdOn ? new Date(messageContent.createdOn) : new Date();
                messageContent.createdOn = convertDate.toISOString()
                messageContent.text = messageContent.text ? this.$helpers.formatTabMessages(messageContent.text) : ""

                if(messageContent.url && process.env.VUE_APP_IMAGE_ORIGINAL_URL && process.env.VUE_APP_IMAGE_CDN_URL) {
                    messageContent.cdnUrl = messageContent.url.replace(process.env.VUE_APP_IMAGE_ORIGINAL_URL, process.env.VUE_APP_IMAGE_CDN_URL)
                }

                messageContent.showReaction = false

                if (messageContent.noteAction) {
                    messageContent.noteAction.time = toFriendlyDateTime(messageContent.noteAction.createdOn)
                }

                if (messageContent.internalNotes) {
                    messageContent.internalNotes.forEach(note => {
                        note.time = toFriendlyDateTime(note.createdOn)
                        note.createdOn = new Date(note.createdOn).toISOString(note.createdOn)
                        note.kind = note.kind ? note.kind : []
                    })
                    messageContent.internalNotes = messageContent.internalNotes ? this.helpers.formatInternalNotes(messageContent.internalNotes) : ""
                }

                if (messageContent.isResponseTime) {
                    if(messageContent.responseTime < 60) {
                        messageContent.convertedResponseTime = "0:" + messageContent.responseTime
                    } else {
                        const minute = Math.floor(messageContent.responseTime / 60)
                        const seconds = messageContent.responseTime % 60
                        messageContent.convertedResponseTime = minute + ":" + seconds
                    }
                }

                let showOpHeader = false

                if(this.messageList.length > 0) {
                    const selectedMsg = this.messageList[this.messageList.length - 1]

                    if(selectedMsg.from == 'cust' || selectedMsg.operatorId != messageContent.userId) {
                        showOpHeader = true
                    }
                }

                var msgData = {
                    ...messageContent,
                    _id: messageId,
                    contactId: contactId,
                    time: toFriendlyDateTime(messageContent.createdOn),
                    showOpHeader: showOpHeader
                }

                if (messageContent.url) {
                    msgData.url = messageContent.url;
                    msgData.thumb = messageContent.thumb;
                }

                if (msgData.from == 'op' || msgData.from == 'op-wa') {
                    msgData.sentTo = messageContent.sentTo;
                    msgData.status = messageContent.status;
                    msgData.operatorId = messageContent.operatorId;
                    msgData.operatorName = messageContent.operatorName;
                } else {
                    msgData.fromChannelId = messageContent.fromChannelId;
                    msgData.fromName = messageContent.fromName;
                }

                if (contactId == this.contactUiFlag.selectedContactId) {
                    if (this.messageList.length == 0) {
                        this.messageList.push(msgData);
                    } else if (!this.messageList[0].createdOn || moment(this.messageList[0].createdOn)
                        .isSameOrBefore(messageContent.createdOn)) {
                        var i = 0;
                        var found = false;
                        do {
                            if (this.messageList[i]._id == messageId) {
                                this.messageList.splice(i, 1, msgData)
                                found = true;
                            } else if (moment(this.messageList[i].createdOn).isSameOrAfter(messageContent
                                    .createdOn)) {
                                this.messageList.splice(i, 0, msgData)
                                found = true;
                            }
                            i++;
                        } while (i < this.messageList.length && !found);

                        if (!found) {
                            this.messageList.push(msgData);
                        }

                        messageScrollBar.update();
                        const index = this.messageList.findIndex(e => e._id == msgData._id)

                        if (this.messageList.length == index + 1) {
                            setTimeout(() => {
                                messageScrollBar.scrollTo(0, messageScrollBar.getSize().content.height, 0);
                                messageScrollBar.addListener(this.messageScrollBarListener);
                            })
                        }
                    }
                } else {
                    var self = this;
                    firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV +
                        '/messages/' +
                        contactId).off(
                        "child_changed",
                        self.updateMessageFromFb);
                    firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV +
                            '/messages/' +
                            contactId)
                        .orderByChild(
                            "createdOn").limitToLast(1).off("child_added", self.updateMessageFromFb);
                    messageListnerList.splice(messageListnerList.indexOf(contactId), 1);
                }
            },
            messageScrollBarListener: function (status) {
                if (this.msgUiFlag.hasMoreData && status.offset.y == 0) {
                    messageScrollBar.removeListener(this.messageScrollBarListener);
                    this.loadMoreMessages(true);
                }
                if (this.msgUiFlag.hasMoreDataNext && status.limit.y == status.offset.y) {
                    messageScrollBar.removeListener(this.messageScrollBarListener);
                    this.loadMoreMessages(false);
                }
            },
            contactScrollBarListener: function (status) {
                if (this.contactUiFlag.hasMoreData && status.limit.y == status.offset.y) {
                    const self = this
                    setTimeout(async () => await self.loadMoreContacts(), 300);
                } else {
                    if (this.msgUiFlag.globalHasMoreData && status.limit.y == status.offset.y) {
                        const self = this
                        setTimeout(async () => await self.loadMoreGlobalMessage(), 300);
                    }
                }
            },
            chatItemStyle: function (url, channel) {
                if (url) {
                    if (channel == 'cust') {
                        return 'box bg-light-inverse wrapp-chat-img';
                    } else {
                        return 'box bg-light-info wrapp-chat-img';
                    }
                } else {
                    if (channel == 'op') {
                        return 'box bg-light-info';
                    } else {
                        return 'box bg-light-inverse';
                    }
                }
            },
            showAddContactModal: function (event) {
                this.$bvModal.show('add-to-contact-modal')
                event.preventDefault()
            },
            saveContactCallback: function (isSuccess, newContactId, data) {
                if (isSuccess) {
                    const index = this.contactList.findIndex(e => e.id == data.primaryPhoneNumber);

                    this.contactList[index] = {
                        ...this.contactList[index],
                        id: newContactId,
                        name: data.name,
                        primaryPhoneNumber: data.primaryPhoneNumber
                    }

                    this.$router.push({
                        params: {
                            id: encodeURIComponent(newContactId)
                        },
                        query: this.pageQuery
                    })
                }
            },
            clearChatPanel: function () {
                messageScrollBar.removeListener(this.messageScrollBarListener);
                this.messageList = [];
                this.contactUiFlag.selectedContactId = '';
                this.contactUiFlag.selectedContactName = '';
                this.contactUiFlag.selectedContactPrimaryNumber = '';
                this.contactUiFlag.selectedContactDefaultChannel = '';
                this.contactUiFlag.selectedIsUnknonwnContact = '';
            },
            selectChat: async function (contactId) {
                messageScrollBar.removeListener(this.messageScrollBarListener);

                if (this.onClickClose) {
                    this.showReOpenBtn = true;
                }
                if (this.onClickOpen) {
                    this.showTextArea = true;
                }

                const contact = this.contactList.find(e => e.id == contactId)

                this.messageList = [];
                this.contactUiFlag.selectedContactName = '';
                this.contactUiFlag.selectedSessionId = contact ? contact.sessionId : '';
                this.contactUiFlag.labels = contact ? contact.labels : ''; 
                this.contactUiFlag.selectedProfilePicUrl = contact ? contact.profilePicUrl : ''; 

                if(contact) {
                    this.contactUiFlag.selectedStars = contact.stars ? contact.stars : false
                    this.contactUiFlag.isAffiliator = contact.isAffiliator ? contact.isAffiliator : false
                    this.contactUiFlag.currentAffiliator = contact.currentAffiliator ? contact.currentAffiliator : null
                }

                this.contactUiFlag.selectedContactId = contactId;
                this.msgUiFlag.currentPage = 1;
                this.msgUiFlag.hasMoreData = true;

                var itemToUiFlag = function (item) {
                    var retval = item;
                    retval.selectedContactName = item.name;
                    retval.selectedIsUnknonwnContact = item.unknownContact;
                    var tmp = item.phoneNumbers || [];
                    retval.selectedContactChannel = tmp.map(item => {
                        return {
                            channel: 'SMS',
                            number: item
                        }
                    });
                    tmp = item.waNumbers || [];
                    retval.selectedContactChannel.splice(0, 0, ...tmp.map(item => {
                        return {
                            channel: 'WA',
                            number: item
                        }
                    }));
                    retval.selectedContactPrimaryNumber = item.primaryPhoneNumber == null ? retval
                        .selectedContactChannel[0].number : item.primaryPhoneNumber;
                    retval.selectedContactDefaultChannel = item.defaultChannel == null ? 'SMS' : item
                        .defaultChannel;

                    return retval;
                }

                if (contactId != null) {
                    var found = false;
                    this.contactList = this.contactList.map((item) => {
                        if (item.id == contactId) {
                            this.contactUiFlag.selectedContactName = item.name;
                            this.contactUiFlag.selectedContactRole = item.customerRole;
                            this.contactUiFlag.selectedIsUnknonwnContact = item.unknownContact;
                            this.contactUiFlag = {
                                ...this.contactUiFlag,
                                ...itemToUiFlag(item)
                            }
                            this.loadMessage(contactId);
                            item.selected = true;
                            found = true;
                        } else {
                            item.selected = false;
                        }
                        return item;
                    });

                    if (!found) {
                        this.msgUiFlag.error = {}
                        try {
                            var resp = await axios.get(process.env.VUE_APP_API_URL + '/contact/idcommunicator/' +
                                contactId);
                            var jsonData = resp.data;
                            if (jsonData.currentOperatorId != null) {
                                this.contactUiFlag.selectedContactName = jsonData.name;
                                this.contactUiFlag.selectedIsUnknonwnContact = jsonData
                                    .unknownContact;
                            }
                            this.contactUiFlag = {
                                ...this.contactUiFlag,
                                ...itemToUiFlag(jsonData)
                            }
                            this.loadMessage(contactId);
                        } catch (error) {
                            this.msgUiFlag.error = this.$helpers.createError(error)
                        }
                    }
                }
            },
            takeConversation: function (event) {
                this.$bvModal.show("take-conversation-dlg");
                event.preventDefault();
            },
            transferConversation: function (event, toUserId, toUserName) {
                event.preventDefault();
                this.$bvModal.show("transfer-conversation-dlg");

                this.assignToData.operatorId = toUserId;
                this.assignToData.operatorName = toUserName;
            },
            loadInitialData: async function () {
                removeContactListener = firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env
                        .VUE_APP_ENV +
                        '/contact/')
                    .on(
                        "child_removed",
                        function (oldChildSnapshot) {
                            this.contactList.splice(this.contactList.findIndex(contact => {
                                return contact.id === oldChildSnapshot.key;
                            }), 1);
                        });
                firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/contact/')
                    .orderByChild(
                        "lastActive")
                    .limitToLast(1).on("child_added", this.updateContactListener);
                firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/contact/').on(
                    "child_changed",
                    this.updateContactListener);

                if (this.$route.query.status == null) {
                    this.pageQuery.status = 'open';
                } else {
                    this.pageQuery.status = this.$route.query.status;
                }

                if (this.$route.query.operatorId != null) {
                    const operatorId = this.$route.query.operatorId
                    this.pageQuery.operatorId = operatorId
                    this.customFilterUiFlag.operatorId = operatorId
                    this.selectedOperatorId = operatorId
                }

                if (this.$route.query.sortOrder) {
                    this.pageQuery.sortOrder = this.$route.query.sortOrder;
                    this.customFilterUiFlag.sortOrder = this.$route.query.sortOrder
                }

                if (this.$route.query.sortDir) {
                    this.pageQuery.sortDir = this.$route.query.sortDir;
                    this.customFilterUiFlag.sortDir = this.$route.query.sortDir
                }

                if (this.$route.query.labels) {
                    this.pageQuery.labels = this.$route.query.labels;
                    this.customFilterUiFlag.labels = this.$route.query.labels.split(',')
                    this.selectedTags = this.$route.query.labels.split(',')
                }

                if (this.$route.query.lastMessagesDirection) {
                    this.pageQuery.lastMessagesDirection = this.$route.query.lastMessagesDirection;
                    this.customFilterUiFlag.lastMessagesDirection = this.$route.query.lastMessagesDirection
                    this.selectedDirection = this.$route.query.lastMessagesDirection
                }

                if (this.$route.query.channel) {
                    this.pageQuery.channel = this.$route.query.channel;
                    this.customFilterUiFlag.channel = this.$route.query.channel
                    this.selectedChannelContact = this.$route.query.channel
                }

                if (this.$route.query.stars == 'true' || this.$route.query.stars == true) {
                    this.pageQuery.stars = true;
                    this.customFilterUiFlag.stars = true
                    this.selectedStars = true
                }

                if (this.$route.params.id != null) {
                    this.selectChat(decodeURIComponent(this.$route.params.id));
                }

                var opListResp = this.initOperatorList()
                var contactResp = this.loadContact()

                await opListResp
                await contactResp
            },
        },
        created: function () {
            this.$emit('update:layout', MainLayout);
        },
        mounted: async function () {
            Scrollbar.initAll();
            messageScrollBar = Scrollbar.get($('.wrapp-chat-content').get()[0]);
            contactScrollBar = Scrollbar.get($('#left-inner-scrollbar').get()[0]);

            var self = this;
            contactScrollBar.addListener((status) => {
                if(this.selectedSearchBy == 'contact') {
                    if (self.contactUiFlag.hasMoreData && status.offset.y == contactScrollBar.limit.y) {
                        setTimeout(() => self.loadMoreContacts(), 300);
                    }
                } else {
                    if (this.msgUiFlag.globalHasMoreData && status.limit.y == status.offset.y) {
                        const self = this
                        setTimeout(async () => await self.loadMoreGlobalMessage(), 300);
                    }
                }
            });
            if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
                this.loadInitialData();
                
                this.actGetCommentCount();
            }
            try {
                mainuisetup();
                commonpagesetup("message", this.$route.params.id);
            } catch (err) {
                console.log(err);
            }

            await this.actGetLabel()
            await this.actGetCustomerRoles()
        },
        destroyed: function () {
            var self = this;
            firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/contact/').off(
                "child_removed",
                removeContactListener);
            firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/contact/').off(
                "child_changed",
                this.updateContactListener);
            firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/contact/')
                .orderByChild(
                    "lastActive")
                .limitToLast(1).off("child_added", this.updateContactListener);

            for (var i = 0; i < messageListnerList.length; i++) {
                firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/messages/' +
                    messageListnerList[
                        i]).off(
                    "child_changed", self.updateMessageFromFb);
                firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/messages/' +
                        messageListnerList[
                            i])
                    .orderByChild("createdOn").limitToLast(1).off("child_added", self.updateMessageFromFb);
            }
            messageListnerList = [];
        },
        components: {
            AddToContact,
            SendImage,
            ErrorMessage,
            Alert,
            CloseChat,
            ReopenChat,
            TransferConversation,
            TakeConversation,
            UpdateNote,
            MessageList,
            ContactList,
            MessageHeader
        }
    }
</script>