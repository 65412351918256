<template>
    <b-dropdown id="dropdown-1" no-caret @show="searchSalesOrder" class="megaDropdown" title="Transaksi">
        <template #button-content>
            <i class="fa fa-shopping-cart"></i>
        </template>
        <div class="wrapp-chat-template wrapp-order-link wrapp-product-link">
            <div class="heading-history-trans">
                <div class="font-weight-bold m-b-10">
                    Riwayat Transaksi
                    <div class="float-right history-trans">
                        <b-dropdown no-caret id="dropdown-12" size="sm" class="mr-3 send-button" title="Status Pembayaran" variant="">
                            <template #button-content>
                                <i class="fa fa-money text-info"></i>
                            </template>
                            <b-dropdown-item @click="paymentStatus = 'PAID_UNVERIFIED'">PAID UNVERIFIED</b-dropdown-item>
                            <b-dropdown-item @click="paymentStatus = 'PAID'">PAID</b-dropdown-item>
                            <b-dropdown-item @click="paymentStatus = 'UNPAID'">UNPAID</b-dropdown-item>
                            <b-dropdown-item @click="paymentStatus = 'FAIL'">FAIL</b-dropdown-item>
                            <b-dropdown-item @click="paymentStatus = 'VALIDATING'">VALIDATING</b-dropdown-item>
                            <b-dropdown-item @click="paymentStatus = 'DRAFT'">DRAFT</b-dropdown-item>
                            <b-dropdown-item @click="paymentStatus = 'EXPIRED'">EXPIRED</b-dropdown-item>
                        </b-dropdown>
                        <b-dropdown no-caret id="dropdown-12" size="sm" class="mr-3 send-button" title="Status Pengiriman" variant="">
                            <template #button-content>
                                <i class="fa fa-truck text-info"></i>
                            </template>
                            <b-dropdown-item @click="shipmentStatus = 'UNSET'">UNSET</b-dropdown-item>
                            <b-dropdown-item @click="shipmentStatus = 'DRAFT'">DRAFT</b-dropdown-item>
                            <b-dropdown-item @click="shipmentStatus = 'PACKING_IN_PROGRESS'">PACKING_IN_PROGRESS</b-dropdown-item>
                            <b-dropdown-item @click="shipmentStatus = 'SHIPPED'">SHIPPED</b-dropdown-item>
                            <b-dropdown-item @click="shipmentStatus = 'BOOKED'">BOOKED</b-dropdown-item>
                        </b-dropdown>
                        <b-dropdown no-caret id="dropdown-12" size="sm" class="send-button" title="Status Pesanan" variant="">
                            <template #button-content>
                                <i class="fa fa-check-square text-secondary text-info"></i>
                            </template>
                            <b-dropdown-item @click="status = 'DRAFT'">Draft</b-dropdown-item>
                            <b-dropdown-item @click="status = 'VOID'">Void</b-dropdown-item>
                            <b-dropdown-item @click="status = 'COMPLETE'">Complete</b-dropdown-item>
                            <b-dropdown-item @click="status = 'BOOKED'">Booked</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <div class="mt-4">
                    <div v-if="parentId && mainAccount" class="badge badge-secondary badge-pill mr-2">
                        <i class="fa fa-user text-light"></i>
                        Akun Utama
                        <v-icon style="font-size:10px !important" class="text-white" right @click.native.capture.stop="mainAccount = false">
                            mdi-close
                        </v-icon>
                    </div>
                    <div v-if="paymentStatus" class="badge badge-secondary badge-pill mr-2">
                        <i class="fa fa-money text-light"></i>
                        {{ paymentStatus }}
                        <v-icon style="font-size:10px !important" class="text-white" right @click.native.capture.stop="paymentStatus = ''">
                            mdi-close
                        </v-icon>
                    </div>
                    <div v-if="shipmentStatus" class="badge badge-secondary badge-pill mr-2">
                        <i class="fa fa-truck text-light"></i>
                        {{ shipmentStatus }}
                        <v-icon style="font-size:10px !important" class="text-white" right @click.native.capture.stop="shipmentStatus = ''">
                            mdi-close
                        </v-icon>
                    </div>
                    <div v-if="status" class="badge badge-secondary badge-pill">
                        <i class="fa fa-check-square text-light"></i>
                        {{ status }}
                        <v-icon style="font-size:10px !important" class="text-white" right @click.native.capture.stop="status = ''">
                            mdi-close
                        </v-icon>
                    </div>
                    <div class="mt-2">
                        <input style="width:80%" class=" pull-left form-control" type="text" placeholder="Masukan Nomor Transaksi"
                            @keyup.enter="searchSalesOrder" v-model="searchTerm" aria-label="Search" />
                        <button class="ml-2 btn-cari btn btn-primary" @click="searchSalesOrder">Cari</button>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div class="show-hidden-prod" v-if="parentId">
                    <input class="mt-1 pull-left" type="checkbox" id="checkbox-1" v-model="mainAccount" name="checkbox-1">
                    <label class="ml-2" for="checkbox-1">Akun Utama ({{ parentName }})</label>
                </div>
            </div>
            <div v-if="isLoading">
                <div class="text-center mt-2">
                    <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                </div>
            </div>
            <div v-if="salesOrderList <= 0 && !isLoading" class="data-null">
                {{ info }}
            </div>
            <div class="wrapp-order-trans" v-if="salesOrderList.length > 0">
                <div class="head-trans-detail font-weight-bold">
                    <span>Total Pesanan: Rp {{ formatCurrency(total) }}</span>
                    <!-- <button class="btn-sm btn btn-primary pull-right" :disabled="total < 1"
                        @click="sendTransactionInfo(null, null, false, true, $event)"
                    >
                        Kirim
                    </button> -->
                    <b-dropdown id="dropdown-12" size="sm" text="Kirim" class="pull-right send-button" 
                        variant="primary" :disabled="total < 1">
                        <b-dropdown-item style="width:300px;">
                            <div class="row">
                                <div class="col-md-9 col-9">
                                    <span class="text-muted pull-left">Rincian Produk</span>
                                </div>
                                <div class="col-md-2 col-2">
                                    <a class="btn btn-primary btn-sm" @click="sendTransactionInfo(null, 'rincian', false, false, $event)">Kirim</a>
                                </div>
                            </div>
                        </b-dropdown-item>
                        <b-dropdown-item style="width:300px;">
                            <div class="row">
                                <div class="col-md-9 col-9">
                                    <span class="text-muted pull-left">Total Pesanan</span>
                                </div>
                                <div class="col-md-2 col-2">
                                    <button class="btn btn-primary btn-sm" @click="sendTransactionInfo(null, null, false, true, $event)">Kirim</button>
                                </div>
                            </div>
                        </b-dropdown-item>
                        <b-dropdown-item style="width:300px;">
                            <div class="row">
                                <div class="col-md-9 col-9">
                                    <span class="text-muted pull-left">No Resi + Kurir</span>
                                </div>
                                <div class="col-md-2 col-2">
                                    <button class="btn btn-primary btn-sm" @click="sendTransactionInfo(null, 'no_resi_1', false, false, $event)">Kirim</button>
                                </div>
                            </div>
                        </b-dropdown-item>
                        <b-dropdown-item style="width:300px;">
                            <div class="row">
                                <div class="col-md-9 col-9">
                                    <span class="text-muted pull-left">No Resi + Kurir + Alamat</span>
                                </div>
                                <div class="col-md-2 col-2">
                                    <button class="btn btn-primary btn-sm" @click="sendTransactionInfo(null, 'no_resi_2', false, false, $event)">Kirim</button>
                                </div>
                            </div>
                        </b-dropdown-item>
                        <b-dropdown-item style="width:300px;">
                            <div class="row">
                                <div class="col-md-9 col-9">
                                    <span class="text-muted pull-left">Total Pesanan Dropship</span>
                                </div>
                                <div class="col-md-2 col-2">
                                    <button class="btn btn-primary btn-sm" @click="sendTransactionInfo(null, 'dropship', false, true, $event)">Kirim</button>
                                </div>
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
            <div class="wrapp-order-trans" v-for="item in salesOrderList" :key="item.id">
                <div class="head-trans-detail font-weight-bold" v-if="item.amount > 0 && item.amount != null">
                    <input type="checkbox" :id="'check-' + item.formalId" class="pull-left mt-1 mr-2" :name="'check-' + item.formalId"
                        @input="calculateAmount(item, $event)" />

                    <a class="text-primary pull-left" @click="getTransactionDetail({...item})">
                        <b>{{item.formalId}}</b>
                    </a>
                    <a icon @click="openDetailCommerce(item)" title="Detail Order Di Commerce">
                        <v-icon class="icon-new-tab" style="font-size:14px !important;">
                            mdi-open-in-new
                        </v-icon>
                    </a>
                    <b-dropdown id="dropdown-12" size="sm" text="Kirim" class="pull-right send-button" variant="primary">
                        <div v-for="(order, index) in quickOrder" :key="index">
                            <b-dropdown-item style="width:300px;" 
                            v-if="item.paymentMethod == 'BANK_TRANSFER' && 
                                    order.title != 'Payment Gateway' && 
                                    order.title != 'Status Pesanan' && 
                                    order.title != 'Status Pembayaran' && 
                                    order.title != 'Status Pengiriman' && 
                                    order.title != 'No Resi + Kurir' && 
                                    order.title != 'No Resi + Kurir + Alamat'">
                                <div class="row">
                                    <div class="col-md-9 col-9">
                                        <span class="text-muted pull-left">{{order.title}}</span>
                                    </div>
                                    <div class="col-md-2 col-2">
                                        <a class="btn btn-primary btn-sm" @click="sendTransactionInfo(item, order.template, false, null, $event)">Kirim</a>
                                    </div>
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-item style="width:300px;" v-if="item.paymentMethod == 'PAYMENT_GATEWAY' && item.paymentGatewayChannel != null">
                                <div class="row">
                                    <div class="col-md-9 col-9">
                                        <span class="text-muted pull-left">{{order.title}}</span>
                                    </div>
                                    <div class="col-md-2 col-2">
                                        <button class="btn btn-primary btn-sm" @click="sendTransactionInfo(item, order.template, false, null, $event)">Kirim</button>
                                    </div>
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-item style="width:300px;" v-if="(item.paymentMethod == null || item.paymentMethod == 'NONE' ) && 
                                order.title != 'Payment Gateway' && 
                                    order.title != 'Status Pesanan' && 
                                    order.title != 'Status Pembayaran' && 
                                    order.title != 'Status Pengiriman' && 
                                    order.title != 'No Resi + Kurir' && 
                                    order.title != 'No Resi + Kurir + Alamat'">
                                <div class="row">
                                    <div class="col-md-9 col-9">
                                        <span
                                            class="text-muted pull-left">{{order.title}}
                                        </span>
                                    </div>
                                    <div class="col-md-2 col-2">
                                        <a class="btn btn-primary btn-sm" @click="sendTransactionInfo(item, order.template, false, null, $event)">Kirim</a>
                                    </div>
                                </div>
                            </b-dropdown-item>
                        </div>
                    </b-dropdown>
                    <div class="clearfix"></div>
    
                    <small class="text-muted">{{ item.creationTime }} | Rp {{ formatCurrency(item.amount) }}</small>
                    <div class="clearfix m-t-10"></div>
                    <small class="light m-r-10 text-muted font-weight-normal">
                        <i class="fa fa-shopping-cart m-r-5"></i> {{ item.status }}
                    </small>
                    <small class="light m-r-10 text-muted font-weight-normal">
                        <i class="fa fa-money m-r-5"></i> {{ item.paymentStatus }}
                    </small>
                    <small class="light m-r-10 text-muted font-weight-normal">
                        <i class="fa fa-truck m-r-5"></i> {{ item.shipmentStatus }}
                    </small>
                    <!-- <small class="light m-r-10 text-muted font-weight-normal">
                        <i class="fa fa-cube m-r-5"></i> Dropship
                    </small> -->


                    <span class="text-primary badge pull-right p-1" 
                        v-if="item.dropshipOriginAddress && (item.dropshipOriginAddress.name || item.dropshipOriginAddress.primaryMobile)"
                    >
                        <span>
                        <i class="fa fa-cube"></i> Dropship
                        </span>
                    </span>
                </div>
                <div v-if="item.showDetail">
                    <div v-if="loadingDetail">
                        <div class="text-center mt-2">
                            <b-spinner small variant="secondary" label="Loading...">
                            </b-spinner>
                        </div>
                    </div>
                    <b-dropdown-item title="" v-if="!loadingDetail">
                        <div class="row" v-for="itemDetail in detail.lines" :key="itemDetail.id">
                            <div class="col-md-3">
                                <div class="wrapp-img-link">
                                    <img :src="itemDetail.displayImage.src" alt="user-img" class="img-responsive"></div>
                            </div>
                            <div class="col-md-7">
                                <span class="product-title-link font-weight-bold">{{ itemDetail.product.name }}</span>
                                <div class="clearfix">{{ itemDetail.product.localSku }}</div>
                                <div class="m-t-10 font-weight-bold"> Rp {{ formatCurrency(itemDetail.product.price) }} </div>
                            </div>
                            <div class="col-md-2">{{ itemDetail.qty }} {{ itemDetail.qty > 1 ? "pcs" : "pc" }}</div>
                        </div>
                    </b-dropdown-item>
                    <div class="footer-trans-detail" v-if="!loadingDetail">
                        <div class="row">
                            <div class="col-md-6">Jumlah</div>
                            <div class="col-md-6 pull-right text-right">
                                {{ detail.totalQty }}
                                {{ detail.totalQty > 1 ? "pcs" : "pc" }}
                            </div>
                            <div class="col-md-6">Berat</div>
                            <div class="col-md-6 pull-right text-right">
                                {{ detail.weight }} kg</div>
                            <div class="col-md-6">Total Diskon</div>
                            <div class="col-md-6 pull-right text-right ">
                                Rp {{ formatCurrency(detail.totalDiscountAmount) }}
                            </div>
                            <div class="col-md-6">Total Pembelanjaan</div>
                            <div class="col-md-6 pull-right text-right ">
                                Rp {{ formatCurrency(detail.amount) }}
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="m-t-10"></div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </b-dropdown>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'

const template = require('lodash.template');
var moment = require('moment');

import ORDER from '../utils/templates/order'

export default {
    props: ['parentId', 'parentName', 'contactId'],

    components: {},

    data: function() {
        return {
            currentPage: 1,
            total: 0,

            info: 'Belum ada transaksi',
            
            status: String(),
            paymentStatus: String(),
            shipmentStatus: String(),
            searchTerm: String(),
            mainAccount: true,

            error: Object(),
            detail: Object(),
            
            checkedOrder: Array(),
            salesOrderList: Array(),
            quickOrder: Array(),
            
            hasMoreDataNext: true,
            loadingDetail: false,
            isLoading: false,

            rules: {
                required: [(v) => !!v || ""],
                requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                requiredArray: [(v) => v.length > 0 || ""],
            },
        }
    },

    computed: {
        ...mapState({
            commerce: state => state.session.commerce
        }),
    },

    methods: {
        openDetailCommerce: function(item) {
            window.open(this.commerce.webUrl + '/admin/#/edit-sales/invoice=' + item.formalId);
        },
        calculateAmount: function (item, event) {
            event.preventDefault()

            if (document.getElementById(`check-${item.formalId}`).checked) {
                this.total = this.total + item.amount
                this.checkedOrder = [...this.checkedOrder, {
                    formalId: item.formalId,
                    amount: item.amount,
                    dropshipOriginAddress: item.dropshipOriginAddress
                }]
            } else {
                this.total = this.total - item.amount
                const filtered = this.checkedOrder.filter(e => e.formalId != item.formalId)
                this.checkedOrder = filtered
            }
        },
        searchSalesOrder: async function () {
            this.isLoading = true 

            // get selected orders
            const currSelectedOrders = this.salesOrderList.filter(order => this.checkedOrder.find(ck => ck.formalId == order.formalId))

            try {
                const res = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/sales-order', {
                    params: {
                        formalIds: this.searchTerm,
                        dateType: 'creationTime',
                        status: this.status,
                        paymentStatus: this.paymentStatus,
                        shipmentStatus: this.shipmentStatus,
                        customerId: !this.parentId ? this.contactId : this.mainAccount ? this.parentId : this.contactId,
                        page: 0,
                        size: 100,
                        sort: 'modificationTime,DESC',
                    }
                })

                let tmp = Array()

                // filter
                res.data.content.forEach(data => {
                    const prevOrder = currSelectedOrders.find(prevOrder => prevOrder.formalId == data.formalId)
                    
                    if(!prevOrder) {
                        data.creationTime = moment(data.creationTime).format('DD MMMM YYYY')
                        data.showDetail = false
                        tmp.push(data)
                    }

                })

                this.salesOrderList = [...currSelectedOrders, ...tmp]

                // handle when already checked do search order
                currSelectedOrders.forEach(ck => {
                    setTimeout(() => {
                        document.getElementById(`check-${ck.formalId}`).checked = true
                    }, 100);
                })

                if (tmp.length < 1) this.info = 'Riwayat transaksi tidak ada'
            } catch (error) {
                console.log("error search sales order", error);
            }
            this.isLoading = false
        },
        getTransactionDetailForTemplate: async function(formalId) {
            try {
                const { data } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/sales-order/' + formalId)
                data.amount = this.formatCurrency(data.amount)
                data.discountPriceTier = this.formatCurrency(data.discountPriceTier)
                data.discountProductSpecial = this.formatCurrency(data.discountProductSpecial)
                data.totalDiscountAmount = this.formatCurrency(data.totalDiscountAmount)
                data.shippingDiscountAmount = this.formatCurrency(data.shippingDiscountAmount)
                data.linesAmount = this.formatCurrency(data.linesAmount)
                data.shippingCost = this.formatCurrency(data.shippingCost)
                data.originalShippingCost = this.formatCurrency(data.originalShippingCost)
                data.lines.forEach(e => {
                    e.finalPrice = this.formatCurrency(e.finalPrice)
                    e.price = this.formatCurrency(e.price)
                    e.product.price = this.formatCurrency(e.product.price)
                })

                return data
            } catch (error) {
                console.log('transaction-detail-template', error);
            }
        },
        sendTransactionInfo: async function (item, templates, send, total, event) {
            event.preventDefault();

            try {
                this.$emit('set-loading-message', true)

                let data = {}
                let totalObj = {}
                let gateway = {}
                let listDetail = []
                
                if (total == null) {
                    const detail = await this.getTransactionDetailForTemplate(item.formalId)
                    data = detail
                }

                if(total != null) {
                    const changedOrderlist = [...this.checkedOrder]

                    if(changedOrderlist.length == 1) {
                        if(!total) {
                            const detail = await this.getTransactionDetailForTemplate(changedOrderlist[0].formalId)
                            data = detail
                        }
                        changedOrderlist[0].amount = this.formatCurrency(changedOrderlist[0].amount)
                    } else {
                        let transactionDetailList = Array()
                        for (let i = 0; i < changedOrderlist.length; i++) {
                            const e = changedOrderlist[i];
                            e.amount = this.formatCurrency(e.amount)

                            const detail = await this.getTransactionDetailForTemplate(e.formalId)
                            transactionDetailList.push(detail)
                        }
                        listDetail = transactionDetailList
                    }

                    totalObj = {
                        amount: this.formatCurrency(this.total),
                        orderList: changedOrderlist
                    }
                }


                if (total == true) {
                    if(templates != 'dropship') {
                        templates = `Total pembelanjaan untuk seluruh nota yang telah dibeli yaitu sebesar Rp <%= total.amount%>, dengan rincian masing - masing nota:\n<% listDetail.forEach( e => {%> \n*<%= e.formalId%>* - Rp <%= e.amount%> (<%= e.shipmentInfo && e.shipmentInfo.usingInsurance ? 'Asuransi' : 'Non Asuransi'%>)<% })%>`
                    } else {
                        templates = 'Total pembelanjaan untuk nota dropship yaitu sebesar Rp <%= total.amount%>. dengan rincian sebagai berikut\n<% listDetail.forEach( e => {%>\n*<%= e.formalId%>* <%= e.dropshipOriginAddress ? \'dengan nama pengirim *\' + e.dropshipOriginAddress.name + \'* \' : \'\' %>sebesar *Rp <%= e.amount%>*<%= e.shipmentInfo && e.shipmentInfo.usingInsurance ? \', -Asuransi\' : \', -Non Asuransi\'%><%})%>'
                    }
                }

                if(total == false) {
                    if(templates == 'rincian') {
                        if(totalObj.orderList.length == 1) {
                            templates = 'Total pesanan dengan no <%= item.formalId%> yang telah dibeli yaitu sebesar *Rp <%= item.amount%>* dengan rincian masing - masing product:\n<% item.lines.forEach( e => {%>\n<%= e.product.name%> - <%= e.qty%> <%= e.qty > 1 ? \'pcs\' : \'pc\' %> -<%= e.price != e.finalPrice ? \'  ~Rp \' + e.price + \'~\': \'\'%> (Rp <%= e.finalPrice%>)<%})%>\n\n<%= item.shipmentInfo && item.shipmentInfo.usingInsurance ? \'Pengiriman ini telah terlindungi proteksi asuransi. Terima Kasih\' : \'Pengiriman ini tidak menggunakan asuransi. Jika terdapat kerusakan atau kehilangan barang, untuk barang yang dikirim tanpa menggunakan asuransi akan mendapatkan penggantian maksimal 10x dari biaya pengiriman.\' %>'
                        } else {
                            templates = `Total pembelanjaan untuk seluruh nota yang telah dibeli yaitu sebesar Rp <%= total.amount%> dengan rincian masing - masing nota:`+
                                `<% listDetail.forEach( e => {%>\n\n`+
                                `*<%= e.formalId%>* - Rp <%= e.amount%> (<%= e.shipmentInfo && e.shipmentInfo.usingInsurance ? 'Asuransi' : 'Non Asuransi'%>)`+
                                `\nDengan produk terdiri dari:`+
                                `<% e.lines.forEach( l => {%>\n<%= l.product.name%> - <%= l.qty%> <%= l.qty > 1 ? 'pcs' : 'pc'%> -<%= l.price != l.finalPrice ? '  ~Rp ' + l.price + '~': ''%> (Rp <%= l.finalPrice%>)<% })%>`+
                                `<% })%>`
                        }
                    } else if(templates == 'no_resi_1') {
                        templates = 'Berikut saya lampirkan untuk no resi pesanan kk:\n<% listDetail.forEach( e => {%>\nNo nota *<%= e.formalId%>*<%= e.awbNumber ? \', dengan no Resi *\' + e.awbNumber + \'*\' : \'\'%><%= e.courier != \'NONE\' ? \', dikirim menggunakan kurir *\' + e.courier + \'*\' : \'\'%><%= e.shipmentInfo && e.shipmentInfo.usingInsurance ? \', -Asuransi\' : \', -Non Asuransi\'%><% })%>'
                    } else {
                        templates = 'Berikut saya lampirkan untuk no resi pesanan kk:\n<% listDetail.forEach( e => {%>\nNo nota *<%= e.formalId%>*<%= e.awbNumber ? \', dengan no Resi *\' + e.awbNumber + \'*\' : \'\'%><%= e.shipmentInfo && e.shipmentInfo.usingInsurance ? \', -Asuransi\' : \', -Non Asuransi\'%>\n<%= e.courier != \'NONE\' ? \'Dikirim menggunakan kurir *\' + e.courier + \'* \'  : \'\'%><% if(e.shippingAddress) {%>Alamat *<%= e.shippingAddress.street ? e.shippingAddress.street + \', \' : \'\'%><%= e.shippingAddress.district%>, <%= e.shippingAddress.city%>, <%= e.shippingAddress.province%>. <%= e.shippingAddress.postalCode%>*<% }})%>'
                    }
                }

                if(listDetail.length > 0) {
                    let orderInsurance = listDetail.length

                    listDetail.forEach(e => {
                        if(e.shipmentInfo && e.shipmentInfo.usingInsurance) orderInsurance -= 1
                    })
                
                    if(orderInsurance > 0) {
                        templates += `\n\nTerdapat ${orderInsurance} pengiriman yang tidak menggunakan asuransi. Jika terdapat kerusakan atau kehilangan barang, untuk barang yang dikirim tanpa menggunakan asuransi akan mendapatkan penggantian maksimal 10x dari biaya pengiriman`
                    }
                }


                if (data.paymentMethod == 'PAYMENT_GATEWAY' && data.paymentGatewayChannel) {
                    const res = await axios.get(process.env.VUE_APP_API_URL + `/cmrc/sales-payment/${data.id}?channel=${data.paymentGatewayChannel}`)
                    gateway = res.data
                }

                const str = template(templates)({
                    'item': data,
                    'total': totalObj,
                    'gateway': gateway,
                    'listDetail': listDetail,
                })

                if (send) {
                    this.$emit('send-info-order', str)
                } else {
                    this.$emit('copy-info-order', str)
                }

                if(this.checkedOrder.length > 0) {
                    this.checkedOrder.forEach(item => {
                        document.getElementById(`check-${item.formalId}`).checked = false
                    })

                    this.total = 0
                    this.checkedOrder = Array()
                }

                this.searchTerm = String()

                this.$emit('set-loading-message', false)
            } catch (error) {
                this.$emit('set-loading-message', false)
                console.log("send-info", error);
            }
        },
        getTransactionDetail: async function (item) {
            this.loadingDetail = true

            try {
                const idx = this.salesOrderList.findIndex(e => e.formalId == this.detail.formalId)
                if(idx >= 0) this.salesOrderList[idx].showDetail = false

                const index = this.salesOrderList.findIndex(e => e.formalId == item.formalId)
                if(index >= 0) {
                    if (this.detail.formalId == item.formalId) {
                        this.salesOrderList[index].showDetail = false
                    } else {
                        this.salesOrderList[index].showDetail = true
                    }
                }

                let detail = Object()
                if (Object.keys(this.detail).length === 0) {
                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/sales-order/' + item.formalId)
                    detail = data
                }
                if (this.detail.formalId != item.formalId) {
                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/sales-order/' + item.formalId)
                    detail = data
                }
                if (this.detail.formalId == item.formalId) detail = this.detail

                this.detail = detail
            } catch (error) {
                console.log("get-detail-transaction", error);
            }

            this.loadingDetail = false
        },
        formatCurrency(price) {
            let val = (price / 1).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
    },

    watch: {
        parentId: function(val) {
            this.mainAccount = val ? true : false
        },
    },

    mounted: function() {
        this.quickOrder = ORDER
    },
}

</script>
