var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card card m-b-0"},[_c('div',{staticClass:"chat-main-box"},[_c('div',{staticClass:"chat-right-aside layer-opacity",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"chat-main-header"}),_c('div',{staticClass:"chat-rbox static-content"},[_c('div',{staticClass:"wrapp-chat-content",staticStyle:{"overflow":"hidden","outline":"none"},attrs:{"data-scrollbar":"true","tabindex":"1"}},[_c('div',{staticClass:"scroll-content"},[_c('div',{staticClass:"contact-add"},[_c('form',{staticClass:"form-horizontal form-material"},[_vm._v(" Daftar Broadcast "),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"wrapp-input-contact m-t-20"},[(!_vm.showAddAutoTemplate)?_c('div',{staticClass:"list-auto-template"},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('b-form-input',{attrs:{"placeholder":"Cari kode/nama"},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v("Cari")])],1)],1),_vm._m(0)],1):_vm._e(),(_vm.showAddAutoTemplate)?_c('div',{staticClass:"add-auto-template"},[_vm._v(" Auto Template "),_c('v-switch',{staticClass:"pull-right m-t-0",attrs:{"label":"Aktif"}}),_c('div',{staticClass:"clearfix"}),_c('br'),_vm._m(1),_vm._m(2),_vm._m(3),_c('br'),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-12 col-lg-3 text-right control-label col-form-label",attrs:{"for":"f-user-full-name"}}),_c('div',{staticClass:"col-sm-12 col-lg-8"},[_c('a',{staticClass:"btn btn-primary m-r-10",on:{"click":function($event){_vm.showAddAutoTemplate = false}}},[_vm._v(" Simpan ")]),_c('a',{staticClass:"btn btn-light",on:{"click":function($event){_vm.showAddAutoTemplate = false}}},[_vm._v(" Batal ")])])])],1):_vm._e()])])])]),_vm._m(4),_vm._m(5)])])])])])]),_c('SuccessNotif')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-5"},[_c('table',{staticClass:"table table-bordered text-center"},[_c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"2","scope":"col"}},[_vm._v(" Kode ")]),_c('th',{attrs:{"rowspan":"2","scope":"col"}},[_vm._v(" Nama ")]),_c('th',{attrs:{"colspan":"5"}},[_vm._v(" Jumlah ")])]),_c('tr',[_c('th',[_vm._v("Grup")]),_c('th',[_vm._v("Kontak")]),_c('th',[_vm._v("Pesan Terkirim")]),_c('th',[_vm._v("Pesan Terbaca")]),_c('th',[_vm._v("Pesan Tidak Terbaca")])]),_c('tr')]),_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Lorem Ipsum")]),_c('td',[_vm._v("20")]),_c('td',[_vm._v("10")]),_c('td',[_vm._v("10")]),_c('td',[_vm._v("10")]),_c('td',[_vm._v("10")]),_c('td',[_vm._v("10")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-12 col-lg-3 text-right control-label col-form-label",attrs:{"for":"f-password"}},[_vm._v(" Kategori")]),_c('div',{staticClass:"col-sm-12 col-lg-6 m-t-0 p-t-0"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"f-user-full-name","placeholder":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-12 col-lg-3 text-right control-label col-form-label",attrs:{"for":"f-user-full-name"}},[_vm._v(" Judul")]),_c('div',{staticClass:"col-sm-12 col-lg-6 m-t-0 p-t-0"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"f-user-full-name","placeholder":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-12 col-lg-3 text-right control-label col-form-label",attrs:{"for":"f-user-full-name"}},[_vm._v(" Deskripsi")]),_c('div',{staticClass:"col-sm-12 col-lg-8"},[_c('textarea',{staticClass:"form-control",attrs:{"id":"exampleFormControlTextarea1","rows":"3"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scrollbar-track scrollbar-track-x",staticStyle:{"display":"none"}},[_c('div',{staticClass:"scrollbar-thumb scrollbar-thumb-x",staticStyle:{"width":"661px","transform":"translate3d(0px, 0px, 0px)"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scrollbar-track scrollbar-track-y",staticStyle:{"display":"block"}},[_c('div',{staticClass:"scrollbar-thumb scrollbar-thumb-y",staticStyle:{"height":"445.289px","transform":"translate3d(0px, 0px, 0px)"}})])
}]

export { render, staticRenderFns }