<template>
    <b-dropdown no-caret @show="searchAttachment" title="Media" class="dropup-select megaDropdown" >
        <template v-slot:button-content>
            <i class="fa fa-folder-open"></i>
        </template>
        <div id='fileAttachment' class="wrapp-chat-template wrapp-order-link wrapp-product-link">
            <div class="heading-history-trans">
                <div class="font-weight-bold mb-4">
                    Media
                    <div class="float-right history-trans">
                        <b-dropdown no-caret id="dropdown-12" size="sm" class="send-button" title="Status Pembayaran" variant="">
                            <template #button-content>
                                <span class="text-primary">
                                    {{ sort == 'lastUsedOn' ? 'Terakhir Digunakan' : sort == 'createdOn' ? 'Terakhir Dikirim' : sort == 'fileName' ? 'Nama' : 'Keterangan'}}
                                </span>
                            </template>
                            <b-dropdown-item @click="sort = 'lastUsedOn', sortDir = 'desc'">Terakhir Digunakan</b-dropdown-item>
                            <b-dropdown-item @click="sort = 'createdOn', sortDir = 'desc'">Tanggal Kirim</b-dropdown-item>
                            <b-dropdown-item @click="sort = 'fileName', sortDir = 'asc'">Nama</b-dropdown-item>
                            <b-dropdown-item @click="sort = 'title', sortDir = 'asc'">Keterangan</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <input style="width:80%" class="pull-left form-control" type="text" placeholder="nama file" @keyup.enter="searchAttachment"
                    v-model="searchTerm" aria-label="Search" />
                <button class="ml-2 btn-cari btn btn-primary" @click="searchAttachment">Cari</button>
                <div class="clearfix"></div>
            </div>
            <div v-if="isLoading">
                <div class="text-center mt-2">
                    <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                </div>
            </div>
            <div class="wrapp-order-trans p-15" v-if="!isLoading">
                <b-tabs content-class="mt-3" v-model="tabAttachment">
                    <div v-if="attachmentList <= 0 && !isLoading" class="data-null">
                        {{ info }}
                    </div>
                    <b-tab title="Foto">
                        <div class="row">
                            <div class="col-md-12 file-lib">
                                <div class="list-img-upload" style="">
                                    <div class="card card-img" v-for="e in attachmentList" :key="e._id" title="gambar">
                                        <img class="images-preview-modal img-responsive" :src="e.url" alt="attachment" width="100">
                                        <div class="wrap-action-img">
                                            &nbsp;
                                            <a class="send-img mr-2" @click="sendAttachment(e._id, $event)">
                                                <i class="fa fa-paper-plane" title="Kirim"></i>
                                            </a>
                                            <a class="send-img" v-b-tooltip.click.bottom="'url copied'" @click="copyLinkAttachment(e.url, $event)">
                                                <i class="fa fa-file" title="Copy Link"></i>
                                            </a>
                                            <a class="delete-link" @click="deleteAttachment(e._id, $event)">
                                                <i class="fa fa-close" title="Hapus"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Dokumen">
                        <div class="list-doc" v-for="e in attachmentList" :key="e._id">
                            <div class="row">
                                <div class="col-md-1 col-1">
                                    <span class="icon-ext">
                                        <i v-if="e.extension == 'pdf'" class="mdi mdi-file-pdf-box"></i>
                                        <i v-else-if="e.extension == 'xlsx' || e.extension == 'xls' || e.extension == 'ods'" class="mdi mdi-file-excel"></i>
                                        <i v-else-if="e.extension == 'docx' || e.extension == 'doc' || e.extension == 'odt'" class="mdi mdi-file-word"></i>
                                        <i v-else-if="e.extension == 'pptx' || e.extension == 'ppt' || e.extension == 'odp'" class="mdi mdi-file-powerpoint"></i>
                                        <i v-else-if="e.extension == 'zip' || e.extension == 'rar'" class="mdi mdi-folder-zip"></i>
                                        <i v-else class="mdi mdi-file"></i>
                                    </span>
                                </div>
                                <div class="col-md-7 col-7">
                                    <div class="wrapp-title-doc">{{e.fileName}}</div>
                                    <div class="clearfix"></div>
                                    <div class="row">
                                        <div class="col-md-6 pb-0 pt-0 col-6">
                                            <small class="text-left  text-uppercase pull-left text-muted">{{e.extension}}</small>
                                        </div>
                                        <div class="col-md-6 pb-0 pt-0 col-6">
                                            <small class="text-right text-muted pull-right">{{e.time}}</small>
                                        </div>
                                        <div class="col-md-12 pt-0 col-12">
                                            <small class="title-doc text-muted ">{{e.title}}</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-4">
                                    <div class="text-right pull-right">
                                        <a class="mr-2" @click="sendAttachment(e._id, $event)">
                                            <i class="fa fa-paper-plane" title="Kirim"></i>
                                        </a>
                                        <a class="mr-2" v-b-tooltip.click.focus.bottom="'url copied'" @click="copyLinkAttachment(e.url, $event)">
                                            <i class="fa fa-file" title="Copy Text"></i>
                                        </a>
                                        <a @click="deleteAttachment(e._id, $event)">
                                            <i class="mdi mdi-delete" title="Hapus"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Video">
                        <div class="list-doc" v-for="e in attachmentList" :key="e._id">
                            <div class="row">
                                <div class="col-md-1">
                                    <span class="icon-ext">
                                        <i class="mdi mdi-file-video"></i>
                                    </span>
                                </div>
                                <div class="col-md-7">
                                    <div class="wrapp-title-doc">{{e.fileName}}</div>
                                    <div class="clearfix"></div>
                                    <div class="row">
                                        <div class="col-md-6 pb-0 pt-0">
                                            <small class="text-left  text-uppercase pull-left text-muted">{{e.extension}}</small>
                                        </div>
                                        <div class="col-md-6 pb-0 pt-0">
                                            <small class="text-right text-muted pull-right">{{e.time}}</small>
                                        </div>
                                        <div class="col-md-12 pt-0">
                                            <small class="title-doc text-muted ">{{e.title}}</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="text-right pull-right">
                                        <a class="mr-2" @click="sendAttachment(e._id, $event)">
                                            <i class="fa fa-paper-plane" title="Kirim"></i>
                                        </a>
                                        <a class="mr-2" v-b-tooltip.click.focus.bottom="'url copied'" @click="copyLinkAttachment(e.url, $event)">
                                            <i class="fa fa-file" title="Copy Text"></i>
                                        </a>
                                        <a @click="deleteAttachment(e._id, $event)">
                                            <i class="mdi mdi-delete" title="Hapus"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </b-dropdown>
</template>
<script>
import axios from 'axios'

const uuidv4 = require('uuid/v4');
const { toFriendlyDateTime } = require('../utils/date-utils');
var moment = require('moment');

export default {
    props: ['channelId', 'channelDefault', 'channelName', 'contactId', 'contactNumber'],

    components: {},

    data: function() {
        return {
            tabAttachment: 0,
            currentPage: 1,
            fileType: 'image',
            sort: 'lastUsedOn',
            sortDir: 'desc',
            info: 'Belum ada attachment',

            searchTerm: String(),

            error: Object(),

            attachmentList: Array(),
            
            hasMoreDataNext: true,
            isLoading: false,

            rules: {
                required: [(v) => !!v || ""],
                requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                requiredArray: [(v) => v.length > 0 || ""],
            },
        }
    },

    methods: {
        deleteAttachment: async function (attachmentId, event) {
            event.preventDefault();

            try {
                await axios.delete(process.env.VUE_APP_API_URL + '/attachment/' + attachmentId)

                const index = this.attachmentList.map(e => e._id).indexOf(attachmentId)
                this.attachmentList.splice(index, 1)
            } catch (error) {
                console.log("error get customer role", error);
            }
        },
        searchAttachment: async function () {
            this.isLoading = true
            try {
                const {
                    data
                } = await axios.get(process.env.VUE_APP_API_URL + '/attachment', {
                    params: {
                        fileType: this.fileType,
                        sort: this.sort,
                        sortDir: this.sortDir,
                        term: this.searchTerm,
                        page: this.currentPage,
                        size: 25
                    }
                })

                const temp = data.content.map(e => {
                    const splitedFilename = e.fileName ? e.fileName.split('.') : []
                    e.time = moment(e.createdOn).format('L')
                    e.extension = splitedFilename[splitedFilename.length - 1]
                    return e
                })

                // if(this.sort == 'lastUsedOn' || this.sort == 'createdOn') temp.reverse()

                this.attachmentList = temp
            } catch (error) {
                console.log("error get customer role", error);
            }
            this.isLoading = false
        },
        copyLinkAttachment: function (url, event) {
            event.preventDefault();

            navigator.clipboard.writeText(url);
        },
        sendAttachment: async function (attachmentId, event) {
            event.preventDefault();
   
            let postData = {
                "id": uuidv4(),
                "channelId": this.channelId,
                "number": this.contactNumber
            };

            // if (this.channelDefault == 'FB') {
            //     postData.contactFbId = this.contactNumber
            // } else if (this.channelDefault == 'SMS' || this.channelDefault == 'WA') {
            //     postData.number = this.contactNumber
            // }

            const createdOn = (new Date()).getTime();

            let msgData = {
                _id: postData.id,
                channel: this.channelDefault,
                contactId: this.contactId,
                createdOn: createdOn,
                contentType: 'text',
                time: toFriendlyDateTime(createdOn),
                from: 'op',
                text: postData.text ? this.formatTabMessages(postData.text) : postData.text,
                sentTo: this.contactNumber,
                status: 'pending',
                operatorId: global.userId,
                operatorName: global.userFullName,
                channelName: this.channelName
            }

            this.$emit('attachment-callback', msgData)

            try {
                var { data } = await axios.post(process.env.VUE_APP_API_URL + '/attachment/' + this.contactId + '/' + this.channelDefault + '/' + attachmentId, postData);

                this.$emit('attachment-callback-api', msgData, data.externalId, null)
            } catch (error) {
                this.$emit('attachment-callback-api', null, null, error)
                console.log('send-attachment', error);
            }
        },
        formatTabMessages(message) {
            let temp = message

            // check url
            if (this.stringIsUrl(message)) {
                let checkWhiteSpace = []
                let checkNewLine = []

                temp.split(" ").forEach(e => {
                    if (this.stringIsUrl(e) && e.indexOf('\n') == -1) {
                        checkWhiteSpace.push(`<a href='${e}' target='_blank'>${e}</a>`)
                    } else {
                        checkWhiteSpace.push(e)
                    }
                })

                checkWhiteSpace.join(' ').split("\n").forEach(e => {
                    if (this.stringIsUrl(e) && e.indexOf(' ') == -1) {
                        checkNewLine.push(`<a href='${e}' target='_blank'>${e}</a>`)
                    } else {
                        checkNewLine.push(e)
                    }
                })

                temp = checkNewLine.join('\n')
            }

            let current = true

            // check bold
            const isBold = temp.split('').includes('*')
            if (isBold) {
                temp = temp.split('').map(e => {
                    if (e == '*') {
                        if (current) {
                            current = false
                            return '<span style="font-weight: bold;">'
                        } else {
                            current = true
                            return '</span>'
                        }
                    }

                    return e
                }).join('')
            }

            return temp ? temp.split('\n').join('<br />') : temp
        },
    },

    watch: {
        tabAttachment: function (val) {
            if (val == 0) {
                this.fileType = 'image'
            } else if (val == 1) {
                this.fileType = 'document'
            } else if (val == 2) {
                this.fileType = 'video'
            }
            this.searchAttachment();
        },
    },
}

</script>
