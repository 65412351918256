<template>
    <div class="row">
        <div class="col-12">
            <div class="card card m-b-0">
                <!-- .chat-row -->
                <div class="chat-main-box">
                    <div class="chat-right-aside layer-opacity" style="width:100%">
                        <div class="chat-main-header"></div>
                        <div class="chat-rbox static-content">
                            <div class="wrapp-chat-content" data-scrollbar="true" tabindex="1" style="overflow: hidden; outline: none; height:90vh;">
                                <div class="scroll-content">
                                    <div class="wrapp-dashboard">
                                        Selamat Datang Admin
                                        <div class="form-group row m-t-20">
                                            <div class="col-sm-12 col-lg-12">
                                                <div class="form-horizontal form-material">
                                                    <div class="wrapp-input-contact min-height">
                                                        <v-simple-table>
                                                            <template v-slot:default>
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-left">Hari</th>
                                                                        <th class="text-left">Jam Mulai Kerja</th>
                                                                        <th class="text-left">Jam Selesai Kerja</th>
                                                                        <th class="text-left">Jam Mulai Istirahat</th>
                                                                        <th class="text-left">Jam Selesai Istirahat</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="item in presence" :key="item.id">
                                                                        <td>{{ item.day }}</td>
                                                                        <td>{{ item.workStart }}</td>
                                                                        <td>{{ item.workEnd }}</td>
                                                                        <td>{{ item.restStart }}</td>
                                                                        <td>{{ item.restEnd }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </template>
                                                        </v-simple-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                    <div class="scrollbar-thumb scrollbar-thumb-x"
                                        style="width: 661px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                    <div class="scrollbar-thumb scrollbar-thumb-y"
                                        style="height: 445.289px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SuccessNotif />
    </div>
</template>
<script>
    import axios from 'axios'
    import Scrollbar from 'smooth-scrollbar'
    // import ErrorMessage from '../components/ErrorMessage'
    import MainLayout from "../layouts/MainLayout"
    // import SuccessNotif from "../components/SuccessNotif"
    import {
        mapActions,
        mapState
    } from 'vuex'
    // import countryCodes from "../utils/country-codes"
    // import LineChart from "../components/LineChart"

    const $ = require("jquery")
    // const debounce = require('lodash.debounce')
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup')

    // const USER_ROW_PER_PAGE = 25
    // var userScrollBar = null

    var moment = require('moment');
    
    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            })
        },
        data: function () {
            return {
                presence: Array(),

                rules: {
                    required: [(v) => !!v || ""],
                    requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                    requiredArray: [(v) => v.length > 0 || ""],
                },
            }
        },
        watch: {},

        methods: {
            ...mapActions({
                initOperatorList: 'operator/initOperatorList'
            }),

            getPresence: async function() {
                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/presence')
                    
                    this.presence = data 
                } catch (error) {
                    console.log(error);
                }
            },

            utcToLocal: function(date, format) {
                var utcLocal = moment(date).local().format(format);
                return utcLocal;
            },

            utcToLocalView: function(date, format) {
                var utcLocal = moment(date).format(format);
                return utcLocal;
            },

            localToUtc: function(date, format) {
                var utcLocal = moment(date).utc().format(format);
                return utcLocal;
            },
        },

        created: function () {
            this.$emit('update:layout', MainLayout);
        },

        mounted: async function () {
            Scrollbar.initAll();
            Scrollbar.get($('#left-inner-scrollbar').get()[0]);

            // var self = this;
            // userScrollBar.addListener((status) => {
            //     if (this.commChannelUIFlag.hasMoreData && status.offset.y == userScrollBar.limit.y) {
            //         setTimeout(() => self.loadMoreUsers(), 500);
            //     }
            // });

            // if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
            //     await this.loadInitialData();
            // }

            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }

            await this.initOperatorList()

            await this.getPresence()
        },

        components: {

        }
    }
</script>