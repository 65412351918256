<template>
    <div>
        <layout-wrapper>
            {{ showPage == 'list' ? 'Daftar' : showPage == 'add' ? 'Tambah' : showPage == 'edit' ? 'Edit' : 'Statistik' }}
            Broadcast
            <router-link :to="{ name: 'broadcast' , query: { tabs: 'add' } }" v-if="showPage == 'list'">
                <v-btn class="btn btn-primary pull-right text-white btn btn-primary">
                    Buat Broadcast
                </v-btn>
            </router-link>
            <div class="clearfix"></div>
            <br>
            <div class="clearfix"></div>
            <div class="wrapp-input-contact m-t-20">
                <template v-if="showPage == 'list'">
                    <div class="list-auto-template">
                        <v-row>
                            <v-col cols="6" md="3">
                                <v-text-field class="" label="Nama / Kode" v-model="term" />
                            </v-col>
                            <v-col cols="6" md="3" class="mt-4">
                                <v-menu ref="startDate" v-model="isOpenStartDate" :close-on-content-click="false"
                                    :nudge-right="40" :return-value.sync="selectedStartDate"
                                    transition="scale-transition" min-width="290px" offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            :value="selectedStartDate ? utcToLocal(selectedStartDate,'DD-MM-YYYY') : ''"
                                            class="" label="Tanggal Mulai" readonly dense hide-details v-on="on" />
                                    </template>
                                    <v-date-picker v-model="startDate" no-title scrollable
                                        :max="selectedEndDate ? selectedEndDate : ''"
                                        @input="$refs.startDate.save(startDate)" />
                                </v-menu>
                            </v-col>
                            <v-col cols="6" md="3" class="mt-4 mt-0-mobile pt-0-mobile">
                                <v-menu ref="endDate" v-model="isOpenEndDate" :close-on-content-click="false"
                                    :nudge-right="40" :return-value.sync="selectedEndDate" transition="scale-transition"
                                    min-width="290px" offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-text-field readonly dense hide-details
                                            :value="selectedEndDate ? utcToLocal(selectedEndDate,'DD-MM-YYYY') : ''"
                                            class="" label="Tanggal Selesai" v-on="on" />
                                    </template>
                                    <v-date-picker v-model="endDate" no-title scrollable
                                        :min="selectedStartDate ? selectedStartDate : ''"
                                        @input="$refs.endDate.save(endDate)" />
                                </v-menu>
                            </v-col>
                            <v-col cols="6" md="3" class="mt-0-mobile pt-0-mobile">
                                <v-select class="" v-model="selectedStatus" :items="status" label="Status Broadcast">
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="3" class="mt-0 pt-0 mb-4">
                                <v-btn @click="loadBroadcast" class="mr-2 btn">
                                    Cari
                                </v-btn>
                                <v-btn @click="resetFilter">
                                    Reset
                                </v-btn>
                            </v-col>
                        </v-row>

                        <div class="my-5">
                            <v-data-table :headers="headers" :items="broadcastList" :options.sync="options"
                                :server-items-length="totalElements" class="elevation-1" :loading="isLoading"
                                :footer-props="{ 'items-per-page-options': [5, 10, 15] }">
                                <template v-slot:item.code="{ item }">
                                    <div>
                                        {{ item.messageTemplate.code }}
                                    </div>
                                </template>
                                <template v-slot:item.send="{ item }">
                                    <div v-if="item.deliverySummary">
                                        {{ item.countSend }}
                                        ({{ item.percentSend}}%)
                                    </div>
                                </template>
                                <template v-slot:item.action="{ item }">
                                    <div class="row btn-action" style="min-width:220px">
                                        <b-button @click="openCloneBroadcast(item._id)" title="Salin"
                                            variant="outline-secondary" size="sm" class="btn ml-2"
                                            color="btn float-left" small>Salin

                                        </b-button>
                                        <router-link
                                            :to="{ name: 'broadcast' , params: {id: encodeURIComponent(item._id)}}"
                                            v-if="item.status == 'DRAFT'">
                                            <b-button variant="outline-secondary" size="sm" class="btn ml-2"
                                                color="btn float-left" small>Ubah
                                            </b-button>
                                        </router-link>
                                        <router-link
                                            :to="{ name: 'broadcast' , params: {id: encodeURIComponent(item._id)}, query: {tabs: 'detail'}}"
                                            v-if="item.status != 'DRAFT'">
                                            <b-button variant="outline-secondary" size="sm" class="btn ml-2"
                                                color="btn float-left" small>Detail
                                            </b-button>
                                        </router-link>
                                        <DeleteDialog v-if="item.status == 'DRAFT'" class="ml-2"
                                            :name="'Hapus Broadcast'" :title="'Hapus Broadcast'" :kind="'Broadcast'"
                                            :shape="'icon'" @delete="deleteBroadcast(item._id)" />
                                        <v-menu offset-y v-if="item.status == 'DRAFT'">
                                            <template v-slot:activator="{ on, attrs }">
                                                <b-button v-bind="attrs" v-on="on" variant="outline-secondary" size="sm"
                                                    class="btn ml-2" color="btn float-left" small>Kirim
                                                </b-button>
                                            </template>
                                            <v-list>
                                                <v-list-item>
                                                    <v-list-item-title @click="openTryBroadcastDialog(item._id)">
                                                        <a>Coba Broadcast</a>
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-list-item-title>
                                                        <router-link
                                                            :to="{ name: 'broadcast' , params: {id: encodeURIComponent(item._id)}, query: {tabs: 'detail'}}">
                                                            Kirim Broadcast
                                                        </router-link>
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </template>
                            </v-data-table>
                        </div>
                    </div>
                </template>
                <FormBroadcast :showPage="showPage" :channelList="inputChannelList" />
                <DetailBroadcast :showPage="showPage" :channelList="inputChannelList" />
            </div>
        </layout-wrapper>

        <CopyBroadcastDialog :showDialog="copyBroadcastDialog" :broadcastId="selectedCloneId"
            @close-dialog="closeDialog" @post-callback="postCallback" />
        <SuccessNotif />
        <TestBroadcastDialog :showDialog="testBroadcastDialog" :broadcastId="idBroadcast" @close-dialog="closeDialog" />
    </div>
</template>
<script>
    import axios from 'axios'
    import Scrollbar from 'smooth-scrollbar'
    // import ErrorMessage from '../components/ErrorMessage'
    import MainLayout from "../layouts/MainLayout"
    import SuccessNotif from "../components/SuccessNotif"
    import Toast from '../plugins/vuenotification'
    import {
        mapState
    } from 'vuex'

    // const $ = require("jquery")
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup')
    var moment = require('moment');

    import TestBroadcastDialog from '../components/TestBroadcastDialog.vue'
    import CopyBroadcastDialog from '../components/copyBroadcastDialog.vue'
    import DetailBroadcast from '../components/DetailBroadcast.vue'
    import FormBroadcast from '../components/FormBroadcast.vue'
    import DeleteDialog from '../components/DeleteDialog.vue';
    import LayoutWrapper from '../layouts/components/LayoutWrapper.vue'

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo,
                broadcastCallback: state => state.socket.broadcastCallback,
            })
        },
        data: function () {
            return {
                testBroadcastDialog: false,
                copyBroadcastDialog: false,
                formValid: false,
                formDialogValid: false,
                formTryDialogValid: false,
                selectedCloneId: String(),
                isLoadingForm: false,
                selectedInputContact: Array(),
                isLoadingContact: false,
                inputContactList: Array(),
                searchInputContact: String(),
                selectedStatus: String(),
                title: String(),
                status: ['DRAFT', 'RUNNING', 'PAUSE', 'DONE'],
                isOpenStartDate: false,
                isOpenEndDate: false,
                selectedStartDate: String(),
                selectedEndDate: String(),
                startDate: String(),
                endDate: String(),
                headers: [{
                        text: 'Kode',
                        value: 'code',
                        sortable: false
                    },
                    {
                        text: 'Judul',
                        value: 'title',
                        sortable: false
                    },
                    {
                        text: 'Status',
                        value: 'status',
                        sortable: false
                    },
                    {
                        text: 'Terakhir Ubah',
                        value: 'updateTime',
                        sortable: false
                    },
                    {
                        text: 'Tanggal Kirim',
                        value: 'sendTime',
                        sortable: false
                    },
                    {
                        text: 'Tujuan Kirim',
                        value: 'publishedToContactCount',
                        sortable: false
                    },
                    {
                        text: 'Terkirim',
                        value: 'send',
                        sortable: false
                    },
                    {
                        text: 'Aksi',
                        value: 'action',
                        sortable: false
                    },
                ],
                broadcastList: Array(),
                inputChannelList: Array(),
                totalElements: 0,
                options: Object(),
                isLoading: false,
                showPage: 'list',
                term: String(),
                idBroadcast: String(),
                rules: {
                    required: [(v) => !!v || ""],
                    requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                    requiredArray: [(v) => v.length > 0 || ""],
                },
            }
        },
        watch: {
            options: {
                handler() {
                    this.loadBroadcast();
                },
                deep: true,
            },
            $route: async function (to, from) {
                if (this.$helpers.isBlank(to.params.id)) {
                    if (this.$route.query.tabs == 'add') {
                        this.showPage = 'add'
                    } else {
                        this.showPage = 'list'
                    }
                } else if (to.params.id != from.params.id) {
                    if (this.$route.query.tabs == 'detail') {
                        this.showPage = 'detail'
                    } else {
                        this.showPage = 'edit'
                    }
                }
            },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            },
        },
        methods: {
            postCallback: function (val) {
                if (val) this.options.page = 1
            },
            closeDialog: function (val) {
                if (val == 'test') {
                    this.testBroadcastDialog = false
                } else {
                    this.copyBroadcastDialog = false
                }
            },
            openTryBroadcastDialog: function (id) {
                this.testBroadcastDialog = true
                this.idBroadcast = id
            },
            openCloneBroadcast: function (id) {
                this.copyBroadcastDialog = true
                this.selectedCloneId = id
            },
            resetFilter: async function () {
                this.isOpenStartDate = false,
                    this.isOpenEndDate = false,
                    this.selectedStartDate = String()
                this.selectedEndDate = String()
                this.startDate = String(),
                    this.endDate = String(),
                    this.selectedStatus = String()
                this.term = String()

                await this.loadBroadcast()
            },
            searchContact: async function () {
                this.isLoadingContact = true

                try {
                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/contact', {
                        params: {
                            size: 25,
                            page: 1,
                            term: this.searchInputContact,
                            status: 'ACTIVE',
                        },
                    })

                    this.inputContactList.unshift(...data.content)

                } catch (error) {
                    console.log("search-template");
                }
                this.isLoadingContact = false
            },
            deleteBroadcast: async function (broadcastId) {
                try {
                    await axios.delete(process.env.VUE_APP_API_URL + '/broadcast/' + broadcastId)

                    await this.loadBroadcast()
                } catch (error) {
                    console.log('delete-broadcast', error);
                    Toast.showToast("error", error.response.data.message)
                }
            },
            loadInitialData: async function () {
                if (this.$route.params.id != null) {
                    if (this.$route.query.tabs == 'detail') {
                        this.showPage = 'detail'
                    } else {
                        this.showPage = 'edit'
                    }
                } else {
                    if (this.$route.query.tabs == 'add') {
                        this.showPage = 'add'
                    } else {
                        this.showPage = 'list'
                    }
                }
            },
            loadBroadcast: async function () {
                this.isLoading = true
                let params = Object();
                params.term = this.term
                params.startDate = this.startDate ? moment(this.startDate).format() : String()
                params.endDate = this.startDate ? moment(this.endDate).endOf('day').format() : String()
                params.status = this.selectedStatus
                params.sort = 'lastUpdatedOn'
                params.sortOrder = 'desc'

                if (this.options && this.options.page) params.page = this.options.page;
                if (this.options && this.options.itemsPerPage) params.size = this.options.itemsPerPage;

                try {
                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/broadcast', {
                        params
                    })

                    this.broadcastList = data.content.map(e => {
                        e.sendTime = e.publishedOn ? moment(e.publishedOn).locale('id').format('L') :
                            String()
                        e.updateTime = e.lastUpdatedOn ? moment(e.lastUpdatedOn).locale('id').format('L') :
                            String()
                        if (e.deliverySummary) {
                            const delivered = e.deliverySummary.delivered ? e.deliverySummary.delivered : 0
                            const read = e.deliverySummary.read ? e.deliverySummary.read : 0
                            const sent = e.deliverySummary.sent ? e.deliverySummary.sent : 0
                            const error = e.deliverySummary.error ? e.deliverySummary.error : 0
                            const unsubscribed = e.deliverySummary.unsubscribed ? e.deliverySummary
                                .unsubscribed : 0
                            const total = delivered + read + sent + error + unsubscribed
                            e.countSend = total
                            e.percentSend = Math.ceil(((total) / e.publishedToContactCount) * 100)
                        }
                        return e
                    })

                    this.totalElements = data.page.totalElements
                } catch (error) {
                    console.log("load-broadcast", error);
                }
                this.isLoading = false
            },
            searchChannel: async function () {
                try {
                    var {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/commchannel/', {
                        params: {
                            page: 1,
                            // term: this.searchInputChannel,
                            size: 100
                        }
                    });

                    this.inputChannelList = data.content
                } catch (error) {
                    console.log("search-input-channel", error);
                }
            },
            utcToLocal: function (date, format) {
                var utcLocal = moment(date).local().format(format);
                return utcLocal;
            },
            utcToLocalView: function (date, format) {
                var utcLocal = moment(date).format(format);
                return utcLocal;
            },
            localToUtc: function (date, format) {
                var utcLocal = moment(date).utc().format(format);
                return utcLocal;
            },
        },
        created: function () {
            this.$emit('update:layout', MainLayout);
        },
        mounted: async function () {
            Scrollbar.initAll();
            if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
                await this.loadInitialData();
            }
            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }
            await this.searchChannel()
        },
        components: {
            'layout-wrapper': LayoutWrapper,
            // ErrorMessage,
            SuccessNotif,
            TestBroadcastDialog,
            CopyBroadcastDialog,
            DetailBroadcast,
            FormBroadcast,
            DeleteDialog
        }
    }
</script>