<template>
    <div class="row">
        <div class="col-12">
            <div class="card card m-b-0">
                <!-- .chat-row -->
                <div class="chat-main-box">
                    <div class="chat-right-aside layer-opacity" style="width:100%">
                        <div class="chat-main-header"></div>
                        <div class="chat-rbox static-content">
                            <div class="wrapp-chat-content" data-scrollbar="true" tabindex="1" style="overflow: hidden; outline: none;">
                                <div class="scroll-content">
                                    <div class="wrapp-dashboard">
                                        Komplain
                                        <div class="form-group row m-t-20">
                                            <div class="col-sm-12 col-lg-12">
                                                <v-form v-model="formValid" class="form-horizontal form-material">
                                                    <div class="wrapp-input-contact min-height">
                                                        <v-row>
                                                            <v-col cols="6" md="4">
                                                                <v-select 
                                                                    v-model="selectedFilter" 
                                                                    :items="filterBy" 
                                                                    item-text="text"
                                                                    item-value="value"
                                                                    label="Filter Berdasarkan" 
                                                                    :rules="rules.required">
                                                                </v-select>
                                                            </v-col>
                                                            <v-col cols="6" md="4" 
                                                                class="pt-7" 
                                                                :style="{ 'display': selectedFilter == 'month' ? '' : 'none' }"
                                                            >
                                                                <v-menu offset-y
                                                                    ref="month" 
                                                                    v-model="isOpenMonth" 
                                                                    :close-on-content-click="false" 
                                                                    :nudge-right="40"
                                                                    :return-value.sync="selectedMonth" 
                                                                    transition="scale-transition" 
                                                                    min-width="290px"
                                                                >
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-text-field readonly dense hide-details
                                                                            :value="selectedMonth ? utcToLocal(selectedMonth,'MMMM-YYYY') : ''"
                                                                            class="" 
                                                                            label="Bulan & Tahun" 
                                                                            v-on="on" />
                                                                    </template>
                                                                    <v-date-picker no-title scrollable
                                                                        type="month" 
                                                                        v-model="month" 
                                                                        @input="$refs.month.save(month)" />
                                                                </v-menu>
                                                            </v-col>
                                                            <v-col cols="6" md="3" 
                                                                class="pt-7" 
                                                                :style="{ 'display': selectedFilter == 'day' ? '' : 'none' }"
                                                            >
                                                                <v-menu offset-y
                                                                    ref="startDate" 
                                                                    v-model="isOpenStartDate" 
                                                                    :close-on-content-click="false" 
                                                                    :nudge-right="40"
                                                                    :return-value.sync="selectedStartDate" 
                                                                    transition="scale-transition" 
                                                                    min-width="290px"
                                                                >
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-text-field readonly dense hide-details
                                                                            :value="selectedStartDate ? utcToLocal(selectedStartDate,'DD-MM-YYYY') : ''"
                                                                            label="Tanggal" 
                                                                            v-on="on" />
                                                                    </template>
                                                                    <v-date-picker no-title scrollable
                                                                        v-model="startDate" 
                                                                        :max="maxDateFilter ? maxDateFilter : ''"
                                                                        @input="$refs.startDate.save(startDate)" />
                                                                </v-menu>
                                                            </v-col>
                                                            <v-col cols="12" class="mt-0 pt-0" v-if="selectedFilter">
                                                                <v-btn class="mr-2 text-white btn btn-primary"
                                                                    @click="handleLoad" 
                                                                    :disabled="!formValid" 
                                                                    :loading="isLoading"
                                                                >
                                                                    Cari
                                                                </v-btn>
                                                                <v-btn @click="resetFilter">Reset</v-btn>
                                                            </v-col>
                                                        </v-row>

                                                        <DashboardViewComplain 
                                                            ref="complain"
                                                            :show="show"
                                                            :selectedStartDate="selectedStartDate"
                                                            :selectedMonth="selectedMonth"
                                                            :selectedFilter="selectedFilter" />
                                                            
                                                    </div>
                                                </v-form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                    <div class="scrollbar-thumb scrollbar-thumb-x" style="width: 661px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                    <div class="scrollbar-thumb scrollbar-thumb-y" style="height: 445.289px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SuccessNotif />
    </div>
</template>
<script>
    import MainLayout from "../layouts/MainLayout"
    import SuccessNotif from "../components/SuccessNotif"
    import DashboardViewComplain from '../components/DashboardViewComplain.vue'
    import Scrollbar from 'smooth-scrollbar'

    import { mapState } from 'vuex'

    const { commonpagesetup, mainuisetup } = require('../utils/ui-setup')
    const $ = require("jquery")
    const moment = require('moment');

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            })
        },

        data: function () {
            return {
                selectedFilter: String(),
                selectedStartDate: String(),
                startDate: String(),
                selectedMonth: String(),
                month: String(),
                maxDateFilter: new Date(),

                isOpenStartDate: false,
                isOpenMonth: false,
                formValid: false,
                show: false,
                isLoading: false,

                filterBy: [
                    { text: 'Bulan', value: 'month' },
                    { text: 'Hari', value: 'day' }
                ],

                rules: {
                    required: [(v) => !!v || ""],
                    requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                    requiredArray: [(v) => v.length > 0 || ""],
                },
            }
        },

        watch: {

        },

        methods: {
            handleLoad: async function() {
                this.isLoading = true
                this.show = true

                this.$refs.complain.selectedOperator = String()
                await this.$refs.complain.loadDataComplainByDay()
                
                this.isLoading = false
            },

            resetFilter: async function() {
                this.selectedStartDate = String()
                this.startDate = String()
                this.selectedMonth = String()
                this.isOpenStartDate = false
                this.isOpenMonth = false
                this.show = false
            },

            utcToLocal: function (date, format) {
                var utcLocal = moment(date).local().format(format);
                return utcLocal;
            },

            utcToLocalView: function (date, format) {
                var utcLocal = moment(date).format(format);
                return utcLocal;
            },

            localToUtc: function (date, format) {
                var utcLocal = moment(date).utc().format(format);
                return utcLocal;
            },
        },

        created: function () {
            this.$emit('update:layout', MainLayout);
        },

        mounted: function () {
            Scrollbar.initAll();
            Scrollbar.get($('#left-inner-scrollbar').get()[0]);

            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }

            const now = new Date()
            this.maxDateFilter = this.utcToLocal(now, 'YYYY-MM-DD')
        },

        components: {
            SuccessNotif,
            DashboardViewComplain
        }
    }
</script>