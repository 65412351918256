<template>
	<div>
		<div :id="page == 'my-conversation' ? 'chatContent' : 'detailContent'" class="wrapp-chat-content" 
			data-scrollbar="true" tabindex="1" style="overflow: hidden; outline: none; width:100%">
			<div class="scroll-content">
				<ul class="list-chat">
					<li v-if="msgUiFlag.isLoading" class="text-center" id="msg-loader-indicator">
						<b-spinner small variant="secondary" label="Loading..."></b-spinner>
					</li>
					<template v-for="(item, indx) in messageList">
						<li v-if="item.contentType == 'action'" :key="item.id" class="gap-chat text-center text-muted text-sm">
							<small>
								<div v-if="item.noteAction.type == 'ASSIGN'">
									Obrolan ditangani oleh <b>{{ item.noteAction.operatorName }}</b>
								</div>
								<div v-else-if="item.noteAction.type == 'CLOSE'">
									Obrolan diselesaikan oleh <b>{{ item.noteAction.operatorName }}</b>
								</div>
								<div v-else>
									Obrolan dibuka kembali oleh <b>{{ item.noteAction.operatorName }}</b>
								</div>
								<div>
									<i class="ti-timer" title="Waktu"></i>	
									{{ item.noteAction.time }}
								</div>
							</small>
						</li>
						<!-- start left chat -->
						<li v-if="item.contentType != 'action' && item.from == 'cust'" :id="'chatitem-' + item._id" class="gap-chat" :key="item.id">
							<input v-if="isForward && page == 'my-conversation'" 
								type="checkbox" 
								v-model="selectedMessagesForward" 
								:value="{ ...item, index: indx }" />
							<div class="chat-content" v-if="item.status != 'removed'">
								<h5 v-if="item.showOpHeader && !item.isGroup">
									{{ item.fromName }}
								</h5>
								<h5 v-if="item.isGroup && item.showOpHeader">
									{{ item.author.name }}
								</h5>
								<!-- start message reaction -->
								<div class="showReact effect8" v-if="item.showReaction && page == 'my-conversation'">
									<b-tabs>
										<b-tab v-for="(emo, index) in emoticonList" :key="index" :title="emo.icon">
											<span class="cursor-pointer" v-for="(emoji, i) in emo.emojis" :key="i"
												@click="reactMessage(indx, { ...item }, emoji)" style="font-size: 20px;">
												{{ emoji }}
											</span>
										</b-tab>
									</b-tabs>
									<span class="closeReachbtn" title="Tutup" @click="item.showReaction = false">
										<v-icon>mdi-close</v-icon>
									</span>
								</div>
								<!-- end message reaction -->
								<div v-bind:class="chatItemStyle(item.url, 'cust')">
									<!-- start message menu -->
									<div class="select-chat-menu" :class="{ 
										'select-menu-bg': item.quotedMessage || 
										item.contentType != 'text' && 
										item.contentType != '' 
									}">
										<b-nav-item-dropdown id="my-nav-dropdown" text="" toggle-class="nav-link-custom" right>
											<b-dropdown-item v-if="item.status != 'pending' && item.status != 'error' && page == 'my-conversation'" 
												@click="quoteMessage(item)">
												Balas
											</b-dropdown-item>
											<b-dropdown-item v-if="item.status != 'pending' && item.status != 'error' && page == 'my-conversation'" 
												@click="openReaction(item)">
												Reaksi
											</b-dropdown-item>
											<b-dropdown-item v-if="item.status != 'pending' && item.status != 'error' && page == 'my-conversation'" 
												@click="setForward({ ...item, index: indx })">
												Teruskan
											</b-dropdown-item>
											<b-dropdown-item @click="openNote(item)">
												Beri Keterangan
											</b-dropdown-item>
											<b-dropdown-item v-if="item.contentType == 'image'" @click="searchOnGoogle(item.url)">
												Cari Gambar Di Google
											</b-dropdown-item>
											<b-dropdown-item v-if="page == 'my-conversation'" @click="removeMessage(item)">
												Hapus Pesan
											</b-dropdown-item>
										</b-nav-item-dropdown>
									</div>
									<!-- end message menu -->
									<div v-if="item.affiliatorName" class="m-b-10">
										<small v-if="item.affiliatorName" title="Afiliator" class="text-affiliator badge badge-light badge-pill">
											<i class="text-affiliator mdi mdi-link-variant"></i>{{ item.affiliatorName }}
										</small>
									</div>
									<!-- start quote message -->
									<div v-if="item.quotedMessage" class="quote-chat-op-sender"
										@click="item.quotedMessage.isFromStory ? '' : jumpToElement(item.quotedMessage.originId || item._id)">
										<div class="pull-left wrapp-authorNameLimit">
											<span class="text-info font-weight-bold">
												{{ item.quotedMessage.isFromStory ? 'Status' : item.quotedMessage.authorName }}
											</span>
											<div class="quote-style" v-if="item.quotedMessage.contentType == 'image'">
												<i class="mt-2 mdi mdi-camera"></i> Foto
											</div>
											<div class="quote-style" v-if="item.quotedMessage.contentType == 'video'">
												<i class="mdi mdi-video"></i> Video
											</div>
											<div class="quote-style" v-if="item.quotedMessage.contentType == 'document'">
												<i class="mdi mdi-file-document"></i>
												{{ item.quotedMessage.fileName ?  item.quotedMessage.fileName : 'Dokumen' }}
											</div>
										</div>
										<div v-if="item.quotedMessage.contentType == 'image'" class="pull-right ml-4 mt-1">
											<img :src="item.quotedMessage.url" alt="image-quoted" class="pull-right" width="50px;" 
												@error="handleImageError($event, 'message')" />
										</div>
										<div class="clearfix"></div>
										<div v-if="item.quotedMessage.contentType == 'text'" class="quote-text"
											:class="{'quote-style': item.quotedMessage }" v-html="item.quotedMessage.text"></div>
									</div>
									<!-- end quote message -->
									<!-- start message image -->
									<template v-if="item.url && (item.contentType == 'image' || 
										(item.contentType == 'story_mention' && item.storyType == 'image') ||
										item.contentType == 'share')"
									>
										<div class="img-thumb-chat wrapp-all-conversation">
											<a href="#" data-toggle="modal" class="wrap-preview-icon" @click="viewImage(item, $event)">
												<img :src="item.cdnUrl || item.url" class="img-responsive" @error="handleImageError($event, 'message')" />
												<span style="text-align:center !important;"></span>
											</a>
										</div>
									</template>
									<!-- end message image -->
									<!-- start message document -->
									<template v-if="item.url && item.contentType == 'document'">
										<div class="doc-attach-sender  m-tpull-left wrapp-authorNameLimit-10">
											<a :href="item.url" target="_blank">
												<div class="row">
													<div class="col-md-2">
														<i class="mdi mdi-file m-r-10"></i>
													</div>
													<div class="col-md-8">
														{{ item.fileName }}
													</div>
													<div class="col-md-2">
														<i class="downloadIcon mdi mdi-download"></i>
													</div>
												</div>
											</a>
											<div class="is-clearfix"></div>
											<small v-if="item.fileSize">
												{{ 
													item.fileSize > 1000000 ? (item.fileSize/1000000).toFixed(1) + " MB" : 
													item.fileSize > 1000 ?  (item.fileSize/1000).toFixed(1) + ' kB' : 
													item.fileSize + " bytes" 
												}}
											</small>
										</div>
									</template>
									<!-- end message document -->
									<!-- start message video -->
									<template v-if="item.url && (item.contentType == 'video' || 
										(item.contentType == 'story_mention' && item.storyType == 'video') ||
										item.contentType == 'share')"
									>
										<div class="img-thumb-chat">
											<a href="#" data-toggle="modal" class="wrap-preview-icon">
												<video style="max-width:100%" :src="item.url" controls></video>
												<span style="text-align:center !important;"></span>
											</a>
										</div>
									</template>
									<!-- end message video -->
									<!-- start message context -->
									<template v-if="item.context">
										<div class="wrapp-fb">
											<div class="img-thumb">
												<img :src="item.context.thumbUrl" alt="thumb" style="width:100%;" />
											</div>
											<div class="content">
												<p>{{ item.context.title }}</p>
												<a :href="item.context.url" target="_blank">
													{{ item.context.url }}
												</a>
											</div>
											<div class="clearfix"></div>
										</div>
									</template>
									<!-- end message context -->
									<div class="pull-left caption-img">
										<div :class="{'quote-style': item.quotedMessage }" v-html="item.text"></div>
										<span v-if="item.contentType == 'story_mention' && item.channel == 'IG'" class="text-muted font-italic">
											Story Mentions
										</span>
										<span v-if="item.contentType == 'share' && item.channel == 'IG'" class="text-muted font-italic">
											Share
										</span>
										<a v-if="item.contentType == 'text' && item.url" :href="item.url" target="_blank">
											{{ item.url }}
										</a>
									</div>
									<!-- start message notes -->
									<div v-if="item.internalNotes">
										<div v-if="item.internalNotes.length > 0">
											<div v-for="(note, index) in item.internalNotes" :key="index">
												<div class="row">
													<div class="col-md-12" v-if="note.contentType && note.contentType == 'text'">
														<div class="row">
															<div class="col-md-12">
																<v-menu offset-y>
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn v-bind="attrs" v-on="on" class="note-chat button-full-width">
																			<div>
																				<span class="pull-left left-note">
																					<span class="font-weight-bold mr-4">
																						Teks
																					</span>
																					{{ note.authorId }}
																				</span>
																				<span class="pull-right">
																					<v-icon>
																						mdi-chevron-down
																					</v-icon>
																				</span>
																			</div>
																		</v-btn>
																	</template>
																	<template>
																		<div class="list-note text-justify">
																			<div v-for="(nos, index) in note.list" :key="'teks' + index"
																				style="margin-right:0; border: 1px solid #d7dfe3;">
																				<div class="card-body p-20">
																					<p class="mb-2 card-text">
																						{{ nos.note }}
																					</p>
																					<div class="mb-2">
																						<small class="card-subtitle mb-2 text-muted">
																							{{ nos.time }}
																						</small>
																					</div>
																					<a class="card-link btn btn-primary btn-sm"
																						@click="deleteNote({...nos}, item._id)">
																						Hapus
																					</a>
																				</div>
																			</div>
																		</div>
																	</template>
																</v-menu>
															</div>
														</div>
														<div class="clearfix"></div>
													</div>
													<div v-if="note.contentType && note.contentType == 'order' || note.contentType == 'return'" class="col-md-12">
														<div class="row">
															<div class="col-md-12">
																<v-menu offset-y>
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn v-bind="attrs" v-on="on" class="note-chat button-full-width">
																			<div>
																				<span class="pull-left left-note">
																					<span class="font-weight-bold mr-4">
																						{{ note.contentType == 'return' ? 'Pengembalian' : 'Penjualan'}}
																					</span>
																					{{ note.authorId }}
																				</span>
																				<span class="pull-right">
																					<v-icon>
																						mdi-chevron-down
																					</v-icon>
																				</span>
																			</div>
																		</v-btn>
																	</template>
																	<v-container>
																		<v-row>
																			<v-col cols="12">
																				<div v-for="(nos, index) in note.list" :key="'orderreturn' + index" class="card"
																					style="margin-right:0; border: 1px solid #d7dfe3; margin-bottom: 10px;"
																				>
																					<div class="card-body p-10">
																						<span v-for="(li, index) in nos.list" :key="'notelist' + index" 
																							style="font-size:12px;" class="mb-2 mr-2 badge badge-secondary card-text">
																							{{ li }}
																						</span>
																						<div class="mb-2">
																							<small class="card-subtitle mb-2 text-muted">
																								{{ nos.time }}
																							</small>
																						</div>
																						<a class="card-link btn btn-primary btn-sm" 
																							@click="deleteNote({...nos}, item._id)">
																							Hapus
																						</a>
																					</div>
																				</div>
																			</v-col>
																		</v-row>
																	</v-container>
																</v-menu>	
															</div>
														</div>
														<div class="clearfix"></div>
													</div>
													<div v-if="note.contentType && note.contentType == 'complain'" class="col-md-12">
														<div class="row">
															<div class="col-md-10">
																<v-menu offset-y>
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn color="" v-bind="attrs" v-on="on" class="note-chat button-full-width">
																			<span class="pull-left left-note">
																				<span class="font-weight-bold mr-4">Komplain</span>
																				{{ note.authorId }},
																				{{ note.time }}
																			</span>
																			<span class="pull-right">
																				<v-icon>
																					mdi-chevron-down
																				</v-icon>
																			</span>
																		</v-btn>
																	</template>
																	<v-list>
																		<v-list-item v-for="(item, index) in note.kind" :key="'complain' + index">
																			<v-list-item-title>
																				{{ item }}
																			</v-list-item-title>
																		</v-list-item>
																	</v-list>
																</v-menu>
															</div>
															<div class="col-md-2">
																<v-icon v-if="sessionInfo.userId == note.authorId" class="p-2" title="Hapus Catatan" 
																	style="font-size:20px !important" @click="deleteNote({...note}, item._id)">
																	mdi-delete-circle-outline
																</v-icon>
															</div>
														</div>
														<div class="clearfix"></div>
													</div>
													<div v-if="note.contentType && note.contentType == 'agent'" class="col-md-12">
														<div class="row">
															<div class="col-md-10">
																<v-menu offset-y>
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn v-bind="attrs" v-on="on" class="note-chat button-full-width">
																			<span class="pull-left left-note">
																				<span class="font-weight-bold mr-4">Agen</span>
																				{{ note.authorId }},
																				{{ note.time }}
																			</span>
																			<span class="pull-right">
																				<v-icon>
																					mdi-chevron-down
																				</v-icon>
																			</span>
																		</v-btn>
																	</template>
																	<v-container>
																		<v-row>
																			<v-col cols="12">
																				<div v-for="(nos, index) in note.list" :key="'orderreturn' + index" class="card"
																					style="margin-right:0; border: 1px solid #d7dfe3; margin-bottom: 10px;">
																					<div class="card-body p-10">
																						<span style="font-size:12px;" class="mb-2 mr-2 badge badge-secondary card-text">
																							{{ 
																								note.list[0] == 'INQUIRY' ? 'PERTANYAAN' :
																								note.list[0] == 'REGISTRATION' ? 'REGISTRASI' :
																								note.list[0] == 'SHOPPING' ? 'PEMBELANJAAN' : 'DEPOSIT'
																							}}
																						</span>
																						<p class="mb-2 card-text">
																							{{ note.note }}
																						</p>
																					</div>
																				</div>
																			</v-col>
																		</v-row>
																	</v-container>
																</v-menu>
															</div>
															<div class="col-md-2">
																<v-icon v-if="sessionInfo.userId == note.authorId" class="p-2" title="Hapus Catatan" 
																	style="font-size:20px !important" @click="deleteNote({...note}, item._id)">
																	mdi-delete-circle-outline
																</v-icon>
															</div>
															<span v-if="item.affiliatorName" class="mr-2">
																<i class="mr-1 ti-share" title="Affiliator"></i> {{ item.affiliatorName }}
															</span>
														</div>
														<div class="clearfix"></div>
													</div>
													<div v-if="note.contentType && note.contentType == 'product'" class="col-md-12">
														<div class="row">
															<div class="col-md-10">
																<v-menu offset-y>
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn color="" v-bind="attrs" v-on="on" class="note-chat button-full-width">
																			<span class="pull-left left-note">
																				<span class="font-weight-bold mr-4">Produk</span>
																				{{ note.authorId }},
																				{{ note.time }}
																			</span>
																			<span class="pull-right">
																				<v-icon>
																					mdi-chevron-down
																				</v-icon>
																			</span>
																		</v-btn>
																	</template>
																	<v-list>
																		<v-list-item v-for="(item, index) in note.productList" :key="'produk' + index">
																			<v-list-item-title>
																				{{ item.localSku }} - {{ item.qty }}
																				{{ item.qty > 1 ? 'pcs' : 'pc' }}
																				{{ item.size }}
																				{{ item.color }}
																			</v-list-item-title>
																		</v-list-item>
																	</v-list>
																</v-menu>
															</div>
															<div class="col-md-2">
																<v-icon class="p-2" title="Hapus Catatan" v-if="sessionInfo.userId == note.authorId"
																	style="font-size:20px !important" @click="deleteNote({...note}, item._id)">
																	mdi-delete-circle-outline
																</v-icon>
															</div>
														</div>
														<div class="clearfix"></div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="clearfix"></div>
									<!-- end message notes -->
									<!-- start message footer -->
									<div class="pull-right text-right mt-2">
										<div class="chat-time pull-right">
											<span class="mr-2" v-if="item.fromChannelId">
												<i class="mr-1 ti-mobile" title="No Telepon"></i>
												{{ '... ' + item.fromChannelId.slice(item.fromChannelId.length - 4) }}
											</span>
											<span class="mr-2">
												<i class="mr-1 ti-time" title="Waktu"></i>
												{{ item.time }}
											</span>
											<span v-if="item.channel != 'WA' || !isAccountTest || item.channelName == 'wacloudapi'" 
												class="mr-2 wrapp-icon-channel" :class="{ 'badge-chat-channel-hime': item.channelName == 'wa-himekana' }">
												<v-icon v-if="item.channel == 'FB'">mdi-facebook-messenger</v-icon>
												<v-icon v-if="item.channel == 'WA'">mdi-whatsapp</v-icon>
												<v-icon v-if="item.channel == 'IG'">mdi-instagram</v-icon>
												{{ item.channelName }}
											</span>
											<span class="mr-2 wrapp-icon-channel" v-else>
												<v-icon>mdi-whatsapp</v-icon>
												Wa Business
											</span>
										</div>
									</div>
									<!-- end message footer -->
									<!-- start message reaction -->
									<div class="reactions" v-if="item.reactions && item.reactions.length > 0">
										<span v-for="reaction in item.reactions" :key="reaction.id" :title="reaction.senderName">
											{{ reaction.text }}
										</span>
									</div>
									<!-- end message reaction -->
								</div>
							</div>
							<!-- start message deleted -->
							<div class="chat-content" :class="{'chat-merge-cust': !item.showOpHeader}" v-else>
								<div v-bind:class="chatItemStyle(item.url, 'cust')">
									<div class="pull-left caption-img">
										<span class="text-muted font-italic">
											Pesan ini telah dihapus
										</span>
									</div>
									<div class="clearfix"></div>
									<div class="pull-right text-right mt-2">
										<div class="chat-time pull-right">
											<span class="mr-2" v-if="item.fromChannelId">
												<i class="mr-1 ti-mobile" title="No Telepon"></i>
												{{ '... ' + item.fromChannelId.slice(item.fromChannelId.length - 4) }}
											</span>
											<span class="mr-2">
												<i class="mr-1 ti-time" title="Waktu"></i>
												{{ item.time }}
											</span>
											<span v-if="item.channel != 'WA' || !isAccountTest || item.channelName == 'wacloudapi'" 
												class="mr-2 wrapp-icon-channel" :class="{ 'badge-chat-channel-hime': item.channelName == 'wa-himekana' }">
												<v-icon v-if="item.channel == 'FB'">mdi-facebook-messenger</v-icon>
												<v-icon v-if="item.channel == 'WA'">mdi-whatsapp</v-icon>
												<v-icon v-if="item.channel == 'IG'">mdi-instagram</v-icon>
												{{ item.channelName }}
											</span>
											<span class="mr-2 wrapp-icon-channel" v-else>
												<v-icon>mdi-whatsapp</v-icon>
												Wa Business
											</span>
											<i class="message-info mdi mdi-delete"></i>
										</div>
									</div>
								</div>
							</div>
							<!-- end message deleted -->
						</li>
						<!-- end left chat -->
						<!-- start right chat -->
						<li v-if="item.contentType != 'action' && (item.from == 'op' || item.from == 'op-wa')" class="reverse gap-chat" :id="'chatitem-' + item._id" :key="item.id">
							<input v-if="isForward" type="checkbox" v-model="selectedMessagesForward" :value="{...item, index: indx}" />
							<div class="chat-content" v-if="item.status != 'removed' && !item.isLoginLink">
								<h5 v-if="item.showOpHeader">
									{{ item.operatorName }}
								</h5>
								<!-- start message reaction -->
								<div v-if="item.showReaction" class="showReact effect8">
									<b-tabs>
										<b-tab v-for="(emo, index) in emoticonList" :key="index" :title="emo.icon">
											<span class="cursor-pointer" v-for="(emoji, i) in emo.emojis" :key="i"
												@click="reactMessage(indx, { ...item }, emoji)" style="font-size: 20px;">
												{{ emoji }}
											</span>
										</b-tab>
									</b-tabs>
									<span class="closeReachbtn" title="Tutup" @click="item.showReaction = false">
										<v-icon>mdi-close</v-icon>
									</span>
								</div>
								<!-- end message reaction -->
								<div v-bind:class="chatItemStyle(item.url,'op')">
									<div class="is-block text-left fwd-msg" v-if="item.isForward">
										<v-icon>mdi-share</v-icon> <i>Diteruskan</i>
									</div>
									<!-- start message menu -->
									<div class="select-chat-menu" :class="{
										'select-menu-bg': item.quotedMessage || item.contentType != 'text' &&  item.contentType != ''
									}">
										<b-nav-item-dropdown id="my-nav-dropdown" text="" toggle-class="nav-link-custom" right>
											<b-dropdown-item v-if="item.status != 'pending' && item.status != 'error'" @click="quoteMessage(item)">
												Balas
											</b-dropdown-item>
											<b-dropdown-item v-if="item.status != 'pending' && item.status != 'error'" @click="openReaction(item)">
												Reaksi
											</b-dropdown-item>
											<b-dropdown-item v-if="item.status != 'pending' && item.status != 'error'" @click="setForward({ ...item, index: indx })">
												Teruskan
											</b-dropdown-item>
											<b-dropdown-item v-if="item.externalId && item.status != 'removed'" @click="removeMessage(item)">
												Hapus
											</b-dropdown-item>
											<b-dropdown-item @click="openNote(item)">
												Beri Keterangan
											</b-dropdown-item>
										</b-nav-item-dropdown>
									</div>
									<div class="clearfix"></div>
									<!-- end message menu -->
									<!-- end message quote -->
									<div class="quote-chat-op" v-if="item.quotedMessage"
										@click="item.quotedMessage.isFromStory ? '' : jumpToElement(item.quotedMessage.originId || item._id)">
										<div class="pull-left wrapp-authorNameLimit">
											<span class="authorNameLimit">
												&nbsp;
												<b class="text-info font-weight-bold">
													{{ item.quotedMessage.isFromStory ? 'Status' : item.quotedMessage.authorName }}
												</b>
											</span>
											<div v-if="item.quotedMessage.contentType == 'image'" class="quote-style">
												<i class="mt-2 mdi mdi-camera"></i> Foto
											</div>
											<div v-if="item.quotedMessage.contentType == 'video'" class="quote-style">
												<i class="mdi mdi-video"></i> Video
											</div>
											<div v-if="item.quotedMessage.contentType == 'document'" class="quote-style">
												<i class="mdi mdi-file-document"></i>
												{{ item.quotedMessage.fileName ?  item.quotedMessage.fileName : 'Dokumen' }}
											</div>
										</div>
										<div v-if="item.quotedMessage.contentType == 'image'" class="pull-right ml-4 mt-1">
											<img :src="item.quotedMessage.url" alt="image-quoted" class="pull-right" width="50px;"
												@error="handleImageError($event, 'message')" />
										</div>
										<div class="clearfix"></div>
										<div v-if="item.quotedMessage.contentType == 'text'" class="quote-text" v-html="item.quotedMessage.text">
										</div>
									</div>
									<div class="clearfix"></div>
									<!-- end message quote -->
									<!-- start message image -->
									<template v-if="item.url && (item.contentType == 'image' || item.contentType == 'story_mention' || item.contentType == 'share')">
										<div class="img-thumb-chat m-b-10 wrapp-all-conversation" :class="{ 'quote-style': item.quotedMessage }">
											<a href="#" data-toggle="modal" class="wrap-preview-icon" @click="viewImage(item, $event)">
												<img :src="item.cdnUrl || item.url" class="img-responsive" @error="handleImageError($event, 'message')" />
												<span style="text-align:center !important;"></span>
											</a>
										</div>
									</template>
									<!-- end message image -->
									<!-- start message document -->
									<template v-if="item.url && item.contentType == 'document'">
										<div class="doc-attach">
											<a :href="item.url" target="_blank">
												<div class="row">
													<div class="col-md-2">
														<i class="mdi mdi-file m-r-10"></i>
													</div>
													<div class="col-md-8">
														{{ item.fileName }}
													</div>
													<div class="col-md-2">
														<i class="downloadIcon mdi mdi-download"></i>
													</div>
												</div>
											</a>
											<div class="is-clearfix"></div>
											<small v-if="item.fileSize">
												{{ 
													item.fileSize > 1000000 ? (item.fileSize/1000000).toFixed(1) + " MB" : 
													item.fileSize > 1000 ?  (item.fileSize/1000).toFixed(1) + ' kB' : 
													item.fileSize + " bytes" 
												}}
											</small>
										</div>
									</template>
									<!-- end message document -->
									<!-- start message video -->
									<template v-if="item.url && item.contentType == 'video'">
										<div class="img-thumb-chat" :class="{ 'quote-style': item.quotedMessage }">
											<a href="#" data-toggle="modal" class="wrap-preview-icon">
												<video style="max-width:100%" :src="item.url" controls></video>
												<span style="text-align:center !important;"></span>
											</a>
										</div>
									</template>
									<!-- end message video -->
									<!-- start message context -->
									<template v-if="item.context">
										<div class="wrapp-fb">
											<div class="img-thumb">
												<img :src="item.context.thumbUrl" alt="thumb" style="width:100%;">
											</div>
											<div class="content">
												<p>{{ item.context.title }}</p>
												<a :href="item.context.url" target="_blank">
													{{ item.context.url }}
												</a>
											</div>
											<div class="clearfix"></div>
										</div>
									</template>
									<!-- end message context -->
									<div class="pull-left caption-img">
										<div v-html="item.text"></div>
										<span v-if="item.contentType == 'story_mention' && item.channel == 'IG'" class="text-muted font-italic">
											Story Mentions
										</span>
										<span v-if="item.contentType == 'share' && item.channel == 'IG'" class="text-muted font-italic">
											Share
										</span>
										<a :href="item.url" v-if="item.contentType == 'text' && item.url" target="_blank">
											{{ item.url }}
										</a>
									</div>
									<div class="clearfix"></div>
									<!-- start message notes -->
									<div v-if="item.internalNotes">
										<div v-if="item.internalNotes.length > 0">
											<div v-for="(note, index) in item.internalNotes" :key="index">
												<div class="row">
													<div v-if="note.contentType && note.contentType == 'text'" class="col-md-12">
														<div class="row">
															<div class="col-md-12">
																<v-menu offset-y>
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn v-bind="attrs" v-on="on" class="note-chat button-full-width">
																			<div>
																				<span class="pull-left left-note">
																					<span class="font-weight-bold mr-4">
																						Teks
																					</span>
																					{{ note.authorId }}
																				</span>
																				<span class="pull-right">
																					<v-icon>
																						mdi-chevron-down
																					</v-icon>
																				</span>
																			</div>
																		</v-btn>
																	</template>
																	<template>
																		<v-container>
																			<v-row>
																				<v-col cols="12">
																					<div v-for="(nos, index) in note.list" :key="'teks' + index" class="card"
																						style="margin-right:0; border: 1px solid #d7dfe3; margin-bottom: 10px;">
																						<div class="card-body p-10">
																							<p class="mb-2 card-text">
																								{{ nos.note }}
																							</p>
																							<div class="mb-2">
																								<small class="card-subtitle mb-2 text-muted">
																									{{ nos.time }}
																								</small>
																							</div>
																							<a class="card-link btn btn-primary btn-sm"
																								@click="deleteNote({...nos}, item._id)">
																								Hapus
																							</a>
																						</div>
																					</div>
																				</v-col>
																			</v-row>
																		</v-container>
																	</template>
																</v-menu>
															</div>
														</div>
														<div class="clearfix"></div>
													</div>
													<div v-if="note.contentType && note.contentType == 'order' || note.contentType == 'return'" class="col-md-12">
														<div class="row">
															<div class="col-md-12">
																<v-menu offset-y>
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn v-bind="attrs" v-on="on" class="note-chat button-full-width">
																			<div>
																				<span class="pull-left left-note">
																					<span class="font-weight-bold mr-4">
																						{{ note.contentType == 'return' ? 'Pengembalian' : 'Penjualan' }}
																					</span>
																					{{ note.authorId }}
																				</span>
																				<span class="pull-right">
																					<v-icon>
																						mdi-chevron-down
																					</v-icon>
																				</span>
																			</div>
																		</v-btn>
																	</template>
																	<v-container>
																		<v-row>
																			<v-col cols="12">
																				<div v-for="(nos, index) in note.list" :key="'orderreturn' + index" class="card"
																					style="margin-right:0; border: 1px solid #d7dfe3; margin-bottom: 10px;">
																					<div class="card-body p-10">
																						<span v-for="(li, index) in nos.list" :key="'notelist' + index"
																							style="font-size:12px;" class="mb-2 mr-2 badge badge-secondary card-text">
																							{{ li }}
																						</span>
																						<div class="mb-2">
																							<small class="card-subtitle mb-2 text-muted">
																								{{ nos.time }}
																							</small>
																						</div>
																						<a class="card-link btn btn-primary btn-sm" 
																							@click="deleteNote({...nos}, item._id)">
																							Hapus
																						</a>
																					</div>
																				</div>
																			</v-col>
																		</v-row>
																	</v-container>
																</v-menu>
															</div>
														</div>
														<div class="clearfix"></div>
													</div>
													<div v-if="note.contentType && note.contentType == 'complain'" class="col-md-12">
														<div class="row">
															<div class="col-md-10">
																<v-menu offset-y>
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn color="" v-bind="attrs" v-on="on" class="note-chat button-full-width">
																			<span class="pull-left left-note">
																				<span class="font-weight-bold mr-4">Komplain</span>
																				{{ note.authorId }},
																				{{ note.time }}
																			</span>
																			<span class="pull-right">
																				<v-icon>
																					mdi-chevron-down
																				</v-icon>
																			</span>
																		</v-btn>
																	</template>
																	<v-list>
																		<v-list-item v-for="(item, index) in note.kind" :key="'complain' + index">
																			<v-list-item-title>
																				{{ item }}
																			</v-list-item-title>
																		</v-list-item>
																	</v-list>
																</v-menu>
															</div>
															<div class="col-md-2">
																<v-icon v-if="sessionInfo.userId == note.authorId" class="p-2" title="Hapus Catatan"
																	style="font-size:20px !important" @click="deleteNote({...note}, item._id)">
																	mdi-delete-circle-outline
																</v-icon>
															</div>
														</div>
														<div class="clearfix"></div>
													</div>
													<div v-if="note.contentType && note.contentType == 'agent'" class="col-md-12">
														<div class="row">
															<div class="col-md-10">
																<v-menu offset-y>
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn color="" v-bind="attrs" v-on="on" class="note-chat button-full-width">
																			<span class="pull-left left-note">
																				<span class="font-weight-bold mr-4">Agen</span>
																				{{ note.authorId }},
																				{{ note.time }}
																			</span>
																			<span class="pull-right">
																				<v-icon>
																					mdi-chevron-down
																				</v-icon>
																			</span>
																		</v-btn>
																	</template>
																	<v-container>
																		<v-row>
																			<v-col cols="12">
																				<div v-for="(nos, index) in note.list" :key="'orderreturn' + index" class="card"
																					style="margin-right:0; border: 1px solid #d7dfe3; margin-bottom: 10px;">
																					<div class="card-body p-10">
																						<span style="font-size:12px;" class="mb-2 mr-2 badge badge-secondary card-text">
																							{{ 
																								note.list[0] == 'INQUIRY' ? 'PERTANYAAN' :
																								note.list[0] == 'REGISTRATION' ? 'REGISTRASI' :
																								note.list[0] == 'SHOPPING' ? 'PEMBELANJAAN' : 'DEPOSIT'
																							}}
																						</span>
																						<p class="mb-2 card-text">
																							{{ note.note }}
																						</p>
																					</div>
																				</div>
																			</v-col>
																		</v-row>
																	</v-container>
																</v-menu>
															</div>
															<div class="col-md-2">
																<v-icon v-if="sessionInfo.userId == note.authorId" class="p-2" title="Hapus Catatan" 
																	style="font-size:20px !important" @click="deleteNote({...note}, item._id)">
																	mdi-delete-circle-outline
																</v-icon>
															</div>
														</div>
														<div class="clearfix"></div>
													</div>
													<div v-if="note.contentType && note.contentType == 'product'" class="col-md-12">
														<div class="row">
															<div class="col-md-10">
																<v-menu offset-y>
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn color="" v-bind="attrs" v-on="on" class="note-chat button-full-width">
																			<span class="pull-left left-note">
																				<span class="font-weight-bold mr-4">Produk</span>
																				{{ note.authorId }},
																				{{ note.time }}
																			</span>
																			<span class="pull-right">
																				<v-icon>
																					mdi-chevron-down
																				</v-icon>
																			</span>
																		</v-btn>
																	</template>
																	<v-list>
																		<v-list-item v-for="(item, index) in note.productList" :key="'produk' + index">
																			<v-list-item-title>
																				{{ item.localSku }} - {{ item.qty }}
																				{{ item.qty > 1 ? 'pcs' : 'pc' }}
																				{{ item.size }}
																				{{ item.color }}
																			</v-list-item-title>
																		</v-list-item>
																	</v-list>
																</v-menu>
															</div>
															<div class="col-md-2">
																<v-icon v-if="sessionInfo.userId == note.authorId" class="p-2" title="Hapus Catatan"
																	style="font-size:20px !important" @click="deleteNote({...note}, item._id)">
																	mdi-delete-circle-outline
																</v-icon>
															</div>
														</div>
														<div class="clearfix"></div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="clearfix"></div>
									<!-- end message notes -->
									<!-- start message footer -->
									<div class="chat-time">
										<span class="mr-2" v-if="item.internalNotes && item.internalNotes.length > 0">
											<i class="mdi mdi-note-outline" style="font-size: 12px;"></i>
										</span>
										<span class="mr-2" v-if="item.messageType == 'broadcast'">
											<small title="Broadcast">
												<i class="mdi mdi-bullhorn-outline" style="font-size:12px;"></i>
											</small>
										</span>
										<span class="mr-2" v-if="item.sentTo">
											<i class="mr-1 ti-mobile" title="No Telepon"></i>
											{{ '... ' + item.sentTo.slice(item.sentTo.length - 4) }}
										</span>
										<span class="mr-2">
											<i class="mr-1 ti-time" title="Waktu"></i>
											{{ item.time }}
										</span>
										<span v-if="item.channel != 'WA' || !isAccountTest || item.channelName == 'wacloudapi'" 
											class="mr-2 wrapp-icon-channel" :class="{ 'badge-chat-channel-hime': item.channelName == 'wa-himekana' }">
											<v-icon v-if="item.channel == 'FB'">mdi-facebook-messenger</v-icon>
											<v-icon v-if="item.channel == 'WA'">mdi-whatsapp</v-icon>
											<v-icon v-if="item.channel == 'IG'">mdi-instagram</v-icon>
											{{ item.channelName }}
										</span>
										<span class="mr-2 wrapp-icon-channel" v-else>
											<v-icon>mdi-whatsapp</v-icon>
											Wa Business
										</span>
										<span v-if="item.isResponseTime && item.responseTime" class="mr-2" :class="{ 'text-danger': item.responseTime >= 180 }">
											<i class="mr-1 ti-timer" title="Waktu"></i>
											{{ item.convertedResponseTime }}
										</span>
										<i style="font-size: 16px" class="message-info mdi" :class="{
											'mdi-timelapse': item.status == 'pending', 
											'mdi-check': item.status == 'sent', 
											'mdi-check-all': item.status == 'delivered', 
											'mdi-check-all text-info-ori': item.status == 'read', 
											'mdi-clock text-danger': item.status == 'error'
										}"></i>
									</div>
									<!-- end message footer -->
									<!-- start message reaction -->
									<div class="reactions-right" v-if="item.reactions && item.reactions.length > 0">
										<span v-for="reaction in item.reactions" :key="reaction.id" :title="reaction.senderName">
											{{ reaction.text }}
										</span>
									</div>
									<!-- end message reaction -->
								</div>
							</div>
							<!-- start message deleted -->
							<div v-else-if="item.status == 'removed' || item.isLoginLink" class="chat-content" 
								:class="{ 'chat-merge-op': !item.showOpHeader }">
								<div v-bind:class="chatItemStyle(item.url, 'op')">
									<div class="caption-img">
										<small class="font-italic text-muted" v-if="item.status == 'removed'">
											Kamu menghapus pesan ini
										</small>
										<small class="font-italic text-muted" v-if="item.isLoginLink">
											Pesan ini disembunyikan
										</small>
									</div>
									<div class="clearfix"></div>
									<div class="chat-time">
										<span class="mr-2" v-if="item.internalNotes && item.internalNotes.length > 0">
											<i class="mdi mdi-note-outline" style="font-size: 12px;"></i>
										</span>
										<span class="mr-2" v-if="item.messageType == 'broadcast'">
											<small title="Broadcast">
												<i class="mdi mdi-bullhorn-outline" style="font-size:12px;"></i>
											</small>
										</span>
										<span class="mr-2" v-if="item.sentTo">
											<i class="mr-1 ti-mobile" title="No Telepon"></i>
											{{ '... ' + item.sentTo.slice(item.sentTo.length - 4) }}
										</span>
										<span class="mr-2">
											<i class="mr-1 ti-time" title="Waktu"></i>
											{{ item.time }}
										</span>
										<span v-if="item.channel != 'WA' || !isAccountTest || item.channelName == 'wacloudapi'" 
											class="mr-2 wrapp-icon-channel" :class="{ 'badge-chat-channel-hime': item.channelName == 'wa-himekana' }">
											<v-icon v-if="item.channel == 'FB'">mdi-facebook-messenger</v-icon>
											<v-icon v-if="item.channel == 'WA'">mdi-whatsapp</v-icon>
											<v-icon v-if="item.channel == 'IG'">mdi-instagram</v-icon>
											{{ item.channelName }}
										</span>
										<span class="mr-2 wrapp-icon-channel" v-else>
											<v-icon>mdi-whatsapp</v-icon>
											Wa Business
										</span>
										<span v-if="item.isResponseTime && item.responseTime" class="mr-2" :class="{ 'text-danger': item.responseTime >= 180 }">
											<i class="mr-1 ti-timer" title="Waktu"></i>
											{{ item.convertedResponseTime }}
										</span>
										<i style="font-size: 16px" class="message-info mdi" :class="{
											'mdi-timelapse': item.status == 'pending', 
											'mdi-check': item.status == 'sent', 
											'mdi-check-all': item.status == 'delivered', 
											'mdi-check-all text-info-ori': item.status == 'read', 
											'mdi-clock text-danger': item.status == 'error'
										}"></i>
									</div>
								</div>
							</div>
							<!-- end message deleted -->
						</li>
						<!-- end right chat -->
					</template>
					<li v-if="loadingJump" class="text-center" id="msg-loader-indicator">
						<b-spinner small variant="secondary" label="Loading..."></b-spinner>
					</li>
				</ul>
				<div class="scrollbar-track scrollbar-track-x" style="display: none;">
					<div class="scrollbar-thumb scrollbar-thumb-x" style="width: 728px; transform: translate3d(0px, 0px, 0px);"></div>
				</div>
				<div class="scrollbar-track scrollbar-track-y" style="display: block;">
					<div class="scrollbar-thumb scrollbar-thumb-y" style="height: 61.2129px; transform: translate3d(0px, 0px, 0px);"></div>
				</div>
			</div>
			<div class="scrollbar-track scrollbar-track-x" style="display: none;">
				<div class="scrollbar-thumb scrollbar-thumb-x" style="width: 605px; transform: translate3d(0px, 0px, 0px);"></div>
			</div>
			<div class="scrollbar-track scrollbar-track-y" style="display: block;">
				<div class="scrollbar-thumb scrollbar-thumb-y" style="height: 422.828px; transform: translate3d(0px, 0px, 0px);"></div>
			</div>
		</div>
		<image-viewer 
			:imageUrl="selectedImage.imageUrl" 
			:caption="selectedImage.caption" />
	</div>
</template>
<script>
import { mapState } from 'vuex'

import ImageViewer from './ImageViewer.vue'

import IMAGE_ACC from '../../public/images/dummy.jpg'
import IMAGE_MESSAGE from '../../public/images/image-not-found.png'

export default {

	props: ['page', 'msgUiFlag', 'messageList', 'isForward', 'emoticonList', 'selectedMessagesForward', 'loadingJump'],

	components: {
		'image-viewer': ImageViewer
	},

	data: function() {
		return {
			selectedImage: {
				imageUrl: String(),
				caption: String
			},
		}
	},

	computed: {
		...mapState({
			isAccountTest: state => state.session.sessionInfo.userId == "bippofb"
		}),
	},

	methods: {
		reactMessage(index, message, emoji) {
			this.$emit('reactMessage', index, message, emoji)
		},

		deleteNote(note, messageId) {
			this.$emit('deleteNote', note, messageId)
		},

		removeMessage(message) {
			this.$emit('removeMessage', message)
		},

		quoteMessage(message) {
			this.$emit('quoteMessage', message)
		},

		setForward(message) {
			this.$emit('setForward', message)
		},

		openReaction(message) {
			this.$emit('openReaction', message)
		},

		openNote(message) {
			this.$emit('openNote', message)
		},

		searchOnGoogle(imageUrl) {
			const url = 'https://lens.google.com/uploadbyurl?url=' + imageUrl

			window.open(url, '_blank');
		},

		jumpToElement(messageId) {
			this.$emit('jumpToElement', messageId)
		},

		viewImage: function (message, event) {
			event.preventDefault();

			this.$bvModal.show("modal-image-preview");

			this.selectedImage.imageUrl = message.cdnUrl || message.url;
			this.selectedImage.caption = message.text;
		},

		handleImageError: function (event, type) {
			if (type == 'contact') event.target.src = IMAGE_ACC;
			if (type == 'message') event.target.src = IMAGE_MESSAGE;
		},

		chatItemStyle: function (url, channel) {
			if (url) {
				if (channel == 'cust') {
					return 'box bg-light-inverse wrapp-chat-img';
				} else {
					return 'box bg-light-info wrapp-chat-img';
				}
			} else {
				if (channel == 'op') {
					return 'box bg-light-info';
				} else {
					return 'box bg-light-inverse';
				}
			}
		}
	}
}
</script>