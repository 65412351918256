<template>
    <div>
        <v-row>
            <v-col cols="4">
                <router-link :to="{ name: routeName }" class="">
                    <v-icon title="Kembali">mdi-arrow-left</v-icon>
                </router-link>
            </v-col>
            <v-col cols="8">
                <div class="float-right" v-if="routeName != 'myemail'">
                 
                    <b-dropdown style="border-radius:20px; padding:0 !important;" class="btn-assign m-r-10" pill text="Rekan" right>
                        <span style="padding: 8px 1rem; display:block">
                            <input class="form-control" type="text" placeholder="Search" @input="filterReturn($event)"
                                aria-label="Search" />
                        </span>
                        <b-dropdown-item v-for="(op) in filterOperator" :key="op.id" class="list-account">
                            <span class="pull-left wrapp-list-account itemName">
                                <a @click="openDialog(thread, op)">
                                    {{ op.name }}
                                </a>
                            </span>
                            <span class="online-status" v-if="op.active && op.active == 1">
                                <i style="color:green" class="mdi mdi-circle"></i>
                            </span>
                            <div class="clearfix"></div>
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-button variant="primary" class="btn" @click="openDialog(thread)">
                        Saya
                    </b-button>
                </div>
                <div v-else class="float-right">
                    <a class="m-r-20" @click="openDialog('reply')">
                        <v-icon title="Balas">mdi-reply</v-icon>
                    </a>
                     <a class="m-r-20" @click="openDialogClose({...thread}, 'pending')">
                        <v-icon title="Tunda Percakapan">mdi-timer</v-icon>
                    </a>
                    <a @click="openDialogClose({...thread}, 'close')">
                          <v-icon title="Tutup Percakapan">mdi-close</v-icon>
                    </a>
                   
                    
                </div>
            </v-col>
        </v-row>
        <div class="m-t-20 mb-10" v-if="thread" :class="{ 'mb-10': routeName != 'myemail' }">
            <v-row v-for="(message, index) in thread.messages" :key="message._id">
                <v-col cols="12">
                    <div class="m-b-20 title-message" v-if="index == 0">
                        <h3 class="">{{ message.title }}</h3>
                    </div>
                    <div v-if="message.direction == 'OUT'">
                        <div class="wrapp-img m-r-10">
                            <span>
                                <div class="initials">
                                    {{ message.operator ? changeInitialName(message.operator.name) : "S" }}
                                </div>
                            </span>
                        </div>
                        <span class="font-weight-bold">
                            {{ message.operator ? message.operator.name : "Sistem" }}
                        </span>
                        <span class="float-right text-muted">
                            {{ message.time }}
                        </span>
                    </div>
                    <div v-else>
                        <div class="wrapp-img m-r-10">
                            <span>
                                <div class="initials">
                                    {{ 
                                        message.fromName ? changeInitialName(message.fromName) : 
                                        message.from ? changeInitialName(message.from) : ""
                                    }}
                                </div>
                            </span>
                        </div>
                        <div>
                            <span class="font-weight-bold">
                                {{ message.fromName ? message.fromName : message.from }}
                            </span>
                            <span class="float-right text-muted">
                                {{ message.time }}
                            </span>
                            <br>
                            <span class="m-r-10" v-if="message.fromEmail">
                                {{ message.fromEmail }}
                            </span>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="m-t-20" v-if="message.textHtml" v-html="message.textHtml"></div>
                    <div class="m-t-20" v-else>
                        {{ message.text }}
                    </div>
                    <div class="  m-t-20" v-if="message.attachments && message.attachments.length > 0">
                        <span class="font-weight-bold">
                            {{ message.attachments.length }} Lampiran
                        </span>
                    </div>
                    <div class="m-t-20" v-if="message.attachments && message.attachments.length > 0">
                        <div v-for="attachment in message.attachments" :key="attachment._id"
                            :title="attachment.contentType == 'image' ? 'Gambar' : 
                                attachment.contentType == 'video' ? 'Video' : 'Dokumen'">
                            <div class="card-attachment card float-left m-r-10" style="width: 180px;">
                                <a class="downloadIcon" :href="attachment.url" target="_blank">
                                    <v-icon class="pull-left">mdi-download</v-icon>
                                </a>
                                <img class="card-img-top" :src="attachment.url" alt="attachment" v-if="attachment.contentType == 'image'" @click="viewImage(attachment)" />
                                <video :src="attachment.url" controls v-else-if="attachment.contentType == 'video'"></video>
                                <div v-else class="no-preview-attach">
                                    <v-icon class="pull-left">mdi-file</v-icon>
                                </div>
                                <div class="card-body">
                                    <div class=""></div>
                                    <p class="card-text">
                                        <v-icon class="pull-left m-r-5">
                                            {{ 
                                                attachment.contentType == 'image' ? 'mdi-image' : 
                                                attachment.contentType == 'video' ? 'mdi-video' : 'mdi-file'
                                            }}
                                        </v-icon>
                                        <span class="filename">
                                            {{ attachment.filename }}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" v-if="index != thread.messages.length - 1">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <div class="clearfix"></div>
            <v-row class="m-t-40" v-if="routeName == 'myemail'">
                <v-col>
                    <v-btn class="mb-4 m-r-10" @click="openDialog('reply')">
                        <span>
                            <v-icon class="pull-left m-r-5">mdi-reply</v-icon> Balas
                        </span>
                    </v-btn>
                    <v-btn class="mb-4 m-r-10" @click="openDialogClose({...thread}, 'pending')">
                        <span class="">
                            <v-icon class="pull-left m-r-5">mdi-timer</v-icon> Tunda
                        </span>
                    </v-btn>
                    <v-btn class="mb-4 m-r-10" @click="openDialogClose({...thread}, 'close')">
                        <span class="">
                            <v-icon class="pull-left m-r-5">mdi-close</v-icon> Tutup Percakapan
                        </span>
                    </v-btn>
                </v-col>
            </v-row>
        </div>

        <ImageViewerEmail :url="selectedImage.url" :filename="selectedImage.filename" />
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    import ImageViewerEmail from '../components/ImageViewerEmail.vue'

    export default {
        props: ['thread', 'routeName'],
        

        data: function() {
            return {
                selectedImage: Object(),

                filterOperator: Array(),
            }
        },

        computed: {
            ...mapState({
                operatorList: state => state.operator.operatorList
            })
        },

        methods: {
            openDialog: function(item, operator) {
                if(this.routeName == 'myemail') {
                    this.$emit('open-dialog', item)
                } else {
                    this.$emit('open-dialog', item, operator)
                }
            },
            openDialogClose: function(item, kind) {
                this.$emit('open-dialog-close', item, kind)
            },
            viewImage: function (attachment) {
                this.$bvModal.show("modal-image-preview");

                this.selectedImage = {
                    url: attachment.url,
                    filename: attachment.filename
                }
            },
            filterReturn: function (event) {
                this.filterOperator = this.operatorList.filter(e => e.name.toLowerCase().includes(event.target.value.toLowerCase()))
            },
            changeInitialName: function (name) {
                let changedName = String()

                name.split(" ").forEach(e => {
                    if (changedName.length < 2) changedName += e[0]
                })

                return changedName
            },
        },

        mounted: function() {
            this.filterOperator = this.operatorList
        },

        components: {
            ImageViewerEmail
        }
    }
</script>